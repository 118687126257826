import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      isProductTagPopup: false,
      propId: null,
      productTagInfo: null,
    }
  },
  watch: {
    getOpenProductTagPopupWithImgId(v) {
      if (v) {
        this.openProductTagPopupForUpdate(v).then()
      }
    }
  },
  computed: {
    ...mapGetters({
      getRemoveProductTagEditorImgIds: 'productTag/getRemoveProductTagEditorImgIds',
      getOpenProductTagPopupWithImgId: 'productTag/getOpenProductTagPopupWithImgId',
    }),
  },
  methods: {
    ...mapActions({
      setOpenProductTagPopupWithImgId: 'productTag/setOpenProductTagPopupWithImgId',
      setMsgPopup: 'common/setMsgPopup',
    }),
    async removeProductTag() {
      if (this.getRemoveProductTagEditorImgIds.length > 0) {
        const { result, data } = await this.$api.removeProductTag({ imgIds: this.getRemoveProductTagEditorImgIds })
      }
    },
    removeProductTagDiv() {
      const tagIdDiv = document.getElementsByClassName('tag-div')
      if (tagIdDiv) {
        document.getElementsByClassName('tag-div').remove()
      }
    },
    openProductTagPopupForInsert() {
      document.body.style.overflowY ="hidden"
      this.isProductTagPopup = true
    },
    async openProductTagPopupForUpdate(editorImageId) {
      document.body.style.overflowY ="hidden"
      this.propId = editorImageId

      const { result, data } = await this.$api.getProductTag({ imgId: editorImageId })
      if (result === 'success') {
        this.productTagInfo = data
        this.isProductTagPopup = true
        this.setOpenProductTagPopupWithImgId(null)
      }
      else {
        this.alertPopup('상품태그의 정보 조회에 실패하였습니다.')
        this.closeProductTagPopup()
      }
    },
    closeProductTagPopup() {
      document.body.style.overflowY ="scroll"
      this.isProductTagPopup = false
      this.propId = null
      this.productTagInfo = null
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}