<template>
  <div>
    <div class="navi-bar"> 
        <span>회원</span>
        <span>알림</span>
        <span>알림 발송</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">알림 발송</div>
      <div class=""><button @click="send" class="btn color-1">발송</button></div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">기본설정</div>
        <table class="table-cols">
          <colgroup>
            <col width="180">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">구분</th>
            <td><select-box selectBoxClass="w-100 mr-10" :options="opt.target" v-model="target" :errorMessage="targetMsg"/></td>
          </tr>
          <tr>
            <th class="require">제목</th>
            <td><input-field v-model="title" class="table-input2" :errorMessage="titleMsg" placeholder="제목을 입력해주세요." maxLength="100"/></td>
          </tr>
          <tr>
            <th class="require">링크</th>
            <td><input-field v-model="url" class="table-input2" :errorMessage="urlMsg" maxLength="500" placeholder="클릭 시 이동할 링크 https:// 포함하여 입력해주세요."/></td>
          </tr>
          </tbody>
        </table>
      </div>
    </validateForm>
    
    <div class="information-box">
        <div class="information-box-title">안내</div>
        <div class="information-contents">
            <ul class="dot-list">
                <li>구분 : 알림에 대한 구분 값을 선택하는 영역입니다.</li>
                <li>제목 : 사용자 알림 화면에 보여지는 제목입니다.</li>
                <li>링크URL : 사용자 알림 화면에서 메시지 클릭 시 이동되는 화면 URL 입니다. </li>
                <li>발송대상은 전체 회원을 대상으로 발송됩니다.</li>
            </ul>
        </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: 'notificationSend',
  data() {
    return {
      opt: {
        target: [
          { value: null, text: '선택' },
          { value: 'event', text: '이벤트' },
          { value: 'notice', text: '공지사항' },
        ]
      },
      target: null,
      title: null,
      url: null,
      targetMsg: '',
      titleMsg: '',
      urlMsg: '',
    }
  },
  watch: {
    target() {
      this.targetMsg = ''
    },
    title() {
      this.titleMsg = ''
    },
    url() {
      this.urlMsg = ''
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    validate() {
      if (!this.target) {
        this.targetMsg = this.$msg('required.select')
        return false
      }
      if (!this.title) {
        this.titleMsg = this.$msg('required.input')
        return false
      }
      if (!this.url) {
        this.urlMsg = this.$msg('required.input')
        return false
      }
      if (!this.url.patternCheck('url')) {
        this.urlMsg = this.$msg('url.check')
        return false
      }
      return true
    },
    async send() {
      if (this.validate()) {
        const { result } = await this.$api.notificationSend({
          target: this.target,
          title: this.title,
          url: this.url,
        })
        if (result === 'success') this.alertPopup(this.$msg('ok.send'))
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          this.$router.push('/member/notification/send/list')
        },
      })
    },
  }
}
</script>
