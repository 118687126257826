import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
        return Promise.reject(err)
    })
    /*console.log("aaa")
    return originalPush.call(this, location).catch(err => err)*/
}

Vue.use(VueRouter)

const routes = [
    {path: '/',                               name: 'index',            component: () => import('@/views/index')},
    {path: '/login',                          name: 'login',            component: () => import('@/views/login')},

    // 대시보드
    { path: '/dashboard',                     name: 'dashBoard',        component: () => import('@/views/dashboard/') },
    
    // 운영관리 > 기본정책 > 기본 정보 설정
    {path: '/policy/baseInfo',                name: 'baseInfo',         component: () => import('@/views/policy/baseInfo')},
    // 운영관리 > 기본정책 > 약관설정
    {path: '/policy/terms',                   name: 'terms',            component: () => import('@/views/policy/terms')},
    // 운영관리 > 기본정책 > 아이콘 노출 설정
    {path: '/policy/icon',                    name: 'icon',             component: () => import('@/views/policy/icon')},
    // 운영관리 > 기본정책 > 스크립트
    {path: '/policy/script',                  name: 'script',           component: () => import('@/views/policy/script')},
    // 운영관리 > 기본정책 > 단어 필터링
    {path: '/policy/wordFilter',              name: 'wordFilter',       component: () => import('@/views/policy/wordFilter')},
    // 운영관리 > 운영자 관리 > 운영자 리스트
    {path: '/policy/manager/list',            name: 'managerList',      component: () => import('@/views/policy/managerList')},
    // 운영관리 > 운영자 관리 > 운영자 등록
    {path: '/policy/manager/save',            name: 'managerSave',      component: () => import('@/views/policy/managerSave')},
    // 운영관리 > 분류 관리 > 공통 분류 관리
    {path: '/policy/classification',          name: 'classification',   component: () => import('@/views/policy/classification')},

    // 회원 > 회원 관리 > 회원 리스트
    {path: '/member/list',                     name: 'memberList',      component: () => import('@/views/member/list')},
    // 회원 > 회원 관리 > 회원 등록
    {path: '/member/save',                     name: 'memberSave',      component: () => import('@/views/member/save')},
    // 회원 > 회원 관리 > 휴면 회원관리
    {path: '/member/sleepList',                name: 'memberSleepList', component: () => import('@/views/member/sleepList')},
    // 회원 > 회원 관리 > 회원 탈퇴
    {path: '/member/withdrawal',               name: 'memberWithdrawal',component: () => import('@/views/member/withdrawal')},
    // 회원 > 알림 > 알림 발송
    {path: '/member/notification/send',        name: 'notificationSend',     component: () => import('@/views/member/notificationSend')},
    // 회원 > 알림 > 알림 발송 내역
    {path: '/member/notification/send/list',   name: 'notificationSendList', component: () => import('@/views/member/notificationSendList')},

    // 게시판 > 게시판관리 > 꼬리TIP
    {path: '/board/tip/list',                  name: 'tipList',         component: () => import('@/views/board/tip/list')},
    // 게시판 > 게시판관리 > 꼬리TIP 쓰기
    {path: '/board/tip/save',                  name: 'tipSave',         component: () => import('@/views/board/tip/save')},
    // 게시판 > 게시판관리 > 연구일지
    {path: '/board/lab/list',                  name: 'labList',         component: () => import('@/views/board/lab/list')},
    // 게시판 > 게시판관리 > 연구일지 쓰기
    {path: '/board/lab/save',                  name: 'labSave',         component: () => import('@/views/board/lab/save')},
    // 게시판 > 게시판관리 > 랜선집사
    {path: '/board/md/list',                   name: 'mdList',          component: () => import('@/views/board/md/list')},
    // 게시판 > 게시판관리 > 랜선집사 쓰기
    {path: '/board/md/save',                   name: 'mdSave',          component: () => import('@/views/board/md/save')},
    // 게시판 > 게시판관리 > 꼬뮤니티
    {path: '/board/ggomunity/list',            name: 'ggommuList',     component: () => import('@/views/board/ggomunity/list')},
    // 게시판 > 게시판관리 > 꼬뮤니티 쓰기
    {path: '/board/ggomunity/save',            name: 'ggommuSave',     component: () => import('@/views/board/ggomunity/save')},
    // 게시판 > 게시판관리 > 이벤트
    {path: '/board/event/list',                name: 'eventList',       component: () => import('@/views/board/event/list')},
    // 게시판 > 게시판관리 > 이벤트 쓰기
    {path: '/board/event/save',                name: 'eventSave',       component: () => import('@/views/board/event/save')},
    // 게시판 > 게시판관리 > 공지사항
    {path: '/board/notice/list',               name: 'noticeList',      component: () => import('@/views/board/notice/list')},
    // 게시판 > 게시판관리 > 공지사항 쓰기
    {path: '/board/notice/save',               name: 'noticeSave',      component: () => import('@/views/board/notice/save')},
    // 게시판 > 게시판관리 > 1:1문의
    {path: '/board/inquiry/list',              name: 'inquiryList',     component: () => import('@/views/board/inquiry/list')},
    // 게시판 > 게시판관리 > 1:1문의 쓰기
    {path: '/board/inquiry/save',              name: 'inquirySave',     component: () => import('@/views/board/inquiry/save')},
    // 게시판 > 게시판관리 > FAQ
    {path: '/board/faq/list',                  name: 'faqList',         component: () => import('@/views/board/faq/list')},
    // 게시판 > 게시판관리 > FAQ 쓰기
    {path: '/board/faq/save',                  name: 'faqSave',         component: () => import('@/views/board/faq/save')},
    // 게시판 > 게시판관리 > 삭제 게시물 관리
    {path: '/board/remove',                    name: 'remove',          component: () => import('@/views/board/remove')},
    // 게시판 > 게시판관리 > 신고 게시물 관리
    {path: '/board/report',                    name: 'report',          component: () => import('@/views/board/report')},
    // 게시판 > 게시물 진열 관리 > 메인 분류 관리
    {path: '/board/manage/main',               name: 'manageMain',      component: () => import('@/views/board/manage/main')},
    // 게시판 > 게시물 진열 관리 > 메인 진열 관리
    {path: '/board/manage/mainDisplay',        name: 'displayMain',     component: () => import('@/views/board/manage/mainDisplay')},
    // 게시판 > 게시물 진열 관리 > MD'S PICK 분류 관리
    {path: '/board/manage/md',                 name: 'manageMD',        component: () => import('@/views/board/manage/md')},
    // 게시판 > 게시물 진열 관리 > MD"S PICK 진열 관리
    {path: '/board/manage/mdDisplay',          name: 'displayMD',       component: () => import('@/views/board/manage/mdDisplay')},
    // 게시판 > 게시물 진열 관리 > 나이별 추천 컨텐츠
    {path: '/board/manage/age',                name: 'ageContent',      component: () => import('@/views/board/manage/age')},
    // 게시판 > 댓글관리 > 댓글 리스트
    {path: '/board/comment/list',              name: 'commentList',     component: () => import('@/views/board/comment/list')},
    // 게시판 > 댓글관리 > 삭제 댓글 관리
    {path: '/board/comment/remove',            name: 'commentRemove',   component: () => import('@/views/board/comment/remove')},
    // 게시판 > 활동관리 > 활동 리스트
    {path: '/board/activity',                  name: 'activity',        component: () => import('@/views/board/activity')},

    // 상품 > 상품 관리 > 상품 리스트
    {path: '/product/list',                    name: 'productList',     component: () => import('@/views/product/list')},
    // 상품 > 상품 관리 > 상품 리스트 저장
    {path: '/product/save',                    name: 'productSave',     component: () => import('@/views/product/save')},
    // 상품 > 상품 진열 관리 > 메인 분류 관리
    {path: '/product/main',                    name: 'productMain',     component: () => import('@/views/product/main')},
    // 상품 > 상품 진열 관리 > 메인 진열 관리
    {path: '/product/mainDisplay',             name: 'productMainDisplay',component: () => import('@/views/product/mainDisplay')},
    // 상품 > 상품 진열 관리 > 추천상품 진열 관리
    {path: '/product/display',                 name: 'productDisplay',  component: () => import('@/views/product/display')},

    // 반려동물 > 반려동물 관리 > 반려동물 리스트
    {path: '/pet/list',                        name: 'petList',         component: () => import('@/views/pet/list')},
    // 반려동물 > 반려동물 관리 > 반려동물 리스트 쓰기
    {path: '/pet/save',                        name: 'pet/save',        component: () => import('@/views/pet/save')},
    // 반려동물 > 반려동물 관리 > 품종 관리
    {path: '/pet/breed/list',                  name: 'pet/breed/list',  component: () => import('@/views/pet/breed/list')},
    // 반려동물 > 반려동물 관리 > 품종 관리 쓰기
    {path: '/pet/breed/save',                  name: 'pet/breed/save',  component: () => import('@/views/pet/breed/save')},
    // 반려동물 > 반려동물 관리 > 접종 관리
    {path: '/pet/vaccine/list',                name: 'pet/vaccine/list',component: () => import('@/views/pet/vaccine/list')},
     // 반려동물 > 반려동물 관리 > 접종 쓰기
    {path: '/pet/vaccine/save',                name: 'pet/vaccine/save',component: () => import('@/views/pet/vaccine/save')},

    // 디자인 > 디자인설정 > 배너 관리
    {path: '/design/banner/list',              name: 'design/banner/list', component: () => import('@/views/design/bannerList')},

]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router