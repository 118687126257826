const session = {
    namespaced: true,
    state: {
        authToken: null,
        id: null,
        email: null,
        name: null,
        nickName: null,
        phone: null,
    },
    mutations: {
        MUT_SET_SESSION(state, payload) {
            if (payload.authToken) state.authToken = payload.authToken
            if (payload.id) state.id = payload.id
            if (payload.email) state.email = payload.email
            if (payload.name) state.name = payload.name
            if (payload.nickName) state.nickName = payload.nickName
            if (payload.phone) state.phone = payload.phone
        },
        MUT_SET_CLEAR_SESSION(state) {
            state.authToken = null
            state.id = null
            state.email = null
            state.name = null
            state.nickName = null
            state.phone = null
        },
    },
    actions: {
        setSession({commit}, payload) {
            commit("MUT_SET_SESSION", payload);
        },
        clearSession({commit}) {
            commit("MUT_SET_CLEAR_SESSION");
        },
    },
    getters: {
        isLogin: state => {
            return state.authToken ? true : false
        },
        getAuthToken: state => {
            return state.authToken
        },
        getId: state => {
            return state.id
        },
        getEmail: state => {
            return state.email
        },
        getName: state => {
            return state.name
        },
        getNickName: state => {
            return state.nickName
        },
        getPhone: state => {
            return state.phone
        },
    },
}

export default session