<template>
  <div>
    <div class="navi-bar"> 
      <span>회원</span>
      <span>회원 관리</span>
      <span>회원 {{ idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">회원 {{ idx ? '수정' : '등록'}}</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="save" class="btn color-1">{{ idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">기본정보</div>
      <table class="table-cols">
        <colgroup>
          <col width="200">
          <col width="300">
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th class="require">아이디(이메일)</th>
          <td v-if="idx">{{ email }}</td>
          <td v-else><input-field v-model="email" class="table-input" @focusout="chkEmail" :errorMessage="emailMsg" placeholder="@를 포함한 이메일 주소를 입력해주세요." maxLength="100"/></td>
          <th :class="{ 'require' : idx == 0 }">비밀번호</th>
          <td>
            <input-field v-model="password" type="password" class="table-input mr-5" :errorMessage="passwordMsg" placeholder="비밀번호 입력" maxLength="20"/>
            <input-field v-model="password2" type="password" class="table-input" :errorMessage="password2Msg" placeholder="비밀번호 확인" maxLength="20"/>
          </td>
        </tr>
        <tr>
          <th class="require">닉네임</th>
          <td><input-field v-model="nickName" class="table-input" @focusout="chkNickName" :errorMessage="nickNameMsg" placeholder="닉네임(2~15자)" maxLength="15"/></td>
          <th class="require">휴대폰번호</th>
          <td><input-field v-model="phone" class="table-input" @focusout="chkPhone" :errorMessage="phoneMsg" numberOnly placeholder="10 ~ 11자리 숫자만 입력" maxLength="11" /></td>
        </tr>
        </tbody>
      </table>

      <div class="contents-box-title">부가정보</div>
      <table class="table-cols">
        <colgroup>
          <col width="200">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th class="require">가입경로</th>
          <td>
            <label><input type="radio" name="device" value="PC" v-model="device">PC</label>
            <label><input type="radio" name="device" value="M" v-model="device">M</label>
            <div v-if="isShowError.device" class="error-message">{{ $msg('required.select') }}</div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="contents-box-title">약관 동의 내역</div>
      <table class="table-cols">
        <colgroup>
          <col width="200">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th class="require">마케팅, 이벤트 수신동의</th>
          <td>
            <label><input type="radio" name="marketing" value="1" v-model="marketing">수신</label>
            <label><input type="radio" name="marketing" value="0" v-model="marketing">수신거부</label>
            <div v-if="isShowError.marketing" class="error-message">{{ $msg('required.select') }}</div>
          </td>
        </tr>
        <tr>
          <th>이용약관</th>
          <td>동의</td>
        </tr>
        <tr>
          <th>개인정보 수집 이용동의</th>
          <td>동의</td>
        </tr>
        <tr>
          <th>개인정보 제 3자 제공동의</th>
          <td>동의</td>
        </tr>
        <tr>
          <th>만 14세 이상</th>
          <td>14세 이상입니다.</td>
        </tr>
        </tbody>
      </table>

      <template v-if="idx > 0">
        <div class="contents-box-title">반려동물 정보<div><button @click="goPetSave()" class="btn color-9">반려동물 등록</button></div></div>
        <table class="table-rows">
          <thead>
          <tr>
            <th>펫구분</th>
            <th>품종</th>
            <th>이름</th>
            <th>생년월일(나이)</th>
            <th>중성화 여부</th>
            <th>알러지 여부</th>
            <th>등록일/수정일</th>
          </tr>
          </thead>
          <tbody v-if="pets.length > 0">
          <tr v-for="(pet,index) in this.pets" :key="`list-${index}`" @click="goPetSave(pet.idx)" class="cursor-pointer">
            <td>{{ pet.petName }}</td>
            <td>{{ pet.breedName }}</td>
            <td>{{ pet.name }}</td>
            <td>{{ $YYYYMMDDWithFormat(pet.birthday, '.', true) }}</td>
            <td>{{ pet.neuter === 0 ? '중성화전' : pet.neuter === 1 ? '중성화 완료' : '' }}</td>
            <td>{{ pet.allergic === 0 ? '알러지 없음' : pet.allergic === 1 ? '알러지 있음' : '' }}</td>
            <td>
              <p>{{ $getDateFormat(pet.createDate, 'Y-m-d') }}</p>
              <p>{{ $getDateFormat(pet.updateDate, 'Y-m-d') }}</p>
            </td>
          </tr>
          </tbody>
          <tbody v-else><tr><td colspan="7">{{ $msg('nodate.listed') }}</td></tr></tbody>
        </table>
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex"
export default {
  data() {
    return {
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      email: '',
      password: '',
      password2: '',
      nickName: '',
      phone: '',
      device: null,
      marketing: null,
      pets: [],
      emailMsg: '',
      passwordMsg: '',
      password2Msg: '',
      nickNameMsg: '',
      phoneMsg: '',
      isShowError: {
        device: false,
        marketing: false,
      },
    }
  },
  watch: {
    email() { this.emailMsg = '' },
    password() { this.passwordMsg = '' },
    password2() { this.password2Msg = '' },
    nickName() { this.nickNameMsg = '' },
    phone() { this.phoneMsg = '' },
  },
  created() {
    if (this.idx) this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getMember({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.email = data.email
        this.nickName = data.nickName
        this.phone = data.phone
        this.device = data.device
        this.marketing = data.marketingYN
        this.pets = data.pets
      }
    },
    async chkEmail() {
      this.emailMsg = ''
      if (!this.email || !this.email.patternCheck('email')) return false
      const { result } = await this.$api.memberChkEmail({ email: this.email })
      switch (result) {
        case 'success':
          this.emailMsg = ''
          break;
        case 'fail':
        case 'email-no-input':
        case 'email-type-fail':
          this.emailMsg = this.$msg('email.check')
          break;
        case 'email-duple':
          this.emailMsg = this.$msg('email.exist')
          break;
        default:
          this.emailMsg = this.$msg('email.check')
      }
    },
    async chkNickName() {
      this.nickNameMsg = ''
      if (!this.nickName || !this.nickName.patternCheck('nickName')) return false
      const { result } = await this.$api.memberChkName({ nickName: this.nickName })
      switch (result) {
        case 'success':
          this.nickNameMsg = ''
          break;
        case 'fail':
        case 'name-no-input':
        case 'name-check':
        case 'name-length-fail':
          this.nickNameMsg = this.$msg('nickname.check')
          break;
        case 'name-duple':
          this.nickNameMsg = this.$msg('nickname.exist')
          break;
        default:
          this.nickNameMsg = this.$msg('nickname.check')
      }
    },
    async chkPhone() {
      if (!this.phone || !this.phone.patternCheck('phone')) return false
      if (this.idx && this.originPhone == this.phone) return false
      const { result } = await this.$api.memberChkPhone({ phone: this.phone })
      switch (result) {
        case 'success':
          this.phoneMsg = ''
          break;
        case 'fail':
        case 'phone-no-input':
        case 'phone-type-fail':
          this.phoneMsg = this.$msg('phone.check')
          break;
        case 'phone-duple':
          this.phoneMsg = this.$msg('phone.exist')
          break;
        default:
          this.phoneMsg = this.$msg('phone.check')
      }
    },
    validateDevice() {
      if (!this.device) {
        this.isShowError.device = true
        return false
      }
      return true
    },
    validateMarketing() {
      if (this.marketing == null) {
        this.isShowError.marketing = true
        return false
      }
      return true
    },
    validate() {
      if (!this.email) {
        this.emailMsg = this.$msg('required.input')
        return false
      }
      if (!this.email.patternCheck('email')) {
        this.emailMsg = this.$msg('email.check')
        return false
      }
      if (!this.idx) {
        if (!this.password) {
          this.passwordMsg = this.$msg('required.input')
          return false
        }
        if (!this.password.patternCheck('password')) {
          this.passwordMsg = this.$msg('password.check')
          return false
        }
        if (this.password !== this.password2) {
          this.password2Msg = this.$msg('password.no-match')
          return false
        }
      }
      if (this.idx && this.password) {
        if (!this.password.patternCheck('password')) {
          this.passwordMsg = this.$msg('password.check')
          return false
        }
        if (this.password !== this.password2) {
          this.password2Msg = this.$msg('password.no-match')
          return false
        }
      }
      if (!this.nickName) {
        this.nickNameMsg = this.$msg('required.input')
        return false
      }
      if (!this.nickName.patternCheck('nickName')) {
        this.nickNameMsg = this.$msg('nickname.check')
        return false
      }
      if (!this.phone) {
        this.phoneMsg = this.$msg('required.input')
        return false
      }
      if (!this.phone.patternCheck('phone')) {
        this.phoneMsg = this.$msg('phone.check')
        return false
      }

      if (this.emailMsg || this.passwordMsg
          || this.password2Msg || this.nickNameMsg || this.phoneMsg) {
        return false
      }
      return true
    },
    async save() {
      this.isShowError.device = false
      this.isShowError.marketing = false
      if (this.validate() && this.validateDevice() && this.validateMarketing()) {
        const { result } = await this.$api.saveMember({
          idx: this.idx,
          email: this.email,
          password: this.password,
          nickName: this.nickName,
          phone: this.phone,
          device: this.device,
          marketing: this.marketing,
        })
        this.alertPopup(result === 'success' ? this.$msg('ok.save') : this.$msg('fail'))
      }
    },
    goPetSave(idx=0) {
      console.log("> idx : ", idx)
      if (idx) {
        this.$router.push(`/pet/save?idx=${idx}`)
      }
      else {
        console.log("> this.idx : ", this.idx)
        console.log("> this.email : ", this.email)
        this.$router.push(`/pet/save?memIdx=${this.idx}&memEmail=${this.email}`)
      }
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/member/list${query}`)
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          this.goList()
        },
      })
    },
  }
}
</script>
