<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>신고 게시물 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">신고 게시물 관리</div>
    </div>


    <div class="layout-contents-box"> 

      <table class="table-cols">
        <colgroup>
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th>등록일</th>
          <td><date ref="date"/></td>
        </tr>
        <tr>
          <th>전시상태</th>
          <td>
            <label><input type="radio" name="blindYN" value="" v-model="blindYN">전체</label>
            <label v-for="item in blinds" :key="`blind-${item.value}`"><input type="radio" name="blindYN" :value="item.value" v-model="blindYN">{{ item.text }}</label>
          </td>
        </tr>
        <tr>
          <th>검색어</th>
          <td>
            <div class="d-flex">
              <div class="w-100 mr-10">
                <select v-model="target">
                  <option v-for="item in targets" :key="`target-${item.value}`" :value="item.value">{{ item.text }}</option>
                </select>
              </div>
              <div class="w-300">
                <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="btn-box center my-20">
        <div>
          <button class="btn color-7" @click="search">검색</button>
        </div>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="select-box-div">
          <select v-model="orderBy" @change="search">
            <option v-for="item in orderBys" :key="`orderBy-${item.value}`" :value="item.value">{{ item.text }}</option>
          </select>
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>


      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col width="100">
          <col width="100">
          <col width="300">
          <col width="50">
          <col width="100">
          <col width="100">
          <col width="100">
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>전시상태</th>
          <th>게시판명</th>
          <th>제목</th>
          <th>신고</th>
          <th>아이디/닉네임</th>
          <th>등록일</th>
          <th>블라인드 처리일</th>
        </tr>
        </thead>
        <tbody v-if="list && list.length > 0">
        <tr v-for="(item, index) in list" :key="`list-${index}`">
          <td><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
          <td>{{ item.blindYN ? '블라인드' : '정상' }}</td>
          <td>{{ item.categoryName }}</td>
          <td class="left">
            {{ item.title }}
            <button v-if="!item.blindYN" @click.stop="goWeb(item.cateCode, item.idx)" class="icon-link"/>
          </td>
          <td @click="openReportPopup(item.idx)" class="cursor-pointer">{{ item.reportCount }}</td>
          <td class="left">
            <p>{{ item.email }}</p>
            <p>{{ item.nickName }}</p>
          </td>
          <td>{{ item.createDate }}</td>
          <td>
            <template v-if="item.blindDate">{{ item.blindDate }}</template>
            <template v-else>-</template>
          </td>
        </tr>
        </tbody>
        <tbody v-else><tr><td colspan="8">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-20">
        <div>
          <button class="btn btn-outline color-7" @click="ready(1)">블라인드 처리</button>&nbsp;
          <button class="btn btn-outline color-7" @click="ready(0)">블라인드 해제</button>
        </div>
      </div>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>

    <board-report-popup v-if="reportId" :idx="reportId" @close="reportId = null"/>

  </div>
</template>
<script>
import {mapActions} from "vuex"
import listMixin from '@/mixins/listMixin'
import boardReportPopup from '@/components/common/boardReportPopup'
export default {
  name: 'removeList',
  components: { boardReportPopup },
  mixins: [listMixin],
  data() {
    return {
      target: 1,
      targets: [
        { text: '제목', value: 1 },
        { text: '이메일(아이디)', value: 2 },
        { text: '닉네임', value: 3 },
      ],
      text: null,
      blindYN: '',
      blinds: [
        { text: '정상', value: 0 },
        { text: '블라인드', value: 1 },
      ],
      orderBy: 'new',
      orderBys: [
        { text: '최신순', value: 'new' },
        { text: '신고 많은 순', value: 'count' },
      ],
      reportId: null,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getList() {
      const { result, list, total } = await this.$api.getBoardReportList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        target: this.target,
        text: this.text,
        blindYN: this.blindYN,
        orderBy: this.orderBy,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
        this.selectedIdxs = []
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    openReportPopup(id) {
      this.reportId = id
    },
    ready(isBlind) {
      if (this.selectedIdxs.length > 0) {
        this.confirmBlindPopup(isBlind)
      }
      else {
        this.alertPopup(this.$msg('please.board-select'))
      }
    },
    confirmBlindPopup(isBlind) {
      const msg = this.$msg(isBlind ? 'confirm.blind-true' : 'confirm.blind-false' )
      this.setMsgPopup({
        type: 'confirm',
        message: msg,
        okay: 'BLIND',
        okayCallback: async() => {
          this.setMsgPopup()
          if (!this.selectedIdxs) return false
          const { result } = await this.$api.setBlind({ idxs: this.selectedIdxs, blindYN: isBlind });
          if (result === 'success') {
            this.allChecked = false
            this.alertPopup(this.$msg(isBlind ? 'ok.blind-true' : 'ok.blind-false'))
            this.getList().then()
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    goWeb(cateCode, idx) {
      let target = ''
      if (cateCode.toString().startsWith('80')) target = 'ggomunity'
      window.open(`${this.$domain}/${target}/${idx}`)
    },
  }
}
</script>