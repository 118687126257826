<template>
  <div>    
    <div class="navi-bar">
      <span>디자인</span>
      <span>디자인 설정</span>
      <span>배너 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">배너 관리</div>
      <div>
        <div class="btn btn-outline color-6" @click="openCtgryPopup">카테고리 설정</div>
        <button class="btn color-1" @click="setSortBanner">저장</button>
      </div>
    </div>

    <ctgry-popup v-if="isOpenCtgryPopup" cateCode="70" @close="closeCtgryPopup"/>

    <div class="layout-contents-box">
      <div class="contents-box-title">노출 영역</div>
      <table class="table-cols">
        <colgroup>
            <col width="180">
            <col>
        </colgroup>
        <tbody>
        <tr>
          <th class="require">노출 영역</th>
          <td>
            <div class="w-400 mr-10">
              <select v-model="ctgry">
                <option v-for="item in ctgryList" :key="`target-${item.cateCode}`" :value="item">{{ item.categoryName }}</option>
              </select>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
    
    <div class="layout-contents-box">
      <div class="contents-box-title">배너리스트</div>
      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col width="100">
          <col>
          <col>
          <col width="100">
          <col width="100">
          <col width="100">
          <col width="200">
          <col width="130">
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>진열순서</th>
          <th>이미지</th>
          <th>노출영역</th>
          <th>링크</th>
          <th>링크방식</th>
          <th>노출여부</th>
          <th>노출기간</th>
          <th>관리</th>
        </tr>
        </thead>
        <tbody v-if="list && list.length > 0">
          <tr v-for="item in list" :key="`list-${item.idx}`" class="cursor-pointer">
            <td @click.stop><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
            <td>{{ item.sorting }}</td>
            <td class="banner left"><image-alt v-if="item.img" :src="item.img" altType="banner" /></td>
            <td>{{ item.categoryName }}</td>
            <td><button @click.stop="goLink(item.link)" class="icon-link"/></td>
            <td>{{ item.linkTarget === '_blank' ? '새창' : '현재창' }}</td>
            <td>
              <div v-if="item.view"class="btn btn-outline w-70 btn-x-sm color-1">노출</div>
              <div v-else class="btn btn-outline w-70 btn-x-sm color-6">미노출</div>
            </td>
            <td>
              <span v-if="item.viewType"> {{ $getDateFormat(item.startDate, 'Y-m-d') }} ~ {{ $getDateFormat(item.endDate, 'Y-m-d') }}</span>
              <span v-else>상시노출</span>
            </td>
            <td>
              <button class="btn btn-x-sm color-6" @click="confirmRemovePopup(item.idx)">삭제</button>
              <button class="btn btn-x-sm color-6" @click="openBannerPopup(item.idx)">수정</button>
            </td>
          </tr>
        </tbody>
        <tbody v-else><tr><td colspan="9" class="no-data">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-10">
        <div>
          <button class="btn icon-itemArrow first" @click="sort('first')">맨위로</button>
          <button class="btn icon-itemArrow up" @click="sort('up')">위로</button>
          <button class="btn icon-itemArrow down" @click="sort('down')">아래로</button>
          <button class="btn icon-itemArrow last" @click="sort('last')">맨아래로</button>
          <button class="btn btn-sm btn-outline color-7" @click="openBannerPopup(0)">배너등록</button>
        </div>
      </div>
      
    </div>

    <banner-popup v-if="isOpenBannerPopup" :idx="bannerIdx" :ctgry="ctgry" @save="save" @remove="confirmRemovePopup" @close="closeBannerPopup"/>
  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'
import displayMixin from '@/mixins/displayMixin'
import bannerPopup from '@/components/common/bannerPopup'
export default {
  name: "bannerList",
  components: { bannerPopup },
  mixins: [listMixin, displayMixin],
  data() {
    return {
      ctgryList: null,
      ctgry: [],
      isOpenCtgryPopup: false,
      bannerIdx: null,
      isOpenBannerPopup: false,
    };
  },
  watch: {
    ctgry() {
      this.getList()
    },
  },
  created() {
    this.getCtgryList(70).then(() => {
      this.ctgry = this.ctgryList ? this.ctgryList[0] : null
    })
    this.getList()
  },
  methods: {
    async getList() {
      const { result, list } = await this.$api.getBannerList({ cateCode: this.ctgry.cateCode })
      if (result === 'success') {
        this.list = list
        this.selectedIdxs = []
      }
    },
    async setSortBanner() {
      const { result } = await this.$api.setSortBanner({ idxs: this.getIdxs() })
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'))
        this.getList().then()
      }
      else {
        this.alertPopup(this.$msg('fail'))
      }
    },
    goLink(url) {
      window.open(url, '_blank')
    },
    save(isSuccess) {
      if (isSuccess) {
        this.closeBannerPopup()
        this.alertPopup(this.$msg('ok.save'))
        this.getList()
      }
      else {
        this.alertPopup(this.$msg('fail'))
      }
    },
    async remove(idx) {
      const { result } = await this.$api.removeBanner({ idx: idx });
      if (result === 'success') {
        this.setMsgPopup()
        this.closeBannerPopup()
        this.getList().then()
      }
    },
    openCtgryPopup() {
      this.isOpenCtgryPopup = true
    },
    closeCtgryPopup() {
      this.getCtgryList(70)
      this.isOpenCtgryPopup = false
    },
    openBannerPopup(idx){
      document.body.style.overflowY = "hidden"
      this.bannerIdx = idx
      this.isOpenBannerPopup = true;
    },
    closeBannerPopup(){
      document.body.style.overflowY ="scroll"
      this.bannerIdx = null
      this.isOpenBannerPopup = false;
    },
    confirmRemovePopup(idx) {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove4'),
        okay: 'REMOVE',
        okayCallback: () => {
          this.remove(idx)
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  },
}
</script>