<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>FAQ</span>
      <span>{{ this.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">FAQ</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="setSaveParams" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">게시글 {{ this.idx ? '수정' : '등록'}}</div>
        <table class="table-cols">
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">카테고리</th>
            <td><select-box :selectBoxClass="'w-100 mr-5'" :options="opt.cateCode" v-model="cateCode" :errorMessage="cateCodeMsg"/></td>
          </tr>
          <tr>
            <th class="require">제목</th>
            <td colspan="3"><input-field v-model="title" class="w-300 mr-5 d-inline-block" :errorMessage="titleMsg" lengthCount maxLength="50"/></td>
          </tr>
          </tbody>
        </table>

        <editor ref="editor" :cateCode="5012" :previewH="this.previewH"/>
      </div>
    </validateForm>
  </div>
</template>

<script>
import {mapActions} from "vuex"
import saveEditorMixin from "@/mixins/saveEditorMixin"

export default {
  name: 'faqSave',
  mixins: [ saveEditorMixin ],
  data() {
    return {
      opt: {
        cateCode: [],
      },
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      cateCode: null,
      title: null,
      content: null,
      cateCodeMsg: '',
      titleMsg: '',
    }
  },
  watch: {
    cateCode() {
      this.cateCodeMsg = ''
    },
    title() {
      this.titleMsg = ''
    },
  },
  mounted() {
    this.getCtgryList(5012)
    if (this.idx) this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getFaq({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.cateCode = data.cateCode
        this.title = data.title

        this.$refs.editor.editor.commands.setContent(data.content)
        this.$refs.editor.savedContent = data.content
        this.$refs.editor.editorImageIds = this.getImageIds(data.content)
        this.$refs.editor.savedImageIds = this.getImageIds(this.$refs.editor.savedContent)
      }
    },
    validate() {
      if (!this.cateCode) {
        this.cateCodeMsg = this.$msg('required.select')
        return false
      }
      if (!this.title) {
        this.titleMsg = this.$msg('required.input')
        return false
      }
      return true
    },
    setSaveParams() {
      if (this.validate()) {
        const params = {
          idx: this.idx,
          cateCode: this.cateCode,
          title: this.title,
          content: this.$refs.editor.editor.getHTML(),
          editImg: this.$refs.editor.getEditorImageIds(),
        }
        this.save(params)
      }
    },
    async save(params) {
      const { result } = await this.$api.faqSave(params)
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'), `/board/faq/list`)
      }
      else {
        this.alertPopup(this.$msg('fail'), null)
      }
    },
    alertPopup(msg, movePath) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (movePath) location.href = movePath
        },
      })
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/board/faq/list${query}`)
    }
  }
}
</script>