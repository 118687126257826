<template>
  <div class="popupBox-wrap">
    <div class="popupPosition" @click="close"></div>
<!--    <div class="popupPosition" @click.stop="onNoClick"></div>-->
    <div v-if="confirmType === 'comment-report'" class="popupBox w-600">
      <div class="popupBox-title center">{{ $msg('report') }}</div>
      <div class="popupBox-body">
        <div class="subtitle-1">{{ $msg('reason') }}<span class="color-1">*</span></div>
        <validateForm ref="form">
          <input-field multiline rows="5" v-model="reason" :validate="rules.report" :placeholder="this.$msg('placeholder.report-reason')"></input-field>
        </validateForm>
      </div>
      <div class="popupBox-bottom">
        <button @click.stop="onNoClick" class="w-90 mr-10 btn btn-sm btn-outline color-6">{{ $msg('cancel') }}</button>
        <button @click.stop="onYesCommentReport" class="w-90 btn btn-sm fw-bold color-1">{{ $msg('report') }}</button>
      </div>
    </div>

    <div v-else-if="confirmType === 'join-leave'" class="popupBox w-600">
      <div class=" center">{{ $msg('confirm') }}</div>
      <div class="popupBox-body">{{ $msg('confirm.leave') }}</div>
      <div class="popupBox-bottom">
        <button @click.stop="" class="w-90 mr-10 btn btn-sm btn-outline color-6">{{ $msg('cancel') }}</button>
        <button @click.stop="onYesClickWrapper" class="w-90 btn btn-sm fw-bold color-1">{{ $msg('leave') }}</button>
      </div>
    </div>

    <div v-else-if="confirmType === 'confirm'" class="popupBox w-600">
      <div class="popupBox-title center">{{ $msg('confirm') }}</div>
      <div class="popupBox-body ta-left" v-html="confirmMessage"></div>
      <div class="popupBox-bottom">
        <button @click.stop="onNoClick" class="w-90 mr-10 btn btn-sm btn-outline color-6">{{ $msg('cancel') }}</button>
        <button @click.stop="onYesClickWrapper" class="w-90 btn btn-sm fw-bold color-1">{{ $msg('confirm') }}</button>
      </div>
    </div>

    <div v-else-if="confirmType === 'confirm-save'" class="popupBox w-600">
      <div class="popupBox-title center">{{ $msg('confirm') }}</div>
      <div class="popupBox-body ta-left">{{ confirmMessage }}</div>
      <div class="popupBox-bottom">
        <button @click.stop="onNoClick" class="w-90 mr-10 btn btn-sm btn-outline color-6">{{ $msg('cancel') }}</button>
        <button @click.stop="onYesClickWrapper" class="w-90 btn btn-sm fw-bold color-1">{{ $msg('save') }}</button>
      </div>
    </div>

    <div v-else class="popupBox w-600">
      <div class="popupBox-title center">{{ $msg('confirm') }}</div>
      <div class="popupBox-body ta-left">{{ confirmMessage }}</div>
      <div v-if="onYesClick && onNoClick" class="popupBox-bottom">
        <button @click.stop="onNoClick" class="w-90 mr-10 btn btn-sm btn-outline color-6">{{ $msg('cancel') }}</button>
        <button @click.stop="onYesClickWrapper" class="w-90 btn btn-sm fw-bold color-1">{{ $msg('confirm') }}</button>
      </div>
      <div v-else class="popupBox-bottom">
        <button @click.stop="onYesClickWrapper" v-focus class="w-90 btn btn-sm fw-bold color-1">{{ $msg('confirm') }}</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['confirmType', 'onYesClick', 'onNoClick', 'confirmMessage', 'confirmTitle', 'confirmParam1', 'confirmTarget'],
  data() {
    return {
      reason: null,
      rules: {
        report: [v => !!v || this.$msg('required.input')],
      }
    }
  },
  computed: {
    getConfirmTitle() {
      if (this.confirmTitle) {
        if (this.confirmTitle == 'goto-login') {
          return 'Error (Please login again)';
        }
        return this.confirmTitle;
      }
      return 'Nossstice';
    },
  },
  created() {
  },
  methods: {
    onYesClickWrapper(event) {
      this.onYesClick(event);
    },
    onYesCommentReport(event) {
      if (this.$refs.form.validate()) {
        this.onYesClick(event);
      }
    },
    close() {
      this.$emit('close')
    }
  }
}

</script>
