const findRoot = (which) => {
    let ok = false;
    let that = which;
    while (!ok) {
        if (that.$options._componentTag === "tree") {
            ok = true;
            break;
        }
        that = that.$parent;
    }
    return that;
    };

export { findRoot };