<template>
  <div>
    <div class="navi-bar"> 
      <span>운영관리</span>
      <span>기본정책</span>
      <span>단어필터링</span>
    </div>
    <div class="page-title-box">
      <div class="page-title">단어필터링</div>
      <div class=""><button @click="save" class="btn color-1">저장</button></div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">필터 단어</div>
        <div class="contents-box-sub-title">* 단어는 쉼표(,)로 구분해주세요.</div>
        <input-field multiline rows="10" v-model="word" :validate="rules.word" placeholder="ex) 광고,마케팅"></input-field>
      </div>
    </validateForm>

    <div class="information-box">
      <div class="information-box-title">안내</div>
      <div class="information-contents">
        <ul class="dot-list">
          <li>필터 설정된 단어를 포함한 댓글, 답글은 등록이 불가능합니다.</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex"
export default {
  data(){
    return{
      word: null,
      rules: {
        word: [v => !!v || this.$msg('required.input')],
      },
    }
  },
  created() {
    this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem(){
      const { data } = await this.$api.getWordFilter()
      this.word = data
    },
    async save() {
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.setWordFilter({ word: this.word })
        this.alertPopup(result === 'success' ? this.$msg('ok.save') : this.$msg('fail'))
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}
</script>