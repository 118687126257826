<template>
  <div>
    <div class="navi-bar">
      <span>상품</span>
      <span>상품 진열 관리</span>
      <span>메인 분류 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">메인 분류 관리</div>
    </div>


    <div class="layout-contents-box">
      <table class="table-rows">
        <colgroup>
          <col>
          <col>
          <col width="100">
        </colgroup>
        <thead>
        <tr>
          <th>메인 분류명</th>
          <th>메인 서브문구</th>
          <th>관리</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="left"><input-field v-model="text" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요."
                                        :errorMessage="textMsg" @keypress.enter="save"/></td>
          <td class="left"><input-field v-model="subText" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요."
                                        @keypress.enter="save"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save">수정</button></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: 'productMain',
  data() {
    return {
      code: null,
      text: null,
      subText: null,
      textMsg: '',
    }
  },
  watch: {
    text() { this.textMsg = '' },
  },
  created() {
    this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getProductMainCategory()
      if (result === 'success') {
        this.code = data[0].cateCode
        this.text = data[0].cateName
        this.subText = data[0].cateSubName
      }
    },
    validate() {
      if (!this[`text`]) {
        this[`textMsg`] = this.$msg('required.input')
        return false
      }
      return true
    },
    async save() {
      if (this.validate()) {
        const { result } = await this.$api.setProductMainCategory({
          cateCode: this.code,
          cateName: this[`text`],
          cateSubName: this[`subText`],
        })
        this.alertPopup(result === 'success' ? this.$msg('ok.save') : this.$msg('fail'))
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}
</script>