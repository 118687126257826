export default {
  // 유효성검사(Validation)
  "required.input": "필수 입력 항목입니다.",
  "required.select": "필수 선택 항목입니다.",
  "email.check": "이메일 형식에 맞지 않습니다.",
  "email.exist": "이미 등록된 이메일 입니다.",
  "id.check": "영문,숫자만 입력 가능합니다.",
  "id.exist": "이미 등록된 아이디 입니다.",
  "name.check": "한글만 입력 가능합니다.",
  "password.check": "영문,숫자,특수문자 조합의 8 ~ 20자 이내로 입력해주세요.",
  "password.no-match": "비밀번호가 일치하지 않습니다.",
  "nickname.check": "2~15자 이내의 한글,영문,숫자만 입력해주세요.",
  "nickname.exist": "이미 등록된 닉네임 입니다.",
  "phone.check": "형식에 맞지 않는 번호입니다.",
  "phone.exist": "이미 가입된 번호입니다.",
  "bizNum.check": "사업자등록번호 형식에 맞지 않습니다.",
  "repImage.check": "대표 이미지를 등록해주세요.",
  "image.check": "이미지를 등록해주세요.",
  "image.limit": "제한된 용량(10 MB)을 초과하였습니다.",
  "image.ext": "jpg, png, gif 확장자만 첨부 가능합니다.",

  // 안내메시지(alert)
  "cancel": "취소",
  "confirm": "확인",
  "report": "신고",
  "reason": "사유",
  "leave": "나가기",
  "save": "저장",
  "placeholder.report-reason": "신고 사유를 입력해주세요.",
  "confirm.remove": "삭제된 내역은 복구가 불가능합니다. 삭제하시겠습니까?",
  "confirm.remove2": "선택한 게시물을 삭제하시겠습니까?",
  "confirm.remove3": "선택한 게시물을 삭제하시겠습니까?<br>삭제된 게시물은 [삭제 게시물 관리]에서 확인가능합니다.",
  "confirm.remove4": "삭제된 데이터는 복구가 불가능합니다. 삭제하시겠습니까?",
  "confirm.remove.file": "삭제된 파일은 복구가 불가능합니다. 삭제하시겠습니까?",
  "confirm.saveMove": "저장되지 않은 내용이 있습니다. 내용을 저장하고 이동하시겠습니까?",
  "confirm.audit-complete": "선택한 컨텐츠를 심사완료 처리 하시겠습니까?",
  "confirm.restore.posts": "선택한 게시물을 복구하시겠습니까?",
  "confirm.display-except.posts": "선택한 게시물을 진열제외 하시겠습니까?",
  "confirm.comment.open": "선택한 댓글을 공개 처리 하시겠습니까?",
  "confirm.comment.close": "선택한 댓글을 비공개 처리 하시겠습니까?",
  "confirm.comment.remove": "선택한 댓글을 삭제하시겠습니까?<br>삭제된 댓글은 [삭제 댓글 관리]에서 확인가능합니다.",
  "confirm.comment.remove2": "댓글 및 댓글에 달린 모든 답글까지 삭제 하시겠습니까?",
  "confirm.comment.restore": "선택한 댓글을 복구하시겠습니까?",
  "confirm.display-except.product": "선택한 상품을 진열제외 하시겠습니까?",
  "confirm.withdrawal": "선택한 회원을 탈퇴처리하시겠습니까?",
  "confirm.sleep-off": "선택한 회원을 휴면해제 하시겠습니까?",
  "confirm.blind-true": "선택한 게시물을 블라인드 처리하시겠습니까?",
  "confirm.blind-false": "선택한 게시물을 블라인드 해제하시겠습니까?",
  "ok.save": "정상적으로 저장되었습니다.",
  "ok.insert": "정상적으로 등록되었습니다.",
  "ok.update": "정상적으로 수정되었습니다.",
  "ok.delete": "정상적으로 삭제되었습니다.",
  "ok.restore": "정상적으로 복구되었습니다.",
  "ok.sleep-off": "정상적으로 휴면해제 되었습니다.",
  "ok.send": "정상적으로 발송되었습니다.",
  "ok.publish": "발행 신청이 완료되었습니다.",
  "ok.withdrawal": "정상적으로 탈퇴 처리 되었습니다.",
  "ok.blind-true": "정상적으로 블라인드 처리 되었습니다.",
  "ok.blind-false": "정상적으로 블라인드 해제 되었습니다.",
  "fail.delete.classification": "해당 코드를 참조하는 데이터 존재 시 삭제가 불가능합니다.",
  "login.fail": "가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.",
  "login.no-exist": "가입 내역이 존재하지 않습니다.",
  "no-data-selected": "선택된 데이터가 존재하지 않습니다.",
  "please.board-select": "게시물을 선택해 주세요.",
  "check.remove.manager.exist": "로그인 계정 존재 시 삭제가 불가능합니다.",
  "content.limit1": "10자 이상의 텍스트를 입력해주세요.",
  "content.limit2": "최대 글자 수(2,000자)를 초과하였습니다.",
  "ctgry.limit": "최대 10개까지만 등록 가능합니다.",
  "ctgry.check": "카테고리 명을 입력해주세요.",
  "temp.audit-check": "임시저장 상태의 게시글은 상태 변경이 불가능합니다.",
  "event.date.check": "이벤트 기간을 확인해주세요.",
  "url.check": "https:// 포함하여 입력해주세요.",
  "search.keyword": "검색어 입력 후 검색을 클릭해주세요.",

  // 기타
  "nodata": "저장된 내역이 존재하지 않습니다.",
  "nodata2": "데이터가 존재하지 않습니다.",
  "nodata.select": "선택된 게시물이 존재하지 않습니다.",
  "nodata.inserted": "등록된 게시글이 존재하지 않습니다.",
  "nodate.listed": "등록된 내역이 존재하지 않습니다.",
  "nodata.dashboard.inquiry": "답변대시 상태의 문의내역이 존재하지 않습니다.",
  "type.check": "분류를 선택해주세요.",

  "fail": "실패하였습니다.\n관리자에 문의해 주세요.",
}
