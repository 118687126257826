<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>이벤트</span>
      <span>등록</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">이벤트</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="setSaveParams" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">게시글 {{ this.idx ? '수정' : '등록'}}</div>
      <table class="table-cols" ref="table">
        <colgroup>
          <col width="200">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th class="require">진행여부</th>
          <td colspan="3"><select-box :selectBoxClass="'w-100 mr-5'" :options="opt.close" v-model="close" :errorMessage="closeMsg"/></td>
        </tr>
        <tr>
          <th class="require">이벤트 기간</th>
          <td>
            <date ref="date" noBtn :startdate="eventStart" :enddate="eventEnd"/>
            <div v-if="dateMsg" class="error-message">{{ dateMsg }}</div>
          </td>
        </tr>
        <tr>
          <th class="require">제목</th>
          <td colspan="3"><input-field v-model="title" class="w-300 mr-5 d-inline-block" :errorMessage="titleMsg" lengthCount maxLength="50"/></td>
        </tr>
        <tr>
          <th class="require">대표이미지</th>
          <td colspan="3"><upload-image ref="repImage" @upload="setRepImg" :imgSrc="repImgSrc" :imgName="repImgName" class="file-box w-400"/></td>
        </tr>
        </tbody>
      </table>

      <editor ref="editor" :cateCode="40" :previewH="this.previewH"/>

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
import saveEditorMixin from "@/mixins/saveEditorMixin"

export default {
  name: 'eventSave',
  mixins: [ saveEditorMixin ],
  data() {
    return {
      opt: {
        close: [
          { value: null, text: '선택' },
          { value: '0', text: '진행중'},
          { value: '1', text: '종료'}
        ],
      },
      repImgSrc: null,
      repImgName: null,
      repImgData: null,
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      close: null,
      eventStart: null,
      eventEnd: null,
      title: null,
      content: null,
      closeMsg: '',
      dateMsg: '',
      titleMsg: '',
    }
  },
  mounted() {
    if (this.idx) this.setItem()
    this.setPreviewHeight()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getEvent({ idx: this.idx })
      if (result === 'success') {
        this.getEvent = data.getEvent
        this.close = data.close.toString()
        this.title = data.title
        this.repImgSrc = data.repImgSrc
        this.repImgName = data.repImgName

        this.$refs.date.startDate = data.eventStart
        this.$refs.date.endDate = data.eventEnd

        this.$refs.editor.editor.commands.setContent(data.content)
        this.$refs.editor.savedContent = data.content
        this.$refs.editor.editorImageIds = this.getImageIds(data.content)
        this.$refs.editor.savedImageIds = this.getImageIds(this.$refs.editor.savedContent)
        this.setPreviewHeight()
      }
    },
    validate() {
      this.dateMsg = null
      if (!this.close) {
        this.closeMsg = this.$msg('required.select')
        return false
      }
      if (!this.$refs.date.startDate || !this.$refs.date.endDate) {
        this.dateMsg = this.$msg('required.select')
        return false
      }
      if (!this.title) {
        this.titleMsg = this.$msg('required.input')
        return false
      }
      if (!this.repImgName) {
        this.$refs.repImage.msg = this.$msg('repImage.check')
        return false
      }
      return true
    },
    setSaveParams() {
      if (this.validate()) {
        const params = {
          idx: this.idx,
          close: Number(this.close),
          eventStart: this.$refs.date ? this.$refs.date.startDate : null,
          eventEnd: this.$refs.date ? this.$refs.date.endDate : null,
          title: this.title,
          repImgData: this.repImgData,
          repImgName: this.repImgName,
          content: this.$refs.editor.editor.getHTML(),
          editImg: this.$refs.editor.getEditorImageIds(),
        }
        this.save(params)
      }
    },
    async save(params) {
      const { result } = await this.$api.eventSave(params);
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'), `/board/event/list`)
      }
      else {
        this.alertPopup(this.$msg('fail'), null)
      }
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/board/event/list${query}`)
    },
    alertPopup(msg, movePath) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (movePath) this.$router.push(movePath)
        },
      })
    },
  }
}
</script>