<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 진열 관리</span>
      <span>나이별 추천 컨텐츠</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">나이별 추천 컨텐츠</div>
      <div>
        <button @click="save" class="btn color-1">저장</button>
      </div>
    </div>

    <div class="layout-contents-box">


      <div class="contents-box-title">분류 선택</div>
      <validateForm ref="form">
        <table class="table-cols">
          <colgroup>
            <col width="180">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">펫 구분</th>
            <td><select-box :selectBoxClass="'w-500'" :validate="rules" :options="opt.petCode"  v-model="petCode" @change="getList"/></td>
          </tr>
          <tr>
            <th class="require">나이 구분</th>
            <td><select-box :selectBoxClass="'w-500'" :validate="rules" :options="getAgeOpt"  v-model="age" @change="getList"/></td>
          </tr>
          </tbody>
        </table>
      </validateForm>

      <div class="contents-box-title">진열 게시물 설정</div>

      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col width="100">
          <col width="150">
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>진열순서</th>
          <th>이미지</th>
          <th>제목</th>
        </tr>
        </thead>
        <tbody v-if="this.list && this.list.length > 0">
        <tr v-for="(item, index) in this.list" :key="`list-${index}`">
          <td><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs"></td>
          <td>{{ item.sorting ? item.sorting : '-' }}</td>
          <td><image-alt v-if="item.src" :src="item.src" altType="table" /></td>
          <td class="left">
            <p class="fw-bold">{{ item.title }}</p>
            <p class="color-7">{{ item.email }} / {{ item.nickName }}</p>
          </td>
        </tr>
        </tbody>
        <tbody v-else-if="!age"><tr><td colspan="4">{{ $msg('type.check') }}</td></tr></tbody>
        <tbody v-else><tr><td colspan="4">{{ $msg('nodata.select') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-10">
        <div>
          <button class="btn icon-itemArrow first" @click="sort('first')">맨위로</button>
          <button class="btn icon-itemArrow up" @click="sort('up')">위로</button>
          <button class="btn icon-itemArrow down" @click="sort('down')">아래로</button>
          <button class="btn icon-itemArrow last" @click="sort('last')">맨아래로</button>
          <button class="btn btn-sm btn-outline color-7" @click="readyRemove($msg('confirm.display-except.posts'))">진열제외</button>
          <button class="btn btn-sm btn-outline color-7" @click="openPopup('displayBoard')">게시물 선택</button>
        </div>
      </div>
    </div>

    <display-board-popup v-if="isOpenPopup.displayBoard" :board="board" :boIdxs="getIdxs()" :petCode="petCode" :ageIdx="age" @save="displaySave" @close="closePopup" />
  </div>
</template>
<script>
import {mapGetters} from "vuex"
import displayMixin from '@/mixins/displayMixin'
export default {
  name: 'mainDisplay',
  mixins: [displayMixin],
  data() {
    return {
      opt: {
        petCode: [
          { value: null, text: '선택' },
          {value: 10, text: '강아지'},
          {value: 20, text: '고양이'}
        ],
      },
      board: null,
      age: null,
      petCode: null,
      rules: [v => !!v || this.$msg('required.select')]
    }
  },
  computed: {
    ...mapGetters({
      getAgeGroup: 'common/getAgeGroup',
    }),
    getAgeOpt() {
      const opt = []
      this.getAgeGroup.forEach((age) => {
        opt.push({
          text: age.ageName,
          value: age.idx
        })
      })
      opt.unshift({ value: null, text: '선택' })
      return opt
    },
  },
  created() {
  },
  methods: {
    async getList() {
      if (!(this.petCode && this.age)) return false
      this.board = 'age'
      const { result, list } = await this.$api.getAgeContentList({
        idx: this.age,
        petCode: this.petCode,
      })
      if (result === 'success') {
        this.list = list
        this.selectedIdxs = []
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.saveAgeContent({
          idx: this.age,
          petCode: this.petCode,
          idxs: this.getIdxs()
        })
        if (result === 'success') {
          this.alertPopup(this.$msg('ok.save'))
          this.getList().then()
        }
        else {
          this.alertPopup(this.$msg('fail'))
        }
      }
    },
    displaySave(list) {
      this.list = list
    },
  }
}
</script>