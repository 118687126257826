<template>
  <div class="popupBox-wrap">
    <div class="popupPosition" @click="close"></div>

    <div class="popupBox w-400">
      <div class="popupBox-title">MD'S PICK 분류선택</div>
      <div class="popupBox-body">

        <validateForm ref="form">
          <select-box :options="list" v-model="idx" :validate="rules"/>
        </validateForm>

        <div class="ta-center mt-15">
            <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">취소</button>
            <button @click="save" class="w-90 mr-10 btn btn-sm color-1">등록</button>
        </div>

        <div class="information-box">
          <div class="information-box-title">안내</div>
          <div class="information-contents">
            <ul class="dot-list">
              <li>분류 선택 후 등록 클릭 시 등록과 동시에 선택된 분류로 자동 진열 설정됩니다.</li>
            </ul>
          </div>
        </div>

      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      idx: null,
      rules: [v => !!v || this.$msg('required.select')],
    }
  },
  created() {
    this.getMdManage()
  },
  methods: {
    async getMdManage() {
      const { result, data } = await this.$api.getMdManage()
      if (result === 'success') {
        let md = []
        Object.keys(data).map(function (key) {
          const value = data[key].name
          if (value) md.push({ value: key, text: value },)
        });
        md.unshift({ value: null, text: '선택' })
        this.list = md
      }
    },
    close() {
      this.$emit('close')
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('save', this.idx)
      }
    },
  }
}

</script>
