<template>
  <div>
    <div class="navi-bar"> 
      <span>반려동물</span>
      <span>반려동물 관리</span>
      <span>품종 관리</span>
      <span>{{ this.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">품종 {{ this.idx ? '수정' : '등록'}}</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="save" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">기본정보</div>
        <table class="table-cols">
          <colgroup>
            <col width="200">
            <col width="400">
            <col width="200">
            <col width="450">
          </colgroup>
          <tbody>

          <tr>
            <th>펫구분</th>
            <td>
              <label><input type="radio" name="petCode" value="10" v-model="petCode">강아지</label>
              <label><input type="radio" name="petCode" value="20" v-model="petCode">고양이</label>
            </td>
            <th class="require">품종명</th>
            <td>
              <div class="w-400">
                <input-field v-model="breedName" :validate="rules" placeholder="품종명 입력" maxLength="30" />
              </div>
            </td>
          </tr>
          <tr v-if="petCode == 10">
            <th class="require">크기</th>
            <td colspan="3">
              <label><input type="radio" name="size" value="L" v-model="size">대형견</label>
              <label><input type="radio" name="size" value="M" v-model="size">중형견</label>
              <label><input type="radio" name="size" value="S" v-model="size">소형견</label>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="contents-box-title">나이별 평균 몸무게</div>
        <table class="table-rows" ref="table">
          <colgroup>
            <col width="200">
            <col width="300">
            <col width="250">
            <col width="250">
            <col width="250">
          </colgroup>
          <thead>
            <tr>
              <th>나이</th>
              <th>평균 몸무게 범위</th>
              <th>평균 몸무게 기준(중앙값)</th>
              <th>저체중 기준</th>
              <th>과체중 기준</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in weights" :key="index">
              <td>{{ item.ageName }}</td>
              <td>
                <div class="flex-center">
                  <div class="d-flex">
                    <div class="w-100"><input-field v-model="item.minWeightArr[0]" numberOnly maxLength="3" /></div>
                    <span class="pt-10 px-5">.</span>
                    <div class="w-40"><input-field v-model="item.minWeightArr[1]" numberOnly maxLength="1" /></div>
                    <span class="pt-10 px-5">kg</span>
                  </div>
                  <span class="d-block pt-5 px-10">~ </span>
                  <div class="d-flex">
                    <div class="w-100"><input-field v-model="item.maxWeightArr[0]" numberOnly maxLength="3" /></div>
                    <span class="pt-10 px-5">.</span>
                    <div class="w-40"><input-field v-model="item.maxWeightArr[1]" numberOnly maxLength="1" /></div>
                    <span class="pt-10 px-5">kg</span>
                  </div>
                </div>
              </td>
              <td>{{ getAverage(item) }} kg</td>
              <td>{{ getUnderWeight(item) }} kg</td>
              <td>{{ getOverWeight(item) }} kg</td>
            </tr>
          </tbody>
        </table>
      </div>
    </validateForm>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
export default {
  data() {
    return {
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      petCode: 10,
      breedName: null,
      size: 'L',
      weights: [],
      rules: [v => !!v || this.$msg('required.input')],
    }
  },
  watch: {
    petCode(v) {
      if (v == 10) {
        this.size = 'L'
      }
      else {
        this.size = null
      }
    }
  },
  computed: {
    ...mapGetters({
      getAgeGroup: 'common/getAgeGroup',
    }),
  },
  created() {
    setTimeout(() => {
      this.setWeightsInit()
      if (this.idx) this.setItem()
    }, 300)
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    setWeightsInit() {
      this.getAgeGroup.forEach(age => {
        this.weights.push({ ageIdx: age.idx, ageName: age.ageName, minWeight: null, maxWeight: null, minWeightArr: [null,null], maxWeightArr: [null,null] })
      })
    },
    async setItem() {
      const { result, data } = await this.$api.getBreed({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.petCode = data.petcode
        this.breedName = data.breedName
        this.size = data.size

        this.weights = this.weights.reduce((target, _data, index) => {
          let d = data.weights.find(w => w.ageIdx === _data.ageIdx)
          target.push( d ? {
            ...d,
            minWeight: d.minWeight.toFixed(1),
            maxWeight: d.maxWeight.toFixed(1),
            minWeightArr: d.minWeight.toFixed(1).split('.'),
            maxWeightArr: d.maxWeight.toFixed(1).split('.'),
          } : _data)
          return target;
        }, [])
      }
    },
    getAverage(item) {
      if (item.minWeightArr[0] && item.minWeightArr[1] && item.maxWeightArr[0] && item.maxWeightArr[1]) {
        return ((Number(item.minWeightArr.join('.')) + Number(item.maxWeightArr.join('.'))) / 2).toFixed(2)
      }
      return '-'
    },
    getUnderWeight(item) {
      if (item.minWeightArr[0] && item.minWeightArr[1] && item.maxWeightArr[0] && item.maxWeightArr[1]) {
        return (Number(item.minWeightArr.join('.')) - 0.1).toFixed(1)
      }
      return '-'
    },
    getOverWeight(item) {
      if (item.minWeightArr[0] && item.minWeightArr[1] && item.maxWeightArr[0] && item.maxWeightArr[1]) {
        return (Number(item.maxWeightArr.join('.')) + 0.1).toFixed(1)
      }
      return '-'
    },
    async save() {
      const weight = []
      for(let w of this.weights) {
        if (w.minWeightArr[0] && w.maxWeightArr[0] ) {
          if (!w.minWeightArr[1]) w.minWeightArr[1] = 0
          if (!w.maxWeightArr[1]) w.maxWeightArr[1] = 0
          weight.push({
            ageIdx: w.ageIdx,
            ageName: w.ageName,
            minWeight: w.minWeightArr.join('.'),
            maxWeight: w.maxWeightArr.join('.'),
          })
        }
      }
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.saveBreed({
          idx: this.idx,
          petCode: this.petCode,
          breedName: this.breedName,
          size: this.size,
          weight: JSON.stringify(weight),
        });
        if (result === 'success') {
          this.alertPopup(this.$msg('ok.save'), true)
        }
        else {
          this.alertPopup(this.$msg('fail'))
        }
      }
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/pet/breed/list${query}`)
    },
    alertPopup(msg, isGoList) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (isGoList) this.goList()
        },
      })
    },
  }
}
</script>