<template>
  <ul class="fist-depth">
    <template v-for="(item, index) in list">
      <tree-node :item="item" :depth="increaseDepth" :key="`1depth${index}`" :index="index" :propDisabled="false"/>
    </template>
  </ul>
</template>

<script>
import treeNode from "./treeNode";
export default {
  name: "tree",
  components: { treeNode },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    depth: {
      type: Number,
      default: 0
    },
  },
  watch: {
    data(v) {
      this.emitSetList(v)
    }
  },
  data() {
    return {
      data: this.list,
      isOpen: false,
      isClicked: false,
      isActive: false,
    }
  },
  computed: {
    increaseDepth() {
      return this.depth + 1;
    },
  },
  methods: {
    emitSetList(v) {
      this.$emit("setList", v);
    },
    emitNodeClicked(model, component) {
     this.$emit("nodeClick", model, component);
    },
    emitSetModify(model) {
      this.$emit('setModify', model)
    },
    open(code) {
      this.traverse(this, 'open', code)
    },
    openAll() {
      this.traverse(this, 'openAll')
    },
    closeAll() {
      this.traverse(this, 'closeAll')
    },
    unActive() {
      this.traverse(this, 'unActive')
    },
    traverse(el, flag, orgCode) {
      this.componentCheck(el, flag, orgCode)
      const len = el.$children.length
      if (len > 0) {
        const vueNodes = el.$children
        for (let i = 0; i < len; i++) {
          if (vueNodes[i].$children.length > 0) {
            this.traverse(vueNodes[i], flag, orgCode)
          } else {
            this.componentCheck(vueNodes[i], flag, orgCode)
          }
        }
      }
    },
    componentCheck(el, flag, code) {
      if (el._isVue) {
        let tag = el.$vnode.tag.split('-')
        let tagName = tag[tag.length - 1]
        if (tagName === 'treeNode') {
          if (flag === 'open' && el.item.code == code) el.isOpen = true
          if (flag === 'openAll') el.isOpen = true
          if (flag === 'closeAll') el.isOpen = false
          if (flag === 'unActive') el.isActive = false
        }
      }
    }
  },
};
</script>