<template>
  <div>
    <div class="navi-bar"> 
        <span>운영관리</span>
        <span>운영자 관리</span>
        <span>운영자 리스트</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">운영자 리스트</div>
      <div class=""><button @click="goSave()" class="btn color-1">등록</button></div>
    </div>

    <div class="layout-contents-box">

      <table class="table-cols">
        <colgroup>
            <col width="180">
            <col>
        </colgroup>
        <tbody>
        <tr>
            <th>검색어</th>
            <td>
              <div class="d-flex">
                <div class="w-100 mr-10">
                  <select v-model="target">
                      <option v-for="item in targets" :key="`target-${item.value}`" :value="item.value">{{ item.name }}</option>
                  </select>
                </div>
                <div class="w-300">
                  <input type="text" v-model="text" maxlength="30" @keypress.enter="search">
                </div>
              </div>
            </td>
        </tr>
        </tbody>
      </table>

      <div class="btn-box center my-20">
          <div>
              <button class="btn color-7" @click="search">검색</button>
          </div>
      </div>
    </div>

    <div class="layout-contents-box">
      
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>

      <table class="table-rows">
        <colgroup>
            <col width="50">
            <col>
        </colgroup>
        <thead>
        <tr>
            <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
            <th>아이디</th>
            <th>닉네임</th>
            <th>이름</th>
            <th>휴대폰번호</th>
            <th>이메일</th>
            <th>회원가입일</th>
            <th>최종로그인</th>
        </tr>
        </thead>
        <tbody v-if="list && list.length > 0">
          <tr v-for="item in list" :key="`list-${item.idx}`" @click="goSave(item.idx)" class="cursor-pointer">
            <td @click.stop><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
            <td>{{ item.id }}</td>
            <td>{{ item.nickName }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.phone.phoneNumber() }}</td>
            <td>{{ item.email }}</td>
            <td>{{ $getDateFormat(item.joinDate, 'Y-m-d') }}</td>
            <td>{{ $getDateFormat(item.lastLoginDate, 'Y-m-d') }}</td>
          </tr>
        </tbody>
        <tbody v-else><tr><td colspan="8">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-20">
        <div>
            <button class="btn btn-outline color-7" @click="removeReady">선택 삭제</button>
        </div>  
      </div>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import listMixin from '@/mixins/listMixin'

export default {
  name: 'managerList',
  mixins: [listMixin],
  data() {
    return {
      target: 1,
      targets: [
        { name: '아이디', value: 1 },
        { name: '이름', value: 2 },
        { name: '닉네임', value: 3 },
        { name: '휴대폰', value: 4 },
        { name: '이메일', value: 5 },
      ],
      text: null,
    }
  },
  computed: {
    ...mapGetters({
      getId: 'session/getId',
    }),
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { result, data, total } = await this.$api.getManagerList({
        page: this.page,
        pageSize: this.pageSize,
        target: this.target,
        text: this.text
      })
      if (result === 'success') {
        this.total = total
        this.list = data
        this.selectedIdxs = []
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    removeReady() {
      if (this.selectedIdxs.length > 0) {
        let exist = this.list.filter(l => this.selectedIdxs.includes(l.idx) && l.id == this.getId)
        if (exist.length > 0) this.alertPopup(this.$msg('check.remove.manager.exist'))
        else                  this.confirmRemovePopup()
      }
      else {
        this.alertPopup(this.$msg('no-data-selected'))
      }
    },
    goSave(idx=0) {
      this.$router.push(idx
        ? `/policy/manager/save?idx=${idx}&page=${this.page}`
        : `/policy/manager/save?page=${this.page}`)
    },
    async remove() {
      if (!this.selectedIdxs) return false
      const { result } = await this.$api.removeManager({ idxs: this.selectedIdxs })
      if (result === 'success') {
        // this.list = this.list.filter(l => !this.selectedIdxs.includes(l.idx))
        this.allChecked = false
        this.target = 1
        this.text = null
        this.getList().then()
      }
    }
  }
}
</script>
