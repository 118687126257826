<template>
  <div>
    <div class="navi-bar">
      <span>상품</span>
      <span>상품 진열 관리</span>
      <span>메인 진열 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">메인 진열 관리</div>
      <div>
        <div class="btn btn-outline color-6" @click="openCtgryPopup">카테고리 설정</div>
        <button @click="save" class="btn color-1">저장</button>
      </div>
    </div>

    <product-main-ctgry-popup v-if="isOpenCtgryPopup" :target="cateCode1" @close="closeCtgryPopup"/>

    <div class="layout-contents-box">
      <table class="table-cols">
        <colgroup>
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th class="require">메인 타이틀</th>
          <td>
            <div class="w-400 mr-10">
              <select v-model="cateCode1">
                <option v-for="(item, index) in ctgry1List" :key="`ctgry1-${index}`" :value="item.cateCode">{{ item.cateName }}</option>
              </select>
            </div>
          </td>
        </tr>
        <tr>
          <th class="require">카테고리 선택</th>
          <td>
            <div class="w-400 mr-10">
              <select v-model="cateCode2">
                <option v-for="(item, index) in ctgry2List" :key="`ctgry2-${index}`" :value="item.cateCode">{{ item.cateName }}</option>
              </select>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">진열 상품 설정</div>
      <div class="contents-box-sub-title">* 메인 상품 노출은 최대 10개만 노출됩니다.</div>
      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col width="100">
          <col width="150">
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>진열순서</th>
          <th>이미지</th>
          <th>상품명</th>
        </tr>
        </thead>

        <tbody v-if="this.list && this.list.length > 0">
        <tr v-for="(item, index) in this.list" :key="`list-${index}`">
          <td><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs"></td>
          <td>{{ item.sorting ? item.sorting : '-' }}</td>
          <td><image-alt v-if="item.img" :src="item.img" altType="table" /></td>
          <td class="left">{{ item.productName }}</td>
        </tr>
        </tbody>
        <tbody v-else><tr><td colspan="4" class="no-data">진열된 상품이 존재하지 않습니다.</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-10">
        <div>
          <button class="btn icon-itemArrow first" @click="sort('first')">맨위로</button>
          <button class="btn icon-itemArrow up" @click="sort('up')">위로</button>
          <button class="btn icon-itemArrow down" @click="sort('down')">아래로</button>
          <button class="btn icon-itemArrow last" @click="sort('last')">맨아래로</button>
          <button class="btn btn-sm btn-outline color-7" @click="readyRemove($msg('confirm.display-except.product'))">진열제외</button>
          <button class="btn btn-sm btn-outline color-7" @click="openPopup('displayProduct')">상품 선택</button>
        </div>
      </div>
    </div>

    <display-product-main-popup v-if="isOpenPopup.displayProduct" :cateCode="cateCode2" :idxs="getIdxs()" @save="displaySave" @close="closePopup" />

  </div>
</template>
<script>
import displayMixin from '@/mixins/displayMixin'
import productMainCtgryPopup from '@/components/common/productMainCtgryPopup'
import displayProductMainPopup from '@/components/common/displayProductMainPopup'
export default {
  name: 'productMainDisplay',
  components: { productMainCtgryPopup, displayProductMainPopup },
  mixins: [displayMixin],
  data() {
    return {
      cateCode1: null,
      ctgry1List: [],
      cateCode2: null,
      ctgry2List: [],
      isOpenCtgryPopup: false,
    }
  },
  watch: {
    cateCode1() {
      this.initCtgry()
    },
    cateCode2() {
      this.getList()
    },
  },
  created() {
    this.initCtgry()
  },
  methods: {
    async initCtgry() {
      await this.setCtgry1List()
      await this.setCtgry2List()
      await this.getList()
    },
    async setCtgry1List() {
      const { result, data } = await this.$api.getProductMainCategory()
      if (result === 'success') {
        this.ctgry1List = data
        this.cateCode1 = this.ctgry1List[0].cateCode
      }
    },
    async setCtgry2List() {
      const { result, data } = await this.$api.getProductMainCategory({ target: this.cateCode1 })
      if (result === 'success') {
        this.ctgry2List = data
        this.cateCode2 = this.ctgry2List[0].cateCode
      }
    },
    async getList() {
      const { result, list } = await this.$api.getMainProductDisplayList({ cateCode: this.cateCode2 })
      if (result === 'success') {
        this.list = list
        this.selectedIdxs = []
      }
    },
    async save() {
      const { result } = await this.$api.setMainProductDisplayList({
        cateCode: this.cateCode2,
        idxs: this.getIdxs()
      });
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'))
        this.getList().then()
      }
     else {
        this.alertPopup(this.$msg('fail'))
      }
    },
    displaySave(list) {
      this.list = list
    },
    openCtgryPopup() {
      this.isOpenCtgryPopup = true;
    },
    closeCtgryPopup() {
      this.setCtgry2List()
      this.isOpenCtgryPopup = false
    },
  }
}
</script>