import axios from 'axios'
import store from '@/store'

export const dashboardResource = "dashboard"
export const companyResource = "company"
export const userResource = "user"
export const membersResource = "members"
export const noticeResource = "notice"
export const boardResource = "board"
export const aboardsResource = "aboards"
export const displayResource = "display"
export const commentResource = "comment"
export const productResource = "product"
export const commonResource = "common"
export const youtubeResource = "youtube"
export const bannerResource = "banner"
export const petsResource = "pets"
export const attachResource = "attach"

export default class api {

    instance = null
    getBaseUrl = null

    constructor() {
        this.getBaseUrl = process.env.NODE_ENV === 'development'
          ? 'https://ggolab-admin-api.programrush.co.kr'
          : 'https://admin-api.ggolab.co.kr'

        this.instance = axios.create({
            baseURL: this.getBaseUrl,
            timeout: 60000,
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/x-www-form-urlencoded charset=UTF-8',
            },
        })
    }

    setInterceptors = (instance) => {
        instance.interceptors.request.use(
            (config) => {
                store.dispatch('common/setLoading', { isLoading: true })
                config.headers.Authorization = `Bearer ${store.getters['session/getAuthToken']}`;
                return config;
            },
            (error) => {
                store.dispatch('common/setLoading', { isLoading: false })
                return Promise.reject(error);
            },
        );
        instance.interceptors.response.use(
            (response) => {
                store.dispatch('common/setLoading', { isLoading: false })
                return response;
            },
            (error) => {
                store.dispatch('common/setLoading', { isLoading: false })
                console.log("=========== ERROR ===========")
                console.log("> error : ", error)
                console.log("> error.response : ", error.response)
                console.log("=========== // ERROR ===========")
                if (error.response && error.response.status === 401) {
                    // console.log('the error response status is:', error.response.status);
                    localStorage.removeItem('auth')
                    localStorage.removeItem('id')
                    localStorage.removeItem('email')
                    localStorage.removeItem('name')
                    localStorage.removeItem('phone')
                    location.href = '/login'
                }
                return Promise.reject(error);
            },
        );
        return instance;
    }

    api = () => this.setInterceptors(this.instance)

    getAPI = (url, payload) => this.api().get(url, { params: payload }).then((res) => res.data)
    postAPI = (url, payload) => this.api().post(url, payload).then((res) => res.data)
    download = (url) => location.href = `${this.getBaseUrl}/${url}`


    /* 로그인 */
    login = (payload) => this.postAPI(`${userResource}/userLogin`, payload)

    /* 대시보드 */
    getDashboard = (payload) => this.getAPI(`${dashboardResource}`, payload)
    getNotificationCount = () => this.getAPI(`${dashboardResource}/noticeCount`)

    /* 운영관리 */
    getCompanyInfo = () => this.getAPI(`${companyResource}/getComInfo`)
    setCompanyInfo = (payload) => this.postAPI(`${companyResource}/setComInfo`, payload)
    getPolicy = (payload) => this.getAPI(`${companyResource}/getComPolicy`, payload)
    setPolicy = (payload) => this.postAPI(`${companyResource}/setComPolicy`, payload)
    getIcon = () => this.getAPI(`${companyResource}/getIcon`)
    setIcon = (payload) => this.postAPI(`${companyResource}/setIcon`, payload)
    getScript = () => this.getAPI(`${companyResource}/getScriptList`)
    setScript = (payload) => this.postAPI(`${companyResource}/setScript`, payload)
    getWordFilter = () => this.getAPI(`${companyResource}/getWordFilterList`)
    setWordFilter = (payload) => this.postAPI(`${companyResource}/setWordFilter`, payload)
    getManagerList = (payload)  => this.getAPI(`${userResource}/getUserList`, payload)
    getManager = (payload) => this.getAPI(`${userResource}/getUserDetail`, payload)
    removeManager = (payload) => this.postAPI(`${userResource}/removeUser`, payload)
    managerChkId = (payload) => this.postAPI(`${userResource}/getIdDuple`, payload)
    managerChkNickname = (payload) => this.postAPI(`${userResource}/getNickDuple`, payload)
    managerChkEmail = (payload) => this.postAPI(`${userResource}/getEmailDuple`, payload)
    saveManager = (payload) => this.postAPI(`${userResource}/setUserRegist`, payload)

    /* 회원 */
    getMemberList = (payload) => this.getAPI(`${membersResource}/getMemberList`, payload)
    getMember = (payload) => this.getAPI(`${membersResource}/getMemberDetail`, payload)
    memberChkEmail = (payload) => this.postAPI(`${membersResource}/checkEmail`, payload)
    memberChkName = (payload) => this.postAPI(`${membersResource}/checkNick`, payload)
    memberChkPhone = (payload) => this.postAPI(`${membersResource}/checkPhone`, payload)
    saveMember = (payload) => this.postAPI(`${membersResource}/setMemberRegist`, payload)
    memberWithdrawal = (payload) => this.postAPI(`${membersResource}/setLeaveMember`, payload)
    getSleepMemberList = (payload) => this.getAPI(`${membersResource}/getSleepList`, payload)
    memberSleepOff = (payload) => this.postAPI(`${membersResource}/setSleepOff`, payload)
    getWithdrawalMemberList = (payload) => this.getAPI(`${membersResource}/getLeaveList`, payload)
    notificationSend = (payload) => this.postAPI(`${noticeResource}/setNoticeSend`, payload)
    getNotificationSendList = (payload) => this.getAPI(`${noticeResource}/getNoticeSendList`, payload)

    /* 게시판 */
    getTipList = (payload) => this.getAPI(`${boardResource}/getTipList`, payload)
    getTip = (payload) => this.getAPI(`${boardResource}/getBoardDetail`, payload)
    tipSave = (payload) => this.postAPI(`${boardResource}/setBoard`, payload)
    tipComplete = (payload) => this.postAPI(`${boardResource}/setStatus`, payload)
    getLabList = (payload) => this.getAPI(`${boardResource}/getLabList`, payload)
    getLab = (payload) => this.getAPI(`${boardResource}/getLabDetail`, payload)
    labSave = (payload) => this.postAPI(`${boardResource}/setLab`, payload)
    getMdList = (payload) => this.getAPI(`${boardResource}/getVideoList`, payload)
    getYoutubeList = (payload) => this.getAPI(`${youtubeResource}/getVideoSearch`, payload)
    mdSave = (payload) => this.postAPI(`${boardResource}/setVideo`, payload)
    boardRemove = (payload) => this.postAPI(`${boardResource}/removeBoard`, payload)
    getGgomunityList = (payload) => this.getAPI(`${boardResource}/getGgomuList`, payload)
    getGgomunity = (payload) => this.getAPI(`${boardResource}/getGgomuDetail`, payload)
    saveGgomunity = (payload) => this.postAPI(`${boardResource}/setGgomu`, payload)
    getEventList = (payload) => this.getAPI(`${aboardsResource}/getEventList`, payload)
    getEvent = (payload) => this.getAPI(`${aboardsResource}/getEventDetail`, payload)
    eventSave = (payload) => this.postAPI(`${aboardsResource}/setEvent`, payload)
    getNoticeList = (payload) => this.getAPI(`${aboardsResource}/getNoticeList`, payload)
    getNotice = (payload) => this.getAPI(`${aboardsResource}/getNoticeDetail`, payload)
    noticeSave = (payload) => this.postAPI(`${aboardsResource}/setNotice`, payload)
    getInquiryList = (payload) => this.getAPI(`${aboardsResource}/getInquiryList`, payload)
    getInquiry = (payload) => this.getAPI(`${aboardsResource}/getInquiryDetail`, payload)
    inquirySave = (payload) => this.postAPI(`${aboardsResource}/setInquiryAnswer`, payload)
    inquiryRemove = (payload) => this.postAPI(`${aboardsResource}/inquiryDelete`, payload)
    getFaqList = (payload) => this.getAPI(`${aboardsResource}/getFaqList`, payload)
    getFaq = (payload) => this.getAPI(`${aboardsResource}/getFaqDetail`, payload)
    faqSave = (payload) => this.postAPI(`${aboardsResource}/setFaq`, payload)
    aboardsRemove = (payload) => this.postAPI(`${aboardsResource}/setDelete`, payload)
    getRemoveList = (payload) => this.getAPI(`${boardResource}/getRemoveList`, payload)
    boardRestore = (payload) => this.postAPI(`${boardResource}/restoreBoard`, payload)
    boardRealRemove = (payload) => this.postAPI(`${boardResource}/deleteBoard`, payload)
    getBoardReportList = (payload) => this.getAPI(`${boardResource}/getBoardReportList`, payload)
    setBlind = (payload) => this.postAPI(`${boardResource}/setBlind`, payload)
    getReportDetail = (payload) => this.getAPI(`${boardResource}/getReportDetail`, payload)

    getMainManage = () => this.getAPI(`${displayResource}/getMainTheme`)
    saveMainManage = (payload) => this.postAPI(`${displayResource}/setMainThemeName`, payload)
    getMainDisplayList = (payload) => this.getAPI(`${displayResource}/getMainThemeList`, payload)
    saveMainDisplay = (payload) => this.postAPI(`${displayResource}/setMainDisplay`, payload)
    getMdManage = (payload) => this.getAPI(`${displayResource}/getMdTheme`, payload)
    saveMdManage = (payload) => this.postAPI(`${displayResource}/setMdThemeName`, payload)
    getMdDisplayList = (payload) => this.getAPI(`${displayResource}/getMdThemeList`, payload)
    saveMdDisplay = (payload) => this.postAPI(`${displayResource}/setMdDisplay`, payload)
    getDisplayBoardList = (payload) => this.getAPI(`${displayResource}/getBoardList`, payload)
    getSelectedDisplayBoardList = (payload) => this.getAPI(`${displayResource}/getSeletBoardList`, payload)
    getAgeContentList = (payload) => this.getAPI(`${displayResource}/getAgeContentList`, payload)
    saveAgeContent = (payload) => this.postAPI(`${displayResource}/setAgeContent`, payload)

    getCommentList = (payload) => this.getAPI(`${commentResource}/getCommentsList`, payload)
    getCommentReportList = (payload) => this.getAPI(`${commentResource}/getReportList`, payload)
    commentRemove = (payload) => this.postAPI(`${commentResource}/removeComments`, payload)
    commentClose = (payload) => this.postAPI(`${commentResource}/setBlockComments`, payload)
    getCommentRemoveList = (payload) => this.getAPI(`${commentResource}/getRemoveCommentsList`, payload)
    commentRestore = (payload) => this.postAPI(`${commentResource}/setRestore`, payload)
    commentRealRemove = (payload) => this.postAPI(`${commentResource}/deleteComments`, payload)
    getActivityList = (payload) => this.getAPI(`${membersResource}/getMemberLogs`, payload)

    /* 상품 */
    getProductList = (payload) => this.getAPI(`${productResource}/getProductList`, payload)
    getProduct = (payload) => this.getAPI(`${productResource}/getProductDetail`, payload)
    saveProduct = (payload) => this.postAPI(`${productResource}/setProduct`, payload)
    removeProduct = (payload) => this.postAPI(`${productResource}/removeProduct`, payload)
    getProductDisplayList = () => this.getAPI(`${productResource}/getProductSortingList`)
    getSelectedProductList = (payload) => this.getAPI(`${productResource}/getSeletList`, payload)
    saveProductDisplay = (payload) => this.postAPI(`${productResource}/setDisplay`, payload)
    getProductMainCategory = (payload) => this.getAPI(`${productResource}/getProductCate`, payload)
    setProductMainCategory = (payload) => this.postAPI(`${productResource}/setProductCate`, payload)
    getMainProductDisplayList = (payload) => this.getAPI(`${productResource}/getMainProductList`, payload)
    setMainProductDisplayList = (payload) => this.postAPI(`${productResource}/setMainProductList`, payload)
    getSelectedMainProductList = (payload) => this.getAPI(`${productResource}/getSelectProducts`, payload)

    /* 디자인 */
    getBannerList = (payload) => this.getAPI(`${bannerResource}/getBannerList`, payload)
    getBanner = (payload) => this.getAPI(`${bannerResource}/getBannerDetail`, payload)
    saveBanner = (payload) => this.postAPI(`${bannerResource}/setBanner`, payload)
    removeBanner = (payload) => this.postAPI(`${bannerResource}/delBanner`, payload)
    setSortBanner = (payload) => this.postAPI(`${bannerResource}/sortBanner`, payload)

    /* 반려동물 */
    getPetsList = (payload) => this.getAPI(`${petsResource}/getPetsList`, payload)
    getPets = (payload) => this.getAPI(`${petsResource}/getPetsDetail`, payload)
    savePets = (payload) => this.postAPI(`${petsResource}/setPets`, payload)
    getPetMembers = (payload) => this.getAPI(`${petsResource}/getMembers`, payload)
    removePets = (payload) => this.postAPI(`${petsResource}/removePets`, payload)
    getBreedList = (payload) => this.getAPI(`${petsResource}/getBreedList`, payload)
    getBreed = (payload) => this.getAPI(`${petsResource}/getBreedDetail`, payload)
    saveBreed = (payload) => this.postAPI(`${petsResource}/setBreed`, payload)
    getVacList = (payload) => this.getAPI(`${petsResource}/getVacList`, payload)
    getVac = (payload) => this.getAPI(`${petsResource}/getVacDetail`, payload)
    saveVac = (payload) => this.postAPI(`${petsResource}/setVac`, payload)
    getBreedByPetCode = (payload) => this.getAPI(`${petsResource}/getBreed`, payload)
    getAgeGroup = (payload) => this.getAPI(`${petsResource}/getAgeGroup`, payload)

    /* 파일 다운로드 */
    downloadGgomunityFiles = (payload) => this.download(`${attachResource}/downloadAll?idx=${payload.idx}`)

    /* 공통 */
    getCtgryList = (payload) => this.getAPI(`${commonResource}/getCategoryList`, payload)
    ctgrySave = (payload) => this.postAPI(`${commonResource}/setCategory`, payload)
    uploadEditorImage = (payload) => this.postAPI(`${commonResource}/setEditerImg`, payload)
    removeEditorImage = (payload) => this.postAPI(`${commonResource}/removeEditerImg`, payload)
    getYoutubeCategory = () => this.getAPI(`${commonResource}/getYoutubeCate`)
    getProductTag = (payload) => this.getAPI(`${commonResource}/getPdTag`, payload)
    setProductTag = (payload) => this.postAPI(`${commonResource}/setPdTag`, payload)
    removeProductTag = (payload) => this.postAPI(`${commonResource}/removePdTag`, payload)
    getCodeList = (payload) => this.getAPI(`${commonResource}/getCodeList`, payload)
    saveCode = (payload) => this.postAPI(`${commonResource}/setCode`, payload)
    checkCode = (payload) => this.postAPI(`${commonResource}/checkCode`, payload)
    removeCode = (payload) => this.postAPI(`${commonResource}/removeCode`, payload)
    getCodeAll = (payload) => this.postAPI(`${commonResource}/getAllCode`, payload)
    removeAttachFile = (payload) => this.postAPI(`${commonResource}/setAttachFileRemove`, payload)
}