<template>
  <div>
    <div class="navi-bar"> 
      <span>회원</span>
      <span>회원 관리</span>
      <span>회원 리스트</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">회원 리스트</div>
      <div class=""><button @click="goSave()" class="btn color-1">등록</button></div>
    </div>

    <div class="layout-contents-box">
      <table class="table-cols">
        <colgroup>
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th>회원가입일</th>
          <td><date ref="date"/></td>
        </tr>
        <tr>
          <th>가입경로</th>
          <td>
            <label><input type="radio" name="device" value="" v-model="device">전체</label>
            <label><input type="radio" name="device" value="p" v-model="device">PC</label>
            <label><input type="radio" name="device" value="m" v-model="device">M</label>
          </td>
        </tr>
        <tr>
          <th>연결계정</th>
          <td>
            <label><input type="radio" name="sns" value="" v-model="sns">전체</label>
            <label><input type="radio" name="sns" value="naver" v-model="sns">네이버</label>
            <label><input type="radio" name="sns" value="facebook" v-model="sns">페이스북</label>
            <label><input type="radio" name="sns" value="kakao" v-model="sns">카카오톡</label>
          </td>
        </tr>
        <tr>
          <th>반려동물 등록여부</th>
          <td>
            <label><input type="radio" name="pets" value="" v-model="pets">전체</label>
            <label><input type="radio" name="pets" value="1" v-model="pets">등록완료</label>
            <label><input type="radio" name="pets" value="0" v-model="pets">미등록</label>
          </td>
        </tr>
        <tr>
          <th>마케팅 수신여부</th>
          <td>
            <label><input type="radio" name="marketingYN" value="" v-model="marketingYN">전체</label>
            <label><input type="radio" name="marketingYN" value="1" v-model="marketingYN">수신</label>
            <label><input type="radio" name="marketingYN" value="0" v-model="marketingYN">수신거부</label>
          </td>
        </tr>
        <tr>
          <th>검색어</th>
          <td>
            <div class="d-flex">
              <div class="w-120 mr-10">
                <select v-model="target">
                  <option v-for="item in targets" :key="`target-${item.value}`" :value="item.value">{{ item.name }}</option>
                </select>
              </div>
              <div class="w-300">
                <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      
      <div class="btn-box center my-20">
        <div>
          <button class="btn color-7" @click="search">검색</button>
        </div>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>

      <table class="table-rows">
        <colgroup>
            <col width="50">
            <col>
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>아이디(이메일)</th>
          <th>닉네임</th>
          <th>휴대폰번호</th>
          <th>마케팅 수신여부</th>
          <th>가입경로</th>
          <th>연결계정</th>
          <th>반려동물 등록여부</th>
          <th>회원가입일</th>
          <th>최종로그인</th>
          <th>휴면해제일</th>
        </tr>
        </thead>
        <tbody v-if="list && list.length > 0">
          <tr v-for="item in list" :key="`list-${item.idx}`" @click="goSave(item.idx)" class="cursor-pointer">
            <td @click.stop><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
            <td>{{ item.email }}</td>
            <td>{{ item.nickName }}</td>
            <td>{{ item.phone.phoneNumber() }}</td>
            <td>{{ item.marketingYN == 1 ? '수신' : '수신거부' }}</td>
            <td>{{ item.device }}</td>
            <td>{{ item.sns ? item.sns : '-' }}</td>
            <td>{{ item.pets }}</td>
            <td>{{ $getDateFormat(item.joinDate, 'Y-m-d') }}</td>
            <td>{{ $getDateFormat(item.lastLoginDate, 'Y-m-d') }}</td>
            <td>{{ $getDateFormat(item.nonSleepDate, 'Y-m-d') }}</td>
          </tr>
        </tbody>
        <tbody v-else><tr><td colspan="11">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box my-20">
        <div>
            <button class="btn btn-outline color-7" @click="withdrawalReady">선택 탈퇴처리</button>
        </div>  
      </div>
      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex"
import listMixin from '@/mixins/listMixin'
export default {
  name: 'memberList',
  mixins: [listMixin],
  data() {
    return {
      target: 1,
      targets: [
        { name: '아이디(이메일)', value: 1 },
        { name: '닉네임', value: 2 },
        { name: '휴대폰', value: 3 },
      ],
      text: null,
      device: '',
      sns: '',
      pets: '',
      marketingYN: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getList() {
      const { result, list, total } = await this.$api.getMemberList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        device: this.device,
        sns: this.sns,
        pets: this.pets,
        marketing: this.marketingYN ? Number(this.marketingYN) : null,
        target: this.target,
        text: this.text,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
        this.selectedIdxs = []
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    withdrawalReady() {
      if (this.selectedIdxs.length > 0) {
        this.confirmWithdrawalPopup()
      }
      else {
        this.alertPopup(this.$msg('no-data-selected'))
      }
    },
    async withdrawal() {
      if (!this.selectedIdxs) return false
      const { result } = await this.$api.memberWithdrawal({ idxs: this.selectedIdxs });
      if (result === 'success') {
        // this.list = this.list.filter(l => !this.selectedIdxs.includes(l.id))
        this.setMsgPopup()
        this.getList().then()
        this.alertPopup(this.$msg('ok.withdrawal'))
        this.allChecked = false
      }
    },
    goSave(idx=0) {
      if (idx) this.$router.push(`/member/save?idx=${idx}&page=${this.page}`)
      else     this.$router.push(`/member/save?page=${this.page}`)
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmWithdrawalPopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.withdrawal'),
        okay: 'WITHDRAWAL',
        okayCallback: () => {
          this.withdrawal()
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>
