<template>
  <div class="dashboard-page">
    <div class="page-title-box">
      <div class="page-title">
        대시보드 
        <div class="help-box" >
          <span class="help-icon" :class="{'on': isHelp.dashboard}" @click="toggleHelp('dashboard')">?</span>
          <div class="help-popup" v-if="isHelp.dashboard">
            <div class="close" @click="toggleHelp('dashboard')"></div>
            <ul class="dot-list">
              <li>조회 기간에 대한 합산 데이터를 보여줍니다.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex align-center">
        <div><date ref="date"/></div>
        <div class="ml-10"><button class="btn btn-sm color-1" @click="getInfo">검색</button></div>
      </div>
    </div>

    <div class="dashboard-box col-3">
      <div class="item-box">
        <div class="box-title">회원현황</div>
        <div class="count-box">
          <dl>
            <dt>신규회원</dt>
            <dd>{{ info.members.new }}</dd>
          </dl>
          <dl>
            <dt>탈퇴회원</dt>
            <dd>{{ info.members.leave }}</dd>
          </dl>
        </div>
      </div>

      <div class="item-box">
        <div class="box-title">가입경로</div>
        <div class="count-box">
          <dl>
            <dt>PC</dt>
            <dd>{{ info.device.p }}</dd>
          </dl>
          <dl>
            <dt>M</dt>
            <dd>{{ info.device.m }}</dd>
          </dl>
        </div>
      </div>
      
      <div class="item-box">
        <div class="box-title">가입유형</div>
        <div class="count-box">
          <dl>
            <dt>이메일</dt>
            <dd>{{ info.joinType.email }}</dd>
          </dl>
          <dl>
            <dt>소셜</dt>
            <dd>{{ info.joinType.sns }}</dd>
          </dl>
        </div>
      </div>
      
      <div class="item-box full-box">
        <div class="box-title">
          활동현황
          <div class="help-box">
            <span class="help-icon" :class="{'on': isHelp.active}" @click="toggleHelp('active')">?</span>
            <div class="help-popup" v-if="isHelp.active">
            <div class="close" @click="toggleHelp('active')"></div>
              <ul class="dot-list">
                <li>조회 기간동안 활동내역 별 합산 데이터를 보여줍니다.</li>
                <li>꼬리TIP(심사중, 심사완료 상태), 연구일지 게시판에 대한 활동현황 데이터만 출력됩니다.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="count-box">
          <dl>
            <dt>게시글</dt>
            <dd>{{ info.activity.board }}</dd>
          </dl>
          <dl>
            <dt>댓글</dt>
            <dd>{{ info.activity.comment }}</dd>
          </dl>
          <dl>
            <dt>답글</dt>
            <dd>{{ info.activity.reply }}</dd>
          </dl>
          <dl>
            <dt>추천</dt>
            <dd>{{ info.activity.like }}</dd>
          </dl>
          <dl>
            <dt>신고</dt>
            <dd>{{ info.activity.report }}</dd>
          </dl>
        </div>
      </div>
    </div>

    <div class="board-wrap">
      <div>
        <div class="board-title">
          <div>
            <strong>1:1 문의</strong>
            <em>답변대기 : {{ info.inquiry.count }}건</em>
          </div>
          <router-link to="/board/inquiry/list">자세히 보기</router-link>
        </div>
        <div class="baord-box">
          <ul v-if="info.inquiry.list">
            <li v-for="(item, index) in info.inquiry.list" :key="index" @click="goPage(`/board/inquiry/save?idx=${item.idx}`)">
              <span class="chip chip-outline color-6">답변대기</span>
              <span class="title notice-title"><strong class="color-1">Q. </strong>{{item.title}}</span>
              <span class="date">{{ item.updateDate ? $getDateFormat(item.updateDate, 'Y-m-d') : $getDateFormat(item.createDate, 'Y-m-d') }}</span>
            </li>
          </ul>
          <div v-else>{{ $msg('nodata.dashboard.inquiry') }}</div>
        </div>
      </div>

      <div>
        <div class="board-title">
          <strong>공지사항</strong>
          <router-link to="/board/notice/list">자세히 보기</router-link>
        </div>
        <div class="baord-box">
          <ul v-if="info.notice">
            <li v-for="(item, index) in info.notice" :key="index" @click="goPage(`/board/notice/save?idx=${item.idx}`)">
              <span v-if="item.pinYN == 1" class="chip chip-outline color-1">공지사항</span>
              <span class="title notice-title">{{item.title}}</span>
              <span class="date">{{ item.updateDate ? $getDateFormat(item.updateDate, 'Y-m-d') : $getDateFormat(item.createDate, 'Y-m-d') }}</span>
            </li>
          </ul>
          <div v-else>{{ $msg('nodata.inserted') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'

export default {
  name:'dashboard',
  mixins: [listMixin],
  data(){
    return{
      data: null,
      isHelp: {
        dashboard:false,
        active:false,
      },
      info: {
        members: {
          new: 0,
          leave: 0,
        },
        device: {
          p: 0,
          m: 0,
        },
        joinType: {
          email: 0,
          sns: 0,
        },
        activity: {
          board: 0,
          comment: 0,
          reply: 0,
          like: 0,
          share: 0,
          report: 0,
        },
        inquiry: {
          count: 0,
          list: null,
        },
        notice: null,
      }
    }
  }, 
  created() {
    this.getInfo()
  },
  methods:{
    toggleHelp(target){
      this.isHelp[target] = !this.isHelp[target];
    },
    async getInfo() {
      const res = await this.$api.getDashboard({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
      })
      this.info = res
    },
    goPage(uri) {
      this.$router.push(uri)
    }
  }
}
</script>