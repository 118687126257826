<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 진열 관리</span>
      <span>MD’S PICK 진열 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">MD’S PICK 진열 관리</div>
      <div>
        <button @click="save" class="btn color-1">저장</button>
      </div>
    </div>


    <div class="layout-contents-box">
      <div class="contents-box-title">분류 선택</div>
      <table class="table-cols">
        <colgroup>
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th class="require">분류</th>
          <td><select-box :selectBoxClass="'w-500'" :validate="rules.md" :options="opt.md" v-model="md" @change="getList"/></td>
        </tr>
        </tbody>
      </table>

      <div class="contents-box-title">진열 게시물 설정</div>

      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col width="100">
          <col width="150">
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>진열순서</th>
          <th>이미지</th>
          <th>제목</th>
        </tr>
        </thead>
        <tbody v-if="this.list && this.list.length > 0">
        <tr v-for="(item, index) in this.list" :key="`list-${index}`">
          <td><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs"></td>
          <td>{{ item.sorting ? item.sorting : '-' }}</td>
          <td><image-alt v-if="item.src" :src="item.src" altType="table" /></td>
          <td class="left">
            <p class="fw-bold">{{ item.title }}</p>
            <p class="color-7">{{ item.channelName }}</p>
          </td>
        </tr>
        </tbody>
        <tbody v-else-if="!md"><tr><td colspan="4">{{ $msg('type.check') }}</td></tr></tbody>
        <tbody v-else><tr><td colspan="4">{{ $msg('nodata.select') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-10">
        <div>
          <button class="btn icon-itemArrow first" @click="sort('first')">맨위로</button>
          <button class="btn icon-itemArrow up" @click="sort('up')">위로</button>
          <button class="btn icon-itemArrow down" @click="sort('down')">아래로</button>
          <button class="btn icon-itemArrow last" @click="sort('last')">맨아래로</button>
          <button class="btn btn-sm btn-outline color-7" @click="readyRemove($msg('confirm.display-except.posts'))">진열제외</button>
          <button class="btn btn-sm btn-outline color-7" @click="openPopup('displayBoard')">게시물 선택</button>
        </div>
      </div>
    </div>

    <display-board-popup v-if="isOpenPopup.displayBoard" :board="'md'" :boIdxs="getIdxs()" :mdIdx="md" @save="displaySave" @close="closePopup" />
  </div>
</template>
<script>
import displayMixin from '@/mixins/displayMixin'
export default {
  name: 'mdDisplay',
  mixins: [displayMixin],
  data() {
    return {
      opt: {
        md: [],
      },
      md: null,
      rules: {
        md: [v => !!v || this.$msg('required.select')],
      },
      board: null,
    }
  },
  created() {
    this.getMdManage()
  },
  methods: {
    async getMdManage() {
      const { result, data } = await this.$api.getMdManage()
      if (result === 'success') {
        let md = []
        Object.keys(data).map(function (key) {
          const value = data[key].name
          if (value) md.push({ value: key, text: value },)
        });
        md.unshift({ value: null, text: '선택' })
        this.opt.md = md
      }
    },
    async getList() {
      const { result, list } = await this.$api.getMdDisplayList({ idx: this.md })
      if (result === 'success') {
        this.list = list
        this.selectedIdxs = []
        this.board = 'md'
      }
    },
    async save() {
      if (!this.md || !this.list) return false
      const { result } = await this.$api.saveMdDisplay({
        idx: this.md,
        idxs: this.getIdxs()
      })
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'))
        this.getList().then()
      }
      else {
        this.alertPopup(this.$msg('fail'))
      }
    },
    displaySave(list) {
      this.list = list
    },
  }
}
</script>