<template>
  <div>
    <div class="navi-bar"> 
        <span>운영관리</span>
        <span>기본정책</span>
        <span>스크립트 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">스크립트 관리</div>
      <div class=""><button @click="save" class="btn color-1">저장</button></div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">공통스크립트(…&lt;/head&gt;)</div>
        <div class="contents-box-sub-title">* 스크립트 삽입 시 모든 페이지 &lt;/head&gt;위에 삽입됩니다.</div>
        <div class="source-box">
          <div v-if="head.input.length === 0" class="script1">
            &lt;script <span class="html-attribute-name">src</span>="<input-field class="w-600 mr-5 d-inline-block" :value="''" @input="setInput($event, 'head', 0)"/>"&gt; &lt;/script&gt;
            <button @click="update('head', 'plus', 0)" class="btn btn-x-sm btn-outline color-1">+ 추가</button>
          </div>
          <div v-else v-for="(input, index) in head.input" :key="`head-${index}`" class="script1">
            &lt;script <span class="html-attribute-name">src</span>="<input-field class="w-600 mr-5 d-inline-block" :value="input" @input="setInput($event, 'head', index)"/>"&gt; &lt;/script&gt;
            <button v-if="index === 0" @click="update('head', 'plus', index)" class="btn btn-x-sm btn-outline color-1">+ 추가</button>
            <button v-else @click="update('head', 'minus', index)" class="btn btn-x-sm btn-outline color-1">- 삭제</button>
          </div>
          <div>
            &lt;script&gt;
            <input-field multiline rows="10" class="block" v-model="head.script"/>
            &lt;/script&gt;
          </div>
        </div>

        <div class="contents-box-title">공통스크립트(…&lt;/body&gt;)</div>
        <div class="contents-box-sub-title">* 스크립트 삽입 시 모든 페이지 &lt;/body&gt;위에 삽입됩니다.</div>
        <div class="source-box">
          <div v-if="body.input.length === 0" class="script1">
            &lt;script <span class="html-attribute-name">src</span>="<input-field class="w-600 mr-5 d-inline-block" :value="''" @input="setInput($event, 'body', 0)"/>"&gt; &lt;/script&gt;
            <button @click="update('body', 'plus', 0)" class="btn btn-x-sm btn-outline color-1">+ 추가</button>
          </div>
          <div v-else v-for="(input, index) in body.input" :key="`body-${index}`" class="script1">
            &lt;script <span class="html-attribute-name">src</span>="<input-field class="w-600 mr-5 d-inline-block" :value="input" @input="setInput($event, 'body', index)"/>"&gt; &lt;/script&gt;
            <button v-if="index === 0" @click="update('body', 'plus', index)" class="btn btn-x-sm btn-outline color-1">+ 추가</button>
            <button v-else @click="update('body', 'minus', index)" class="btn btn-x-sm btn-outline color-1">- 삭제</button>
          </div>
          <div>
            &lt;script&gt;
            <input-field multiline rows="10" class="block" v-model="body.script"/>
            &lt;/script&gt;
          </div>
        </div>

        <div class="contents-box-title">전환스크립트(회원가입)</div>
        <div class="contents-box-sub-title">* 회원가입 완료 시 삽입한 스크립트가 호출되며 {tel}, {id} 치환코드 삽입 시 사용자 정보로 치환 되어 호출됩니다.</div>
        <div class="source-box">
          <div v-if="join.input.length === 0" class="script1">
            &lt;script <span class="html-attribute-name">src</span>="<input-field class="w-600 mr-5 d-inline-block" :value="''" @input="setInput($event, 'join', 0)"/>"&gt; &lt;/script&gt;
            <button @click="update('join', 'plus', 0)" class="btn btn-x-sm btn-outline color-1">+ 추가</button>
          </div>
          <div v-else v-for="(input, index) in join.input" :key="`join-${index}`" class="script1">
            &lt;script <span class="html-attribute-name">src</span>="<input-field class="w-600 mr-5 d-inline-block" :value="input" @input="setInput($event, 'join', index)"/>"&gt; &lt;/script&gt;
            <button v-if="index === 0" @click="update('join', 'plus', index)" class="btn btn-x-sm btn-outline color-1">+ 추가</button>
            <button v-else @click="update('join', 'minus', index)" class="btn btn-x-sm btn-outline color-1">- 삭제</button>
          </div>
          <div>
            &lt;script&gt;
            <input-field multiline rows="10" class="block" v-model="join.script"/>
            &lt;/script&gt;
          </div>
        </div>
      </div>
    </validateForm>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data(){
    return{
      head: {
        input: [],
        script: '',
      },
      body: {
        input: [],
        script: '',
      },
      join: {
        input: [],
        script: '',
      },
    }
  },
  created() {
    this.setItem()
  },
  methods:{
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem(){
      const { result, head, body, join } = await this.$api.getScript()
      if (result === 'success') {
        if (head) this.head = head
        if (body) this.body = body
        if (join) this.join = join
      }
    },
    setInput(event, target, index) {
      this[target].input[index] = event
    },
    update(target, type, index) {
      if (type === 'plus') {
        this[target].input.push('')
      }
      else if (type === 'minus') {
        let input = this[target].input
        input.splice(index, 1)
        this[target].input = input
      }
    },
    async save() {
      const { result } = await this.$api.setScript({
        head: this.head,
        body: this.body,
        join: this.join,
      })
      this.alertPopup(result === 'success' ? this.$msg('ok.save') : this.$msg('fail'))
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}
</script>