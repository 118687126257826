<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 진열 관리</span>
      <span>MD'S PICK 분류 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">MD'S PICK 분류 관리</div>
    </div>

    <div class="layout-contents-box">
      <table class="table-rows">
        <colgroup>
          <col width="100">
          <col>
          <col>
          <col width="100">
        </colgroup>
        <thead>
        <tr>
          <th>번호</th>
          <th>MD'S PICK 분류명</th>
          <th>MD'S PICK 서브문구</th>
          <th>관리</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td class="left"><input-field v-model="name1" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(1)"/></td>
          <td class="left"><input-field v-model="subName1" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(1)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(1)">수정</button></td>
        </tr>
        <tr>
          <td>2</td>
          <td class="left"><input-field v-model="name2" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(2)"/></td>
          <td class="left"><input-field v-model="subName2" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(2)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(2)">수정</button></td>
        </tr>
        <tr>
          <td>3</td>
          <td class="left"><input-field v-model="name3" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(3)"/></td>
          <td class="left"><input-field v-model="subName3" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(3)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(3)">수정</button></td>
        </tr>
        <tr>
          <td>4</td>
          <td class="left"><input-field v-model="name4" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(4)"/></td>
          <td class="left"><input-field v-model="subName4" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(4)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(4)">수정</button></td>
        </tr>
        <tr>
          <td>5</td>
          <td class="left"><input-field v-model="name5" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(5)"/></td>
          <td class="left"><input-field v-model="subName5" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(5)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(5)">수정</button></td>
        </tr>
        <tr>
          <td>6</td>
          <td class="left"><input-field v-model="name6" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(6)"/></td>
          <td class="left"><input-field v-model="subName6" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(6)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(6)">수정</button></td>
        </tr>
        <tr>
          <td>7</td>
          <td class="left"><input-field v-model="name7" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(7)"/></td>
          <td class="left"><input-field v-model="subName7" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(7)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(7)">수정</button></td>
        </tr>
        <tr>
          <td>8</td>
          <td class="left"><input-field v-model="name8" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(8)"/></td>
          <td class="left"><input-field v-model="subName8" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(8)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(8)">수정</button></td>
        </tr>
        <tr>
          <td>9</td>
          <td class="left"><input-field v-model="name9" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(9)"/></td>
          <td class="left"><input-field v-model="subName9" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(9)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(9)">수정</button></td>
        </tr>
        <tr>
          <td>10</td>
          <td class="left"><input-field v-model="name10" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요." @keypress.enter="save(10)"/></td>
          <td class="left"><input-field v-model="subName10" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요." @keypress.enter="save(10)"/></td>
          <td><button class="btn btn-x-sm color-6" @click="save(10)">수정</button></td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="information-box">
      <div class="information-box-title">안내</div>
      <div class="information-contents">
        <ul class="dot-list">
          <li>최대 10개 까지만 등록 가능합니다.</li>
          <li>사용자 화면에서는 1번 부터 순차적으로 노출 되며 진열 설정된 게시물이 없는 분류의 경우 노출되지 않습니다.</li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script>
import {mapActions} from "vuex"
export default {
  name: 'mdManage',
  data() {
    return {
      list: null,
      name1: null,
      subName1: null,
      name2: null,
      subName2: null,
      name3: null,
      subName3: null,
      name4: null,
      subName4: null,
      name5: null,
      subName5: null,
      name6: null,
      subName6: null,
      name7: null,
      subName7: null,
      name8: null,
      subName8: null,
      name9: null,
      subName9: null,
      name10: null,
      subName10: null,
    }
  },
  created() {
    this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getMdManage()
      if (result === 'success') {
        this.name1 = data.idx1.name
        this.subName1 = data.idx1.subName
        this.name2 = data.idx2.name
        this.subName2 = data.idx2.subName
        this.name3 = data.idx3.name
        this.subName3 = data.idx3.subName
        this.name4 = data.idx4.name
        this.subName4 = data.idx4.subName
        this.name5 = data.idx5.name
        this.subName5 = data.idx5.subName
        this.name6 = data.idx6.name
        this.subName6 = data.idx6.subName
        this.name7 = data.idx7.name
        this.subName7 = data.idx7.subName
        this.name8 = data.idx8.name
        this.subName8 = data.idx8.subName
        this.name9 = data.idx9.name
        this.subName9 = data.idx9.subName
        this.name10 = data.idx10.name
        this.subName10 = data.idx10.subName
      }
    },
    async save(idx) {
      const { result } = await this.$api.saveMdManage({
        idx: idx,
        name: this[`name${idx}`],
        subName: this[`subName${idx}`],
      })
      this.alertPopup(result === 'success' ? this.$msg('ok.save') : this.$msg('fail'))
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}
</script>