<template>
  <div>
    <div class="navi-bar">
      <span>상품</span>
      <span>상품 관리</span>
      <span>상품 리스트</span>
      <span>{{ this.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">상품 리스트</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="setSaveParams" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">상품 {{ this.idx ? '수정' : '등록'}}</div>
        <table class="table-cols" ref="table">
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">상품명</th>
            <td><input-field v-model="productName" class="table-input2" :errorMessage="productNameMsg" placeholder="상품명 입력" maxLength="100"></input-field></td>
          </tr>
          <tr>
            <th class="require">판매가</th>
            <td><input-field v-model="price" class="table-input2" :errorMessage="priceMsg" placeholder="판매가 입력" maxLength="10" numberOnly></input-field></td>
          </tr>
          <tr>
            <th class="require">URL</th>
            <td><input-field v-model="url" :errorMessage="urlMsg" placeholder="https:// 포함 입력" maxLength="500"></input-field></td>
          </tr>
          <tr>
            <th class="require">이미지</th>
            <td colspan="3"><upload-image ref="repImage" @upload="setRepImg" :imgSrc="repImgSrc" :imgName="repImgName" isProduct="true"/></td>
          </tr>
          </tbody>
        </table>
      </div>
    </validateForm>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'productSave',
  data() {
    return {
      repImgSrc: null,
      repImgName: null,
      repImgData: null,
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      productName: null,
      price: null,
      url: null,
      productNameMsg: '',
      priceMsg: '',
      urlMsg: '',
    }
  },
  watch: {
    productName() {
      this.productNameMsg = ''
    },
    price() {
      this.priceMsg = ''
    },
    url() {
      this.urlMsg = ''
    },
  },
  mounted() {
    if (this.idx) this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getProduct({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.productName = data.productName
        this.price = data.price
        this.url = data.url
        this.repImgSrc = data.repImgSrc
        this.repImgName = data.repImgName
      }
    },
    setRepImg(data, name) {
      this.repImgName = name
      this.repImgData = data
    },
    validate() {
      if (!this.productName) {
        this.productNameMsg = this.$msg('required.input')
        return false
      }
      if (!this.price) {
        this.priceMsg = this.$msg('required.input')
        return false
      }
      if (!this.url) {
        this.urlMsg = this.$msg('required.input')
        return false
      }
      if (!this.url.patternCheck('url')) {
        this.urlMsg = this.$msg('url.check')
        return false
      }
      if (!this.repImgName) {
        this.$refs.repImage.msg = this.$msg('required.select')
        return false
      }
      return true
    },
    setSaveParams() {
      if (this.validate()) {
        const params = {
          idx: this.idx,
          name: this.productName,
          price: this.price,
          url: this.url,
          repImgData: this.repImgData,
          repImgName: this.repImgName,
        }
        this.save(params)
      }
    },
    async save(params) {
      const { result } = await this.$api.saveProduct(params)
      this.alertPopup(result === 'success' ? this.$msg('ok.save') : this.$msg('fail'))
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          this.goList()
        },
      })
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/product/list${query}`)
    },
  }
}
</script>