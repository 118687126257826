<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>랜선집사</span>
      <span>등록</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">랜선집사</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="readySave" class="btn color-1">등록</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <table class="table-cols">
        <colgroup>
          <col width="200">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th>업로드 날짜</th>
          <td><date ref="date" isYoutube/></td>
        </tr>
        <tr>
          <th>검색 키워드</th>
          <td>
            <div class="d-flex">
              <div class="w-100 mr-10">
                <select v-model="type">
                  <option v-for="item in opt.type" :key="`target-${item.value}`" :value="item.value">{{ item.text }}</option>
                </select>
              </div>
              
              <div class="w-300">
                <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="btn-box center my-20">
        <div>
          <button class="btn color-7" @click="search">검색</button>
        </div>
      </div>
    </div>

    <div class="layout-contents-box">

      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col width="150">
          <col width="150">
          <col>
          <col width="100">
          <col width="130">
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll(true)"></th>
          <th>고유번호</th>
          <th>이미지</th>
          <th>제목</th>
          <th>조회수</th>
          <th>업로드날짜</th>
        </tr>
        </thead>
        <tbody v-if="this.list && this.list.length > 0">
        <tr v-for="item in this.list" :key="`list-${item.ytIdx}`" class="cursor-pointer">
          <td @click.stop><input type="checkbox" class="check-list" :value="item.ytIdx" v-model="selectedIdxs" ></td>
          <td>{{ item.ytIdx }}</td>
          <td><image-alt v-if="item.src" :src="item.src" altType="table" /></td>
          <td class="left">
            <p class="fw-bold">{{ item.title }}</p>
            <p class="color-7">{{ item.chanName }}</p>
          </td>
          <td>{{ item.viewCount.numberFormat() }}</td>
          <td>{{ $getDateFormat(item.uploadDate, 'Y-m-d') }}</td>
        </tr>
        </tbody>
        <tbody v-else><tr><td colspan="6" class="no-data">"검색" 클릭 시 등록 가능한 게시물이 출력됩니다.</td></tr></tbody>
      </table>
    </div>

    <mds-pick-popup v-if="isOpenMdsPopup" @save="save" @close="closeMdsPopup" />
  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'
export default {
  mixins: [listMixin],
  data() {
    return {
      list: null,
      date: '',
      opt: {
        type: [
          { text: '제목', value: 'title' },
          { text: '고유번호', value: 'yIdx' },
        ],
      },
      type: 'title',
      text: null,
      isOpenMdsPopup: false,
    }
  },
  created() {
  },
  methods: {
    async search() {
      const { result, data } = await this.$api.getYoutubeList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        type: this.type,
        text: this.text,
      })
      if (result === 'success') {
        this.list = data
        this.selectedIdxs = []
      }
    },
    async save(idx) {
      if (!this.selectedIdxs || this.selectedIdxs.length == 0) return false
      const { result } = await this.$api.mdSave({
        idxs: this.selectedIdxs,
        idx: idx,
      });
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'))
        this.goList()
      }
      else {
        this.alertPopup(this.$msg('fail'))
      }
    },
    goList() {
      this.$router.push(`/board/md/list`)
    },
    readySave() {
      if (this.selectedIdxs.length > 0) {
        this.openMdsPopup()
      }
      else {
        this.alertPopup(this.$msg('no-data-selected'))
      }
    },
    openMdsPopup() {
      this.isOpenMdsPopup = true
    },
    closeMdsPopup() {
      this.isOpenMdsPopup = false
    },
  }
}
</script>