<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>삭제 게시물 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">삭제 게시물 관리</div>
    </div>


    <div class="layout-contents-box"> 

      <table class="table-cols">
        <colgroup>
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th>삭제일</th>
          <td><date ref="date"/></td>
        </tr>
        <tr>
          <th>게시판</th>
          <td>
            <label><input type="radio" name="cateCode" value="" v-model="cateCode">전체</label>
            <label><input type="radio" name="cateCode" value="10" v-model="cateCode">꼬리TIP</label>
            <label><input type="radio" name="cateCode" value="20" v-model="cateCode">연구일지</label>
            <label><input type="radio" name="cateCode" value="30" v-model="cateCode">랜선집사</label>
            <label><input type="radio" name="cateCode" value="80" v-model="cateCode">꼬뮤니티</label>
          </td>
        </tr>
        <tr>
          <th>검색어</th>
          <td>
            <div class="d-flex">
              <div class="w-100 mr-10">
                <select v-model="target">
                  <option v-for="item in targets" :key="`target-${item.value}`" :value="item.value">{{ item.name }}</option>
                </select>
              </div>
              <div class="w-300">
                <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="btn-box center my-20">
        <div>
          <button class="btn color-7" @click="search">검색</button>
        </div>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>


      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col width="150">
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>게시판</th>
          <th>이미지</th>
          <th>제목</th>
          <th>아이디/닉네임</th>
          <th>조회수</th>
          <th>추천</th>
          <th>공유</th>
          <th>댓글</th>
          <th>등록일/삭제일</th>
        </tr>
        </thead>
        <tbody v-if="this.list && this.list.length > 0">
        <tr v-for="(item, index) in this.list" :key="`list-${index}`">
          <td><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
          <td>{{ item.categoryName }}</td>
          <td><image-alt v-if="item.src" :src="item.src" altType="table" /></td>
          <td class="left">{{ item.title }}</td>
          <td class="left">
            <p>{{ item.email }}</p>
            <p>{{ item.nickName }}</p>
          </td>
          <td>{{ item.view.numberFormat() }}</td>
          <td>{{ item.likeCnt.numberFormat() }}</td>
          <td>{{ item.share.numberFormat() }}</td>
          <td>{{ item.commentCount.numberFormat() }}</td>
          <td>
            <p>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</p>
            <p>{{ $getDateFormat(item.delDate, 'Y-m-d') }}</p>
          </td>
        </tr>
        </tbody>
        <tbody v-else><tr><td colspan="10">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-20">
        <div>
          <button class="btn btn-outline color-7" @click="ready('restore')">게시물 복구</button>&nbsp;
          <button class="btn btn-outline color-7" @click="ready('remove')">영구 삭제</button>
        </div>
      </div>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>

    <div class="information-box">
      <div class="information-box-title">안내</div>
      <div class="information-contents">
        <ul class="dot-list">
          <li>꼬리TIP, 연구일지, 랜선집사 게시물에 대한 삭제 내역을 확인할 수 있는 화면 입니다.</li>
          <li>게시물 복구 클릭 시 삭제된 게시물을 복구 할 수 있습니다.</li>
          <li>영구 삭제된 게시물은 복구가 불가능합니다.</li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script>
import {mapActions} from "vuex"
import listMixin from '@/mixins/listMixin'
export default {
  name: 'removeList',
  mixins: [listMixin],
  data() {
    return {
      target: 1,
      targets: [
        { name: '제목', value: 1 },
        { name: '아이디(이메일)', value: 2 },
        { name: '닉네임', value: 3 },
      ],
      text: null,
      cateCode: '',
      categoryName: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getList() {
      const { result, list, total } = await this.$api.getRemoveList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        cateCode: this.cateCode,
        target: this.target,
        text: this.text,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
        this.selectedIdxs = []
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    ready(target) {
      if (this.selectedIdxs.length > 0) {
        if (target === 'restore') this.confirmRestorePopup()
        if (target === 'remove') this.confirmRemovePopup()
      }
      else {
        this.alertPopup(this.$msg('no-data-selected'))
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmRestorePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.restore.posts'),
        okay: 'RESTORE',
        okayCallback: async() => {
          this.setMsgPopup()
          if (!this.selectedIdxs) return false
          const { result } = await this.$api.boardRestore({ idxs: this.selectedIdxs })
          if (result === 'success') {
            this.allChecked = false
            this.alertPopup(this.$msg('ok.restore'))
            this.getList().then()
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    confirmRemovePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          if (!this.selectedIdxs) return false
          const { result } = await this.$api.boardRealRemove({ idxs: this.selectedIdxs });
          if (result === 'success') {
            this.allChecked = false
            this.alertPopup(this.$msg('ok.delete'))
            this.getList().then()
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>