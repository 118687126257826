<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>1:1문의</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">1:1문의</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="confirmRemovePopup" class="btn btn-outline color-6">삭제</button>
        <button @click="save" class="btn color-1">답변</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">게시글 보기</div>
      <table class="table-cols mb-30">
        <colgroup>
          <col width="200">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th>등록자</th>
          <td>{{ writeEmail }}</td>
        </tr>
        <tr>
          <th>제목</th>
          <td>{{ title }}</td>
        </tr>
        <tr>
          <th>문의내용</th>
          <td v-html="content"></td>
        </tr>
        </tbody>
      </table>

      <validateForm ref="form">
        <div class="contents-box-title">게시글 답변</div>
        <table class="table-cols">
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th>답변 제목</th>
            <td>[RE] {{ title }}</td>
          </tr>
          <tr>
            <th class="require">답변내용</th>
            <td><input-field multiline rows="4" v-model="answerContent" :validate="rules.answerContent" class="block table-input2" maxLength="1000" /></td>
          </tr>
          </tbody>
        </table>
      </validateForm>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: 'inquirySave',
  data() {
    return {
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      writeEmail: null,
      title: null,
      content: null,
      answerContent: null,
      rules: {
        answerContent: [v => !!v || this.$msg('required.input')],
      },
    }
  },
  created() {
    if (this.idx) this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getInquiry({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.writeEmail = data.writeEmail
        this.title = data.title
        this.content = data.content.nl2br()
        this.answerContent = data.answerContent
      }
    },
    async save() {
      this.answerContent = this.answerContent && this.answerContent.trim()
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.inquirySave({
          idx: this.idx,
          answerContent: this.answerContent
        })
        if (result === 'success') {
          this.alertPopup(this.$msg('ok.save'))
          this.goList()
        }
        else {
          this.alertPopup(this.$msg('ok.save'))
        }
      }
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/board/inquiry/list${query}`)
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmRemovePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          const { result } = await this.$api.inquiryRemove({ idxs: [this.idx] })
          result === 'success' ? this.goList() : this.alertPopup(this.$msg('fail'))
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>