<template>
  <div class="range-wrap">
    <vue-slider ref="rangeSlider" :data="rangeData.data" :marks="rangeData.marks" :enable-cross="false" @change="change($event)"/>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
export default {
  components: { VueSlider },
  props: ['rangeData'],
  data() {
    return {
    }
  },
  mounted() {
    this.$refs.rangeSlider.setValue(this.rangeData.range)
  },
  methods: {
    change(event) {
      this.$emit('set', event)
    }
  },
}
</script>