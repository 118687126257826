<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>댓글 관리</span>
      <span>삭제 댓글 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">삭제 댓글 관리</div>
    </div>

    <div class="layout-contents-box">

      <table class="table-cols">
        <colgroup>
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th>등록일</th>
          <td><date ref="date"/></td>
        </tr>
        <tr>
          <th>분류</th>
          <td>
            <label><input type="radio" name="type" value="" v-model="type">전체</label>
            <label><input type="radio" name="type" value="1" v-model="type">댓글</label>
            <label><input type="radio" name="type" value="2" v-model="type">답글</label>
          </td>
        </tr>
        <tr>
          <th>검색어</th>
          <td>
            <div class="d-flex">
              <div class="w-100 mr-10">
                <select v-model="target">
                  <option v-for="item in targets" :key="`target-${item.value}`" :value="item.value">{{ item.name }}</option>
                </select>
              </div>
              <div class="w-300">
                <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="btn-box center my-20">
        <div>
          <button class="btn color-7" @click="search">검색</button>
        </div>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>


      <table class="table-rows">
        <colgroup>
          <col width="100">
          <col width="100">
          <col width="100">
          <col width="150">
          <col>
          <col width="200">
          <col width="150">
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>분류</th>
          <th>게시판</th>
          <th>이미지</th>
          <th>내용</th>
          <th>아이디/닉네임</th>
          <th>등록일/삭제일</th>
        </tr>
        </thead>
        <tbody v-if="this.list && this.list.length > 0">
        <tr v-for="item in this.list" :key="`list-${item.idx}`">
          <td @click.stop><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
          <td>{{ item.type == 1 ? '댓글' : '답글' }}</td>
          <td>{{ item.categoryName }}</td>
          <td><image-alt v-if="item.src" :src="item.src" altType="table" /></td>
          <td class="left">{{ item.content }}<button @click.stop="goWeb(item)" class="icon-link"/></td>
          <td class="left">
            <p>{{ item.email }}</p>
            <p>{{ item.nickName }}</p>
          </td>
          <td>
            <p>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</p>
            <p>{{ $getDateFormat(item.delDate, 'Y-m-d') }}</p>
          </td>
        </tr>
        </tbody>
        <tbody v-else><tr><td colspan="7">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-20">
        <div>
          <button class="btn btn-outline color-7" @click="ready('restore')">댓글 복구</button>
          <button class="btn btn-outline color-7" @click="ready('remove')">영구 삭제</button>
        </div>
      </div>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>

    <div class="information-box">
      <div class="information-box-title">안내</div>
      <div class="information-contents">
        <ul class="dot-list">
          <li>삭제된 댓글 내역을 확인할 수 있는 화면 입니다.</li>
          <li>댓글 복구 클릭 시 삭제된 댓글을 복구 할 수 있습니다.</li>
          <li>영구 삭제된 게시물은 복구가 불가능합니다.</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'
export default {
  name: 'commentRemoveList',
  mixins: [listMixin],
  data() {
    return {
      target: 1,
      targets: [
        { name: '이메일(아이디)', value: 1 },
        { name: '닉네임', value: 2 },
      ],
      text: null,
      reportId: null,
      type: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { result, data, total } = await this.$api.getCommentRemoveList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        type: this.type,
        target: this.target,
        text: this.text,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = data
        this.selectedIdxs = []
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    ready(target) {
      if (this.selectedIdxs.length > 0) {
        if (target === 'remove') this.confirmRemovePopup()
        if (target === 'restore') this.confirmRestorePopup()
      }
      else {
        this.alertPopup(this.$msg('no-data-selected'))
      }
    },
    goWeb(item) {
      let board = null
      if (item.cateCode.toString().startsWith('10')) board = 'tip'
      if (item.cateCode.toString().startsWith('20')) board = 'lab'
      if (item.cateCode.toString().startsWith('40')) board = 'event'
      if (item.cateCode.toString().startsWith('80')) board = 'ggomunity'
      window.open(`${this.$domain}/${board}/${item.boIdx}`)
    },
    confirmRestorePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.comment.restore'),
        okay: 'RESTORE',
        okayCallback: async() => {
          this.setMsgPopup()
          if (!this.selectedIdxs) return false
          const { result } = await this.$api.commentRestore({ idxs: this.selectedIdxs });
          if (result === 'success') {
            this.allChecked = false
            this.alertPopup(this.$msg('ok.restore'))
            await this.getList()
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    confirmRemovePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.comment.remove2'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          if (!this.selectedIdxs) return false
          const { result } = await this.$api.commentRealRemove({ idxs: this.selectedIdxs });
          if (result === 'success') {
            this.allChecked = false
            this.alertPopup(this.$msg('ok.delete'))
            await this.getList()
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>