<template>
  <div class="layout-header">

    <div class="logo-util">
      <div class="logo cursor-pointer" @click="$router.push('/dashboard/')"></div>
      <div class="util-menu">

        <div class="alarm" @click="open('notification')" v-click-outside="closeNotification" >
          <div class="icon-btn icon-alarm"></div>
          <div class="count">{{ notification.count }}</div>
          <div v-if="isOpen.notification" class="alarm-popup">
            <a class="cursor-pointer" @click="goPage('/board/tip/list?state=0')">심사중: <strong>{{ notification.boardCount }}건</strong></a>
            <a class="cursor-pointer" @click="goPage('/board/inquiry/list')">답변대기: <strong>{{ notification.inquiryCount }}건</strong></a>
          </div>
        </div>

        <div class="userMenu" @click="open('menu')" v-click-outside="closeMenu">
          <strong>{{ getName }}({{ getNickName }})</strong> 님
          <div v-if="isOpen.menu" class="util-menu-pop">
          <a href="#" @click="logout">로그아웃</a>
          </div>
        </div>

      </div>
    </div>
    <div class="navi-box">
      <ul class="navi">
        <li><router-link to="/dashboard/" :class="{ on : $route.path.startsWith('/dashboard') }">대시보드</router-link></li>
        <li><router-link to="/policy/baseInfo" :class="{ on : $route.path.startsWith('/policy') }">운영관리</router-link></li>
        <li><router-link to="/member/list" :class="{ on : $route.path.startsWith('/member') }">회원</router-link></li>
        <li><router-link to="/board/tip/list" :class="{ on : $route.path.startsWith('/board') }">게시판</router-link></li>
        <li><router-link to="/product/list" :class="{ on : $route.path.startsWith('/product') }">상품</router-link></li>
        <li><router-link to="/pet/list" :class="{ on : $route.path.startsWith('/pet') }">반려동물</router-link></li>
        <li><router-link to="/design/banner/list" :class="{ on : $route.path.startsWith('/design') }">디자인</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      notification: {
        count: 0,
        boardCount: 0,
        inquiryCount: 0,
      },
      isOpen: {
        menu: false,
        notification: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'session/isLogin',
      getAuthToken: 'session/getAuthToken',
      getId: 'session/getId',
      getEmail: 'session/getEmail',
      getName: 'session/getName',
      getNickName: 'session/getNickName',
      getPhone: 'session/getPhone',
    }),
  },
  watch: {
    $route() {
      this.setNotificationCount()
    }
  },
  created() {
    this.setNotificationCount()
  },
  methods:{
    ...mapActions({
      clearSession: 'session/clearSession',
    }),
    async setNotificationCount() {
      const { result, count, boardCount, inquiryCount } = await this.$api.getNotificationCount()
      if (result === 'success') {
        this.notification.count = count
        this.notification.boardCount = boardCount
        this.notification.inquiryCount = inquiryCount
      }
    },
    open(target) {
      this.isOpen[target] = true
    },
    closeMenu() {
      this.isOpen.menu = false
    },
    closeNotification() {
      this.isOpen.notification = false
    },
    goPage(url) {
      this.$router.push(url)
      this.closeNotification()
    },
    logout() {
      this.clearSession()
      localStorage.removeItem('auth')
      localStorage.removeItem('id')
      localStorage.removeItem('email')
      localStorage.removeItem('name')
      localStorage.removeItem('nickName')
      localStorage.removeItem('phone')
      location.href = "/login"
    },
  }
}
</script>
