<template>
  <div v-if="altType === 'list'">
    <img v-show="imageLoaded && !imageError" :src="src" @load="onImageLoaded" @error="onImageError" loading=”lazy”/>
    <img v-show="imageLoaded == false || imageError" src="@/assets/img/common/noDataImg_288.png"/>
<!--    <img :src="src" @error="altImg">-->
  </div>
  <div v-else-if="altType === 'table'" class="w-110 mx-auto">
    <img v-show="imageLoaded && !imageError" :src="src" class="w-100p" @load="onImageLoaded" @error="onImageError" loading=”lazy”/>
    <img v-show="imageLoaded == false || imageError" src="@/assets/img/common/noDataImg_288.png" class="w-110 mx-auto" />
    <!--    <img :src="src" @error="altImg">-->
  </div>
  <div v-else-if="altType === 'detail'">
<!--    <img v-show="imageLoaded && !imageError" :src="src" @load="onImageLoaded" @error="onImageError" loading=”lazy”/>
    <img v-show="imageLoaded == false || imageError" src="@/assets/img/common/noDataImg_800.png"/>-->
    <img :src="src" @error="altImg">
  </div>
  <div v-else-if="altType === 'banner'">
    <img v-show="imageLoaded && !imageError" :src="src" @load="onImageLoaded" @error="onImageError" loading=”lazy”/>
    <img v-show="imageLoaded == false || imageError" src="@/assets/img/common/noDataImg_288.png"/>
  </div>
  <div v-else-if="altType === 'profile'">
    <img v-show="imageLoaded && !imageError" :src="src" @load="onImageLoaded" @error="onImageError" loading=”lazy”/>
  </div>
</template>

<script>
import listAltImg from '@/assets/img/common/noDataImg_288.png'
import detailAltImg from '@/assets/img/common/noDataImg_800.png'
export default {
  name: "imageAlt",
  props: ['src', 'altType'],
  data() {
    return {
      imageLoaded: false,
      imageError: false,
    }
  },
  created() {
  },
  methods: {
    altImg(e) {
      if (this.altType === 'list') e.target.src = listAltImg
      if (this.altType === 'detail') e.target.src = listAltImg
      // if (this.altType === 'detail') e.target.src = detailAltImg
    },
    onImageLoaded() {
      this.imageLoaded = true;
    },
    onImageError() {
      this.imageError = true;
    },
  },
  mounted() {
  }
}
</script>
