<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>랜선집사</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">랜선집사</div>
      <div>
        <button @click="goSave()" class="btn color-1">등록</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <table class="table-cols">
        <colgroup>
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th>업로드 날짜</th>
          <td><date ref="date"/></td>
        </tr>
        <tr>
          <th>검색어</th>
          <td>
            <div class="d-flex">
              <div class="w-100 mr-10">
                <select v-model="target">
                  <option v-for="item in targets" :key="`target-${item.value}`" :value="item.value">{{ item.name }}</option>
                </select>
              </div>
              <div class="w-300">
                <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="btn-box center my-20">
        <div>
          <button class="btn color-7" @click="search">검색</button>
        </div>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>

      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col width="150">
          <col width="150">
          <col>
          <col width="100">
          <col width="130">
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>고유번호</th>
          <th>이미지</th>
          <th>제목</th>
          <th>조회수</th>
          <th>업로드날짜</th>
        </tr>
        </thead>
        <tbody v-if="this.list && this.list.length > 0">
        <tr v-for="item in this.list" :key="`list-${item.idx}`" @click="goWeb(item.idx)" class="cursor-pointer">
          <td @click.stop><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
          <td>{{ item.ytIdx }}</td>
          <td><image-alt v-if="item.thumbnail" :src="item.thumbnail" altType="table" /></td>
          <td class="left">
            <p class="fw-bold">{{ item.title }}</p>
            <p class="color-7">{{ item.chanName }}</p>
          </td>
          <td>{{ item.view.numberFormat() }}</td>
          <td>{{ $getDateFormat(item.uploadDate, 'Y-m-d') }}</td>
        </tr>
        </tbody>
        <tbody v-else><tr><td colspan="6">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-20">
        <div>
          <button class="btn btn-outline color-7" @click="ready('remove')">선택 삭제</button>
        </div>
      </div>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>

    <div class="information-box">
      <div class="information-box-title">안내</div>
      <div class="information-contents">
        <ul class="dot-list">
          <li>게시물 삭제 시 “삭제 게시물 관리” 메뉴에서 확인 가능합니다.</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex"
import listMixin from '@/mixins/listMixin'
export default {
  ame: 'mdList',
  mixins: [listMixin],
  data() {
    return {
      target: 'title',
      targets: [
        { name: '제목', value: 'title' },
        { name: '고유번호', value: 'ytIdx' },
      ],
      text: null,
      type: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getList() {
      const { result, list, total } = await this.$api.getMdList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        target: this.target,
        text: this.text,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
        this.selectedIdxs = []
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    async remove() {
      if (!this.selectedIdxs) return false
      const { result } = await this.$api.boardRemove({ idxs: this.selectedIdxs });
      if (result === 'success') {
        this.allChecked = false
        this.setMsgPopup()
        this.alertPopup(this.$msg('ok.delete'))
        await this.getList()
      }
    },
    goSave() {
      this.$router.push('/board/md/save')
    },
    goWeb(idx) {
      window.open(`${this.$domain}/md`)
      // window.open(`${this.$domain}/md/${idx}`)
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmRemovePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove3'),
        okay: 'REMOVE',
        okayCallback: () => {
          this.remove()
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>