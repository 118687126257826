<template>
  <div>
    <div v-if="attach.length+newAttach.length < 10" class="file-box w-400">
      <label>
        <button class="btn btn-x-sm color-6 btn-round0" @click="$refs.uploadImage.click()">이미지 선택</button>
      </label>
      <input type="text" disabled="" class="">
      <input type="file" accept="image/gif, image/jpg, image/jpeg, image/png" multiple
             ref="uploadImage" @change="uploadImage($event)" style="display:none;">
    </div>

    <div class="imgBox">
      <div v-if="attach.length > 0" class="btnBox">
        <button @click="downloadAll" class="btn btn-x-sm color-4">모두 다운로드</button>
        <button @click="openView" class="btn btn-x-sm color-1">모두 보기</button>
      </div>

      <ul class="imgList">
        <li v-for="(item, index) in attach" :key="`file${index}`">
          <span>{{ item.realFileName }} ({{ $formatBytes(item.fileSize) }})</span>
          <button @click="confirmRemovePopup(item.atchIdx)"><font-awesome-icon class="px-5" :icon="['fas', 'fa-circle-xmark']" /></button>
        </li>
        <li v-for="(item, index) in newAttach" :key="`newFile${index}`">
          <span>{{ item.file_name }} ({{ $formatBytes(item.file_size) }})</span>
          <button @click="removeNew(index)"><font-awesome-icon class="px-5" :icon="['fas', 'fa-circle-xmark']" /></button>
        </li>
      </ul>
    </div>

    <div v-if="msg" class="error-message">{{ msg }}</div>

    <image-popup v-if="srcList" :srcList="srcList" @close="closeView"/>
  </div>
</template>

<script>
import Compressor from 'compressorjs'
import {mapActions} from 'vuex'
import imagePopup from '@/components/common/imagePopup'

export default {
  name: 'uploadImageGroup',
  props: ['attach', 'newAttach'],
  components: { imagePopup },
  data() {
    return {
      msg: null,
      srcList: null,
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    uploadImage(event) {
      if (!event) return false
      event.stopPropagation()
      event.preventDefault()

      let result = false
      this.msg = null
      let files = event.target.files || event.dataTransfer.files
      if (files.length == 0) {
        this.msg = this.$msg('required.input')
      }
      if (files.length > 0) {
        for (let file of files) {
          if (!/^image\//.test(file.type)) {
            this.msg = this.$msg('image.ext')
            return false
          }

          let fileExt = this.$getExts(file.name, false)
          let isImg = this.$isImages(fileExt)
          if (isImg) {
            if (file.size < this.$MBtoB(10)) { // 10MB
              result = true
            } else {
              this.msg = this.$msg('image.limit')
            }
          }

          if (result) {
            let vm = this

            const img = new Image()
            img.src = URL.createObjectURL(file)

            img.onload = () => {
              const isCompressor = fileExt === 'gif' || !(img.width > vm.$imageMaxWidth || img.height > vm.imageMaxHeight) ? false : true

              if (isCompressor) {
                const options = {
                  maxWidth: vm.$imageMaxWidth,
                  maxHeight: vm.imageMaxHeight,
                  success: (_file) => {
                    if (_file.size > vm.$MBtoB(10)) { // 리사이징 했는데도 용량이 큰 경우
                      vm.alertPopup('image.limit')
                      return false
                    }
                    let reader = new FileReader()
                    reader.onload = () => {
                      vm.$emit('upload', { file_data: reader.result, file_name: _file.name, file_size: _file.size })
                      event.target.value = ''
                    }
                    reader.readAsDataURL(_file)
                  },
                  error: (err) => console.log(err)
                }
                new Compressor(file, options)
              }
              else {
                let reader = new FileReader()
                reader.onload = function () {
                  vm.$emit('upload', { file_data: reader.result, file_name: file.name, file_size: file.size })
                  event.target.value = ''
                }
                reader.readAsDataURL(file)
              }
            }
          }

        }
      }
    },
    openView() {
      this.srcList = this.attach.map(a => a.src)
    },
    closeView() {
      this.srcList = null
    },
    downloadAll() {
      this.$api.downloadGgomunityFiles({ idx: this.$route.query.idx })
    },
    removeNew(index) {
      this.$emit('removeNew', index)
    },
    async remove(atchIdx) {
      this.setMsgPopup()
      const { result } = await this.$api.removeAttachFile({ idx: atchIdx });
      if (result === 'success') this.alertPopup(this.$msg('ok.delete'))
      else                      this.alertPopup(this.$msg('fail'))
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmRemovePopup(atchIdx) {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove.file'),
        okay: 'REMOVE',
        okayCallback: () => {
          this.remove(atchIdx)
          this.$emit('remove', atchIdx)
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>