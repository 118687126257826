<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>공지사항</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">공지사항</div>
      <div>
        <button @click="goSave()" class="btn color-1">등록</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <table class="table-cols">
        <colgroup>
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th>등록일</th>
          <td><date ref="date"/></td>
        </tr>
        <tr>
          <th>제목</th>
          <td>
            <div class="table-input"><input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search"></div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="btn-box center my-20">
        <div>
          <button class="btn color-7" @click="search">검색</button>
        </div>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>

      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col>
          <col width="150">
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>제목</th>
          <th>등록일/수정일</th>
        </tr>
        </thead>
        <tbody v-if="list && list.length > 0">
        <tr v-for="item in list" :key="`list-${item.idx}`" @click="goSave(item.idx)" class="cursor-pointer">
          <td @click.stop><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
          <td class="left"><strong v-if="item.pinYN" class="color-1">[상위고정] </strong>{{ item.title }}<button @click.stop="goWeb" class="icon-link"></button></td>
          <td>
            <p>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</p>
            <p>{{ $getDateFormat(item.updateDate, 'Y-m-d') }}</p>
          </td>
        </tr>
        </tbody>
        <tbody v-else><tr><td colspan="3">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-20">
        <div>
          <button class="btn btn-outline color-7" @click="ready('remove')">선택 삭제</button>
        </div>
      </div>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>
  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'
export default {
  name: 'noticeList',
  mixins: [listMixin],
  data() {
    return {
      list: [],
      text: null,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { result, list, total } = await this.$api.getNoticeList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        text: this.text,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
        this.selectedIdxs = []
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    async remove() {
      if (!this.selectedIdxs) return false
      const { result } = await this.$api.aboardsRemove({
        target: 'notice',
        idxs: this.selectedIdxs
      })
      if (result === 'success') {
        this.allChecked = false
        this.alertPopup(this.$msg('ok.delete'))
        await this.getList()
      }
    },
    goSave(idx=0) {
      this.$router.push(idx
        ? `/board/notice/save?idx=${idx}&page=${this.page}`
        : `/board/notice/save?page=${this.page}`)
    },
    goWeb() {
      window.open(`${this.$domain}/notice`)
    }
  }
}
</script>