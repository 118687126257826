import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

// 이미지 최대 크기
Vue.prototype.$imageMaxWidth = 2560
Vue.prototype.imageMaxHeight = 7680

// axios
import api from './api'
Vue.prototype.$api = new api()

// 메시지
import message from '@/plugins/message'
Vue.prototype.$msg = (str) => {
  return message[str]
}

Vue.prototype.$domain = process.env.NODE_ENV === 'production' ? 'https://www.ggolab.co.kr' : 'https://ggolab.programrush.co.kr'

// v-movable
import movable from 'v-movable'
Vue.use(movable)

// calendar
/*import VCalendar from 'v-calendar'
Vue.use(VCalendar)*/


// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBars, faAngleDown, faAngleUp, faPen, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
library.add(faBars, faAngleDown, faAngleUp, faPen, faCircleXmark)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// utils
import util from './utils/util'
Vue.use(util)
import dateUtil from './utils/dateUtil'
Vue.use(dateUtil)

// toast
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

// 전역 사용자 정의 디렉티브 v-click-outside 등록
import clickOutside from 'vue-click-outside'
Vue.directive("clickOutside", clickOutside)

// 전역 사용자 정의 디렉티브 v-focus 등록
Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

// 전역 컴포넌트 선언
Vue.component('inputField', () => import('@/components/common/inputField'))
Vue.component('selectBox', () => import('@/components/common/selectBox'))
Vue.component('validateForm', () => import('@/components/common/validateForm'))
Vue.component('ctgryPopup', () => import('@/components/common/ctgryPopup'))
Vue.component('displayBoardPopup', () => import('@/components/common/displayBoardPopup'))
Vue.component('mdsPickPopup', () => import('@/components/common/mdsPickPopup'))
Vue.component('pagination', () => import('@/components/common/pagination'))
Vue.component('date', () => import('@/components/common/date'))
Vue.component('imageAlt', () => import('@/components/common/imageAlt'))
Vue.component('uploadImage', () => import('@/components/common/uploadImage'))
Vue.component('uploadImageGroup', () => import('@/components/common/uploadImageGroup'))
Vue.component('editor', () => import('@/components/tiptap/editor'))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  message,
  render: h => h(App),
}).$mount('#app')

Vue.config.productionTip = false
