<template>
  <div>
    <div class="navi-bar">
      <span>운영 관리</span>
      <span>분류 관리</span>
      <span>공통 분류 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">공통 분류 관리</div>
    </div>

    <div class="classification-wrap">
      <div class="classification-box">
        <h3>분류 관리</h3>
        <tree ref="tree" :list="list" @setList="setList" @nodeClick="active" @setModify="modifyNode" />
      </div>

      <div class="classification-box">
        <h3>분류 설정</h3>

        <div v-if="data.parentCode" class="setClassification">
          <validateForm ref="form">
            <table class="table-cols">
              <colgroup>
                <col width="180">
                <col>
              </colgroup>
              <tbody>
                <tr>
                  <th>상위 분류명</th>
                  <td>{{ data.parentName }} ({{ data.parentCode }})</td>
                </tr>
                <tr>
                  <th class="require">하위 분류명</th>
                  <td>
                    <div class="">
                      <div class="">
                        <input-field type="text" v-model="data.codeName" :validate="rules" placeholder="분류명 입력" maxLength="20" @keypress.enter="save" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>사용여부</th>
                  <td>
                    <label><input type="radio" name="useYN" value="Y" v-model="data.useYN">사용함</label>
                    <label><input type="radio" name="useYN" value="N" v-model="data.useYN">사용안함</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </validateForm>
          <div class="btn-area">
            <template v-if="data.code === 0">
              <button class="btn color-1" @click="save">등록</button>
            </template>
            <template v-else>
              <button class="btn btn-outline color-6" @click="chkRemove">삭제</button>
              <button class="btn color-1" @click="save">수정</button>
            </template>
          </div>
        </div>

        <div v-else class="setClassification">
          <img src="@/assets/img/common/infoImg.png" class="pt-30">
          <p>분류 클릭 시<br> 하위분류를 등록, 수정할 수 있습니다.<br><span>(분류는 2차 분류 까지만 등록 가능합니다.)</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
import tree from '@/components/tree/index'
export default {
  components: {tree},
  data() {
    return {
      list: [],
      data: {},
      rules: [v => !!v || this.$msg('required.input')],
    }
  },
  created() {
    this.getList()
    this.clearData()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    setList(list) {
      this.list = list
    },
    toggleOpen(item) {
      item.isOpen = !item.isOpen
    },
    active(data, component) {
      this.clearData()
      if (data.useYN == 'Y') {
        if (!component.isActive) {
          if (data.codeLev === 1) {
            this.data = {
              ...data,
              parentCode: data.code,
              parentName: data.codeName,
              code: 0,
              codeName: null,
              useYN: 'Y',
              codeLev: parseInt(data.codeLev) + 1
            }
          }
          else {
            this.data = data
          }
        }
      }
    },
    modifyNode(data) {
      this.clearData()
      this.data = data
    },
    async getList() {
      const { result, data } = await this.$api.getCodeList()
      if (result === 'success') {
        let list = []
        for (let l of data) {
          let children = []
          for (let c of l.children) {
            children.push({
              ...c,
              isActive: false,
              isEdit: false,
            })
          }
          list.push({
            ...l,
            children,
            isOpen: false,
            isActive: false,
          })
        }
        this.list = list
      }
    },
    async chkRemove() {
      const { result, data } = await this.$api.checkCode({ code: this.data.code });
      if (result === 'success') {
        data ? this.alertPopup(this.$msg('fail.delete.classification')) : this.confirmRemovePopup()
      }
      else {
        this.openToast(this.$msg('fail'))
      }
    },
    async remove() {
      const { result } = await this.$api.removeCode({
        code: this.data.code,
        codeLev: this.data.codeLev
      });
      if (result === 'success') {
        this.openToast(this.$msg('ok.delete'))
        this.getList().then()
        this.clearData()
      }
      else {
        this.openToast(this.$msg('fail'))
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.saveCode(this.data);
        if (result === 'success') {
          this.getList().then()
          if (this.data.code === 0) {
            this.data.codeName = null
            this.openToast(this.$msg('ok.insert'))
          }
          else {
            this.openToast(this.$msg('ok.update'))
          }
        }
        else {
          this.openToast(this.$msg('fail'))
        }
      }
    },
    clearData() {
      this.data = {
        code: 0,
        codeName: null,
        codeLev: 2,
        parentCode: null,
        parentName: null,
        useYN: 'Y',
      }
    },
    openToast(msg) {
      this.$toast.open({
        message: msg,
        type: "default",
        position: 'bottom-right',
        duration: 3000,
        dismissible: true
      })
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmRemovePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: () => {
          this.remove()
          this.setMsgPopup()
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>