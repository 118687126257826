<template>
  <div>
    <div class="navi-bar"> 
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>꼬리TIP</span>
      <span>{{ this.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">꼬리TIP</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="setSaveParams" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">기본정보</div>
        <table class="table-cols">
          <colgroup>
            <col width="200">
            <col width="300">
            <col width="180">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th>아이디(이메일)</th>
            <td>{{ email }}</td>
            <th>닉네임</th>
            <td>{{ nickName }}</td>
          </tr>
          <tr>
            <th>등록일</th>
            <td>{{ createDate }}</td>
            <th>수정일</th>
            <td>{{ updateDate }}</td>
          </tr>
          </tbody>
        </table>

        <div class="contents-box-title">컨텐츠 내용</div>
        <table class="table-cols" ref="table">
          <colgroup>
            <col width="200">
            <col width="300">
            <col width="180">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">구분</th>
            <td colspan="3"><select-box selectBoxClass="w-100 mr-5" :options="opt.saveStatus" v-model="saveStatus" :errorMessage="saveStatusMsg"/></td>
          </tr>
          <tr>
            <th class="require">카테고리</th>
            <td><select-box :selectBoxClass="'w-100 mr-5'" :options="opt.cateCode" v-model="cateCode" :errorMessage="cateCodeMsg"/></td>
            <th class="require">펫 구분</th>
            <td><select-box :selectBoxClass="'w-100 mr-5'" :options="opt.petCode" v-model="petCode" :errorMessage="petCodeMsg"/></td>
          </tr>
          <tr>
            <th class="require">제목</th>
            <td colspan="3"><input-field v-model="title" class="d-inline-block" :errorMessage="titleMsg" lengthCount maxLength="50"/></td>
          </tr>
          <tr>
            <th class="require">대표이미지</th>
            <td colspan="3"><upload-image ref="repImage" @upload="setRepImg" :imgSrc="repImgSrc" :imgName="repImgName"/></td>
          </tr>
          </tbody>
        </table>

        <editor ref="editor" :cateCode="10" :previewH="this.previewH"/>

      </div>
    </validateForm>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import saveEditorMixin from "@/mixins/saveEditorMixin"
export default {
  name: 'tipSave',
  mixins: [ saveEditorMixin ],
  data() {
    return {
      opt: {
        saveStatus: [
          {value: '9', text: '임시저장'},
          {value: '0', text: '심사중'},
          {value: '1', text: '심사완료'},
        ],
        cateCode: [],
        petCode: [
          { value: null, text: '선택' },
          {value: 10, text: '강아지'},
          {value: 20, text: '고양이'}
        ],
      },
      repImgSrc: null,
      repImgName: null,
      repImgData: null,
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      saveStatus: '9',
      cateCode: null,
      petCode: null,
      title: null,
      writerType: 1,
      email: null,
      nickName: null,
      content: null,
      createDate: null,
      updateDate: null,
      saveStatusMsg: '',
      cateCodeMsg: '',
      petCodeMsg: '',
      titleMsg: '',
    }
  },
  watch: {
    saveStatus() {
      this.saveStatusMsg = ''
    },
    cateCode() {
      this.cateCodeMsg = ''
    },
    petCode() {
      this.petCodeMsg = ''
    },
    title() {
      this.titleMsg = ''
    },
  },
  computed: {
    ...mapGetters({
      getId: 'session/getId',
      getNickName: 'session/getNickName',
    }),
  },
  mounted() {
    this.getCtgryList(10)
    if (this.idx) this.setItem()
    this.email = this.getId
    this.nickName = this.getNickName
    this.setPreviewHeight()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getTip({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.saveStatus = data.saveStatus.toString()
        this.cateCode = data.cateCode
        this.petCode = data.petCode
        this.title = data.title
        this.writerType = data.writerType
        this.email = data.email
        this.nickName = data.nickName
        this.repImgSrc = data.repImgSrc
        this.repImgName = data.repImgName
        this.createDate = data.createDate
        this.updateDate = data.updateDate

        this.$refs.editor.editor.commands.setContent(data.content)
        this.$refs.editor.savedContent = data.content
        this.$refs.editor.editorImageIds = this.getImageIds(data.content)
        this.$refs.editor.savedImageIds = this.getImageIds(this.$refs.editor.savedContent)
      }
    },
    validate() {
      if (!this.saveStatus) {
        this.saveStatusMsg = this.$msg('required.select')
        return false
      }
      if (!this.cateCode) {
        this.cateCodeMsg = this.$msg('required.select')
        return false
      }
      if (!this.petCode) {
        this.petCodeMsg = this.$msg('required.select')
        return false
      }
      if (!this.title) {
        this.titleMsg = this.$msg('required.input')
        return false
      }
      return true
    },
    setSaveParams() {
      if (this.validate() && this.validateSave()) {
        this.save({
          idx: this.idx,
          status: this.saveStatus,
          cateCode: this.cateCode,
          petCode: this.petCode,
          title: this.title,
          repImgData: this.repImgData,
          repImgName: this.repImgName,
          content: this.$refs.editor.editor.getHTML(),
          writerId: this.email,
          writerName: this.nickName,
          editImg: this.$refs.editor.getEditorImageIds(),
        })
      }
    },
    async save(params) {
      const { result } = await this.$api.tipSave(params);
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'), `/board/tip/list`)
      }
      else {
        this.alertPopup(this.$msg('fail'), null)
      }
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/board/tip/list${query}`)
    },
    alertPopup(msg, movePath) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (movePath) location.href = movePath
        },
      })
    },
  }
}
</script>