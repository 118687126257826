<template>
  <div class="vld-parent">
    <loading ref="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"
             :loader="'spinner'"
             :height="40"
             :width="40"
             :lock-scroll="true"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  props: ['isLoading'],
  data() {
    return {
    }
  },
  components: {
    Loading
  },
  watch: {
    isLoading(v) {
      this.$refs.loading.isActive = v
    }
  },
  mounted() {
    // this.$refs.loading.isActive = false
  },
  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style>
.vld-background {
  opacity: 0 !important;
}
</style>