export default {
  install(Vue) {
    Vue.prototype.$getDateFormat = function(date, format, days = 0) {
      if (!date) return null
      let _date = date
      if (typeof date === 'string') _date = new Date(date.replace(/\-/gi, "/").replace(/T/g, ' ').replace(/Z/g, ''))

      if (days !== 0) {
        const time = _date.getTime()
        _date = new Date(time + (86400 * days * 1000))
      }

      const year = _date.getYear()
      const month = _date.getMonth() + 1
      const day = _date.getDate()
      let dYear = _date.getFullYear()
      let dMonth = month
      let dDay = day

      if (month.toString().length == 1) dMonth = "0" + month
      if (day.toString().length == 1) dDay = "0" + day

      format = format.replace("Y", dYear)
      format = format.replace("m", dMonth)
      format = format.replace("d", dDay)
      format = format.replace("y", year)
      format = format.replace("n", month)
      format = format.replace("j", day)

      const hours = _date.getHours()
      const min = _date.getMinutes()
      const sec = _date.getSeconds()
      let dHours = hours
      let dMin = min
      let dSec = sec

      if (hours.toString().length == 1) dHours = "0" + hours
      if (min.toString().length == 1) dMin = "0" + min
      if (sec.toString().length == 1) dSec = "0" + sec

      format = format.replace("H", dHours)
      format = format.replace("i", dMin)
      format = format.replace("s", dSec)

      return format
    }

    Date.prototype.$getDate = function(days = 0) {
      let date = this
      if (days !== 0) {
        const time = date.getTime()
        date = new Date(time + (86400 * days * 1000))
      }
      return Vue.prototype.$getDateFormat(date, 'Y.m.d')
    }

    Vue.prototype.$calcAge = function(yyyymmdd) {
      const diffDate = new Date().getTime() - new Date(yyyymmdd.substring(0, 4), (yyyymmdd.substring(4, 6)), yyyymmdd.substring(6, 8)).getTime()
      const dateDays = Math.abs(diffDate / (1000 * 3600 * 24)) // 일수
      const months = Math.floor(dateDays / 30) // 개월수
      return `${Math.floor(months / 12)}살 ${months % 12}개월`
    }

    Vue.prototype.$YYYYMMDDWithFormat = function(yyyymmdd, str='', isCalcAge) {
      yyyymmdd = yyyymmdd.toString()
      if (isCalcAge) {
        return `${yyyymmdd.substring(0, 4)}${str}${yyyymmdd.substring(4, 6)}${str}${yyyymmdd.substring(6, 8)} (${this.$calcAge(yyyymmdd)})`
      }
      return `${yyyymmdd.substring(0, 4)}${str}${yyyymmdd.substring(4, 6)}${str}${yyyymmdd.substring(6, 8)}`
    }
  }
}
