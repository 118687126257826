<template>
  <div class="popupBox-wrap">
    <div class="popupPosition" @click="close"></div>

    <div class="popupBox w-600">
      <div class="popupBox-title">카테고리 설정</div>
      <div class="popupBox-body">

        <div class="d-flex mb-10">
          <div class="mr-15">카테고리명</div>
          <div class="w-250 mr-5">
            <input type="text" v-model="name" placeholder="카테고리명 입력" maxlength="30" @keypress.enter="save(0, name)">
            <div v-if="msg" class="error-message">{{ msg }}</div>
          </div>
          <div>
            <button @click="save(0, name)" class="btn btn-x-sm color-1">등록</button>
          </div>
        </div>

        <table class="table-rows">
          <colgroup>
            <col width="70">
            <col>
            <col width="90">
          </colgroup>
          <thead>
          <tr>
            <th>번호</th>
            <th>카테고리 명</th>
            <th>관리</th>
          </tr>
          </thead>
          <tbody v-if="this.list && this.list.length > 0">
            <tr v-for="(item, index) in this.list" :key="`list-${item.cateCode}`">
              <td>{{ index+1 }}</td>
              <td>
                <input type="text" placeholder="카테고리명 입력" v-model="item.cateName">
                <div v-if="msg2 && item.cateCode == targetCode" class="error-message">{{ msg2 }}</div>
              </td>
              <td><button class="btn btn-x-sm color-6" @click="save(item.cateCode, item.cateName)">수정</button></td>
            </tr>
          </tbody>
          <tbody v-else><tr><td colspan="3">{{ $msg('nodata') }}</td></tr></tbody>
        </table>

        <div class="information-box">
          <div class="information-box-title">안내</div>
          <div class="information-contents">
            <ul class="dot-list">
              <li>등록된 카테고리는 삭제가 불가능합니다.</li>
              <li>기존에 등록된 카테고리 명 수정 시 해당 카테고리를 참조 하고 있는 데이터도 일괄 변경됩니다.</li>
              <li>카테고리는 최대 10개 까지만 등록 가능합니다.</li>
            </ul>
          </div>
        </div>

      </div>
      <div class="popupBox-bottom">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">닫기</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex"

export default {
  props: ['target'],
  data() {
    return {
      list: [],
      name: null,
      msg: null,
      msg2: null,
      targetCode: null,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getList() {
      const { result, data } = await this.$api.getProductMainCategory({ target: this.target })
      if (result === 'success') this.list = data
    },
    async save(code, name) {
      this.targetCode = null
      this.msg = null
      this.msg2 = null
      if (!name) {
        if (code > 0) {
          this.targetCode = code
          this.msg2 = this.$msg('ctgry.check')
          return false
        }
        else {
          this.msg = this.$msg('ctgry.check')
          return false
        }
      }
      else if (!code && this.list.length >= 10) {
        this.msg = this.$msg('ctgry.limit')
        return false
      }
      const { result } = await this.$api.setProductMainCategory({
        target: this.target,
        cateCode: code,
        cateName: name,
      })
      if (result === 'success') {
        this.name = null
        this.alertPopup(this.$msg('ok.save'))
        this.getList().then()
      }
      else {
        this.name = null
        this.alertPopup(this.$msg('fail'))
      }
    },
    close() {
      this.$emit('close')
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}

</script>