<template>
  <div>
    <div class="navi-bar"> 
        <span>운영관리</span>
        <span>기본정책</span>
        <span>약관설정</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">약관설정</div>
      <div><button @click="save" class="btn color-1">저장</button></div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div class="tab-btn-box">
          <div v-for="(item, index) in opt.target" :key="index" :class="['tab-btn', { on : target == item.value }]" @click="tab(item.value)">{{ item.text }}</div>
        </div>
      </div>
      <textarea class="block" rows="20" v-model="content"></textarea>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex"

export default {
  name: 'terms',
  data() {
    return {
      opt: {
        target: [
          { text: '이용약관', value: 1 },
          { text: '개인정보처리방침', value: 2 },
          { text: '개인정보수집 및 이용동의', value: 3 },
          { text: '개인정보수집 제3자 제공 동의', value: 4 },
          { text: '탈퇴안내', value: 5 },
        ]
      },
      target: 1,
      content: null,
      originalContent: null,
      isOpenSaveMovePopup: false,
    }
  },
  watch: {
    target() {
      this.setItem()
    }
  },
  created() {
    this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getPolicy({target: this.target})
      if (result === 'success') {
        this.content = data.content
        this.originalContent = data.content
      }
    },
    async save() {
      const { result } = await this.$api.setPolicy({
        target: this.target,
        content: this.content,
      })
      if (result === 'success') {
        this.originalContent = this.content
        this.alertPopup(this.$msg('ok.save'))
      }
      else {
        this.alertPopup(this.$msg('fail'))
      }
    },
    tab(target) {
      if (this.originalContent != this.content) {
        this.confirmMovePopup(target)
      }
      else {
        this.target = target
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmMovePopup(target) {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.saveMove'),
        okay: 'MOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          if (!this.content) return false
          const { result } = await this.$api.setPolicy({
            target: this.target,
            content: this.content,
          })
          if (result === 'success') this.target = target
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>
