<template>
  <div id="app">
    <template v-if="isLogin">

      <loading :isLoading="isLoading"/>

      <global-header/>
      <div class="layout-container">
        <global-lnb/>
        <div class="layout-contents">
          <transition appear name="page-fade" mode="out-in">
            <router-view :key="routerViewKey" />
          </transition>
        </div>
      </div>
      <global-footer/>

    </template>
    <transition appear name="page-fade" mode="out-in" v-else>
      <login />
    </transition>

    <confirm-popup v-if="getMsgPopup.message" :confirmType="getMsgPopup.type"
                   :onYesClick="getMsgPopup.okayCallback" :onNoClick="getMsgPopup.cancelCallback"
                   :confirmMessage="getMsgPopup.message"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import globalHeader from './components/common/header'
import globalFooter from './components/common/footer'
import globalLnb from './components/common/lnb'
import loading from './components/common/loading'
import login from './views/login'
import confirmPopup from './components/common/confirmPopup'
import './assets/scss/_index.scss'

export default {
  components: { globalHeader, globalFooter, globalLnb, loading, login, confirmPopup },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'common/isLoading',
      isLogin: 'session/isLogin',
      getMsgPopup: 'common/getMsgPopup',
    }),
    routerViewKey() {
      return this.$route.name + '-' + this.$route.params.type
    }
  },
  created() {
    console.log(process.env.NODE_ENV === 'development' ? "[개발]" : "[운영]")
    const token = localStorage.getItem('auth');
    this.authenicated(token)
    this.setJwtInfo(token)
    this.setCommonCode()
    this.setAgeList()
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      setSession: 'session/setSession',
      setPopupMsg: 'common/setPopupMsg',
      setCode: 'common/setCode',
      setAgeGroup: 'common/setAgeGroup',
    }),
    authenicated(token) {
      if (!token) this.$router.push('/login')
      else if (location.pathname === '/') this.$router.push('/dashboard/')
    },
    setJwtInfo(token) {
      if (token) {
        this.setSession({
          authToken: token,
          id: localStorage.getItem('id'),
          email: localStorage.getItem('email'),
          name: localStorage.getItem('name'),
          nickName: localStorage.getItem('nickName'),
          phone: localStorage.getItem('phone:'),
        })
      }
    },
    async setCommonCode() {
      const { result, data } = await this.$api.getCodeAll()
      if (result === 'success') await this.setCode(data)
    },
    async setAgeList() {
      const { result, list } = await this.$api.getAgeGroup()
      if (result === 'success') await this.setAgeGroup(list)
    },
  }
}
</script>