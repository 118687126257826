<template>
  <div class="popupBox-wrap scroll">
    <div class="popupPosition" @click="close"></div>

    <div class="popupBox w-1200">
      <div class="popupBox-title">상품 선택</div>
      <div class="popupBox-body">
        <div class="layout-contents-box">
          <table class="table-cols">
            <colgroup>
              <col width="180">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>상품명</th>
              <td>
                <div class="table-input"><input type="text" v-model="name" placeholder="검색어 입력" maxlength="30" @keypress.enter="search"></div>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="btn-box center mt-20">
            <div>
              <button ref="searchBtn" class="btn color-7" @click="search">검색</button>
            </div>
          </div>

          <div class="contents-box-title">
            <div></div>
            <div class="">
              <select v-model="pageSize" class="w-110">
                <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
              </select>
            </div>
          </div>

          <table class="table-rows">
            <colgroup>
              <col width="50">
              <col width="150">
              <col>
              <col width="80">
              <col width="120">
            </colgroup>
            <thead>
            <tr>
              <th><input type="checkbox" class="check-display-all" v-model="allChecked" @change="toggleAll"></th>
              <th>이미지</th>
              <th>상품명</th>
              <th>판매가</th>
              <th>등록일</th>
            </tr>
            </thead>
            <tbody v-if="this.list && this.list.length > 0">
            <tr v-for="item in this.list" :key="`list-${item.idx}`">
              <td><input type="checkbox" class="check-display-list" :value="item.idx" v-model="selectedIdxs" ></td>
              <td><image-alt v-if="item.img" :src="item.img" altType="table" /></td>
              <td class="left">{{ item.productName }}</td>
              <td>{{ item.price.numberFormat() }}</td>
              <td>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</td>
            </tr>
            </tbody>
            <tbody v-else><tr><td colspan="5">{{ $msg('nodata') }}</td></tr></tbody>
          </table>

          <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="movePage" />

          <div class="contents-box-title">선택 리스트</div>
          <table class="table-rows">
            <colgroup>
              <col width="50">
              <col width="150">
              <col>
              <col width="80">
              <col width="120">
            </colgroup>
            <thead>
            <tr>
              <th>-</th>
              <th>이미지</th>
              <th>상품명</th>
              <th>판매가</th>
              <th>등록일</th>
            </tr>
            </thead>
            <tbody v-if="this.selectList && this.selectList.length > 0">
            <tr v-for="item in this.selectList" :key="`select-list-${item.idx}`">
              <td><input type="checkbox" checked @click="remove(item.idx)"></td>
              <td><image-alt v-if="item.img" :src="item.img" altType="table" /></td>
              <td class="left">{{ item.productName }}</td>
              <td>{{ item.price.numberFormat() }}</td>
              <td>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</td>
            </tr>
            </tbody>
            <tbody v-else><tr><td colspan="5">{{ $msg('nodata.select') }}</td></tr></tbody>
          </table>
        </div>
      </div>

      <div class="popupBox-bottom">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">취소</button>
        <button class="w-90 mr-10 btn btn-sm color-1" @click="save">선택완료</button>
      </div>
    </div>
  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'
export default {
  props: ['cateCode', 'idxs'],
  mixins: [listMixin],
  data() {
    return {
      name: null,
      popupMsg: null,
      selectList: [],
      selectedIdxs: [],
      allChecked: false,
    }
  },
  watch: {
    pageSize() {
      this.page = 1
      this.getList()
    },
    selectedIdxs(v) {
      this.allChecked = v.length > 0 && v.length == this.list.length ? true : false
      this.setSelectList()
    }
  },
  created() {
    if (this.idxs) this.selectedIdxs = this.idxs
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        name: this.name,
        page: this.page,
        pageSize: this.pageSize,
      }
      const { result, list, total } = await this.$api.getProductList(params)
      if (result === 'success') {
        this.total = total
        this.list = list
        await this.setSelectList()
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    movePage() {
      this.$refs.searchBtn.focus()
      this.$el.querySelectorAll('.check-display-all')[0].checked = false
      this.getList()
    },
    toggleAll() {
      const checkbox = this.$el.querySelectorAll('.check-display-list')
      const allChecked = this.allChecked
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = allChecked
        let idx = Number(checkbox[i].value)
        if (!this.selectedIdxs.includes(idx)) this.selectedIdxs.push(idx)
        if (!allChecked) this.selectedIdxs = this.selectedIdxs.filter(i => i != idx)
        else if (!this.selectedIdxs.includes(idx)) this.selectedIdxs.push(idx)
      }
    },
    async setSelectList() {
      const { result, list } = await this.$api.getSelectedMainProductList({
        cateCode: this.cateCode,
        idxs: this.selectedIdxs
      })
      if (result === 'success') {
        this.selectList = list
      }
    },
    remove(id) {
      this.selectedIdxs = this.selectedIdxs.filter(i => i != id)
    },
    save() {
      const list = this.selectList
      if (list) list.sort((a, b) => (a.sorting == null) - (b.sorting == null) || a.sorting - b.sorting)
      this.$emit('save', list)
      this.close()
    },
    close() {
      this.$emit('close')
    },
  }
}
</script>
