import {mapActions} from "vuex"
export default {
  data() {
    return {
      list: [],
      popupMsg: null,
      isOpenPopup: {
        msg: false,
        remove: false,
        displayBoard: false,
        displayProduct: false,
      },
      selectedIdxs: [],
      allChecked: false,
    }
  },
  watch: {
    selectedIdxs(v) {
      this.allChecked = v.length > 0 && v.length == this.list.length ? true : false
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    toggleAll() {
      const checkbox = this.$el.querySelectorAll('.check-list')
      const allChecked = this.allChecked
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = allChecked
        let idx = Number(checkbox[i].value)
        if (!this.selectedIdxs.includes(idx)) this.selectedIdxs.push(idx)
        if (!allChecked) this.selectedIdxs = this.selectedIdxs.filter(i => i != idx)
        else if (!this.selectedIdxs.includes(idx)) this.selectedIdxs.push(idx)
      }
    },
    sort(move) {
      if (!this.selectedIdxs) return false
      let selectedList = this.list.filter(l => this.selectedIdxs.includes(l.idx))
      const vm = this

      function getSortList(newList, value, sort) {
        if (!newList) return []
        const index = vm.list.findIndex((l) => {
          if (l.idx == value.idx) {
            return true
          }
        })

        const newPosition = index + sort;
        if (newPosition < 0 || newPosition >= newList.length) {
          return []
        }

        const tempList = JSON.parse(JSON.stringify(newList))
        const target = tempList.splice(index, 1)[0]
        tempList.splice(newPosition, 0, target)
        return tempList
      }

      if (move == 'first') {
        this.list = selectedList.concat(this.list.filter(l => !this.selectedIdxs.includes(l.idx)))
      }
      else if (move == 'up') {
        let isFail = false
        let newList = this.list
        for (let value of selectedList) {
          newList = getSortList(newList, value, -1)
          if (newList.length == 0) isFail = true
        }
        if (!isFail) this.list = newList
      }
      else if (move == 'down') {
        let isFail = false
        let newList = this.list
        for (let value of selectedList.reverse()) {
          newList = getSortList(newList, value, 1)
          if (newList.length == 0) isFail = true
        }
        if (!isFail) this.list = newList
      }
      else if (move == 'last') {
        this.list = this.list.filter(l => !this.selectedIdxs.includes(l.idx)).concat(selectedList)
      }
    },
    readyRemove(msg) {
      if (this.selectedIdxs.length > 0) {
        this.confirmRemovePopup(msg)
      }
      else {
        this.alertPopup(this.$msg('no-data-selected'))
      }
    },
    remove() {
      if (!this.selectedIdxs) return false
      this.list = this.list.filter(l => !this.selectedIdxs.includes(l.idx))
      this.setMsgPopup()
      this.resetCheckBox()
    },
    resetCheckBox() {
      this.allChecked = false
      this.selectedIdxs = []
    },
    getIdxs() {
      if (this.list.length > 0) return this.list.map(l => l.idx)
      return []
    },
    openPopup(target) {
      if (target == 'displayBoard' && !this.board) return false
      document.body.style.overflowY = "hidden"
      this.isOpenPopup[target] = true
    },
    closePopup() {
      document.body.style.overflowY ="scroll"
      this.isOpenPopup.displayBoard = false
      this.isOpenPopup.displayProduct = false
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmRemovePopup(msg) {
      this.setMsgPopup({
        type: 'confirm',
        message: msg,
        okay: 'REMOVE',
        okayCallback: () => {
          this.setMsgPopup()
          this.remove()
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}