<template>
  <div>
    <div class="navi-bar">
      <span>상품</span>
      <span>상품 진열 관리</span>
      <span>추천상품 진열 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">추천상품 진열 관리</div>
      <div>
        <button @click="save" class="btn color-1">저장</button>
      </div>
    </div>


    <div class="layout-contents-box">
      <div class="contents-box-title">진열 게시물 설정</div>
      <table class="table-rows">
        <colgroup>
          <col width="50">
          <col width="100">
          <col width="150">
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>진열순서</th>
          <th>이미지</th>
          <th>상품명</th>
        </tr>
        </thead>

        <tbody v-if="this.list && this.list.length > 0">
        <tr v-for="(item, index) in this.list" :key="`list-${index}`">
          <td><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs"></td>
          <td>{{ item.sorting ? item.sorting : '-' }}</td>
          <td><image-alt v-if="item.img" :src="item.img" altType="table" /></td>
          <td class="left">{{ item.productName }}</td>
        </tr>
        </tbody>
        <tbody v-else><tr><td colspan="4" class="no-data">진열된 상품이 존재하지 않습니다.</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-10">
        <div>
          <button class="btn icon-itemArrow first" @click="sort('first')">맨위로</button>
          <button class="btn icon-itemArrow up" @click="sort('up')">위로</button>
          <button class="btn icon-itemArrow down" @click="sort('down')">아래로</button>
          <button class="btn icon-itemArrow last" @click="sort('last')">맨아래로</button>
          <button class="btn btn-sm btn-outline color-7" @click="readyRemove($msg('confirm.display-except.product'))">진열제외</button>
          <button class="btn btn-sm btn-outline color-7" @click="openPopup('displayProduct')">상품 선택</button>
        </div>
      </div>
    </div>

    <display-product-popup v-if="isOpenPopup.displayProduct" :idxs="getIdxs()" @save="displaySave" @close="closePopup" />
  </div>
</template>
<script>
import displayMixin from '@/mixins/displayMixin'
import displayProductPopup from '@/components/common/displayProductPopup'
export default {
  name: 'productDisplay',
  components: { displayProductPopup },
  mixins: [displayMixin],
  data() {
    return {
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { result, list } = await this.$api.getProductDisplayList()
      if (result === 'success') {
        this.list = list
        this.selectedIdxs = []
      }
    },
    async save() {
      const { result } = await this.$api.saveProductDisplay({ idxs: this.getIdxs() })
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'))
        this.getList().then()
      }
      else {
        this.alertPopup(this.$msg('fail'))
      }
    },
    displaySave(list) {
      this.list = list
    }
  }
}
</script>