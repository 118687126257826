<template>
  <div>
    <div v-if="isSingle" class="datepicker-box">
      <div class="input-group">
        <v-date-picker v-model="date" class="datepicker" @input="setDate" :is-required="isRequired">
          <template #default="{ inputValue, inputEvents }">
            <input type="text" :value="inputValue" v-on="inputEvents" readonly/>
          </template>
        </v-date-picker>
        <span class="icon-calendar-box"><span class="icon-calendar"></span></span>
      </div>
    </div>
    <div v-else class="datepicker-box">
      <div class="input-group">
        <v-date-picker v-model="startDate" class="datepicker" @input="setStartDate" :max-date="endDate" :is-required="isRequired">
          <template #default="{ inputValue, inputEvents }">
            <input type="text" :value="inputValue" v-on="inputEvents" readonly/>
          </template>
        </v-date-picker>
        <span class="icon-calendar-box"><span class="icon-calendar"></span></span>
      </div>
      <div class="px-5">~</div>
      <div class="input-group">
        <v-date-picker v-model="endDate" class="datepicker" @input="setEndDate" :min-date="startDate" :is-required="isRequired">
          <template #default="{ inputValue, inputEvents }">
            <input type="text" :value="inputValue" v-on="inputEvents" readonly/>
          </template>
        </v-date-picker>
        <span class="icon-calendar-box"><span class="icon-calendar"></span></span>
      </div>
      <div v-if="isYoutube" class="datepicker-btn-box">
        <div class="datepicker-btn" :class="{ on : dateBtn == 'thismonth' }" @click="clickDateBtn('thismonth')">이번달</div>
        <div class="datepicker-btn" :class="{ on : dateBtn == 'lastmonth' }" @click="clickDateBtn('lastmonth')">지난달</div>
        <div class="datepicker-btn" :class="{ on : dateBtn == 'thisyear' }" @click="clickDateBtn('thisyear')">올해</div>
        <div class="datepicker-btn" :class="{ on : dateBtn == 'lastyear' }" @click="clickDateBtn('lastyear')">작년</div>
        <div class="datepicker-btn" :class="{ on : dateBtn == 'all' }" @click="clickDateBtn('all')">전체</div>
      </div>
      <div v-else-if="!noBtn" class="datepicker-btn-box">
        <div class="datepicker-btn" :class="{ on : dateBtn == '0' }" @click="clickDateBtn(0)">오늘</div>
        <div class="datepicker-btn" :class="{ on : dateBtn == '-6' }" @click="clickDateBtn(-6)">7일</div>
        <div class="datepicker-btn" :class="{ on : dateBtn == '-14' }" @click="clickDateBtn(-14)">15일</div>
        <div class="datepicker-btn" :class="{ on : dateBtn == '-1m' }" @click="clickDateBtn('-1m')">1개월</div>
        <div class="datepicker-btn" :class="{ on : dateBtn == '-3m' }" @click="clickDateBtn('-3m')">3개월</div>
        <div class="datepicker-btn" :class="{ on : dateBtn == 'all' }" @click="clickDateBtn('all')">전체</div>
      </div>
    </div>
  </div>
</template>
<script>
  import VDatePicker from 'v-calendar/lib/components/date-picker.umd'
  export default {
    name: "date",
    components: { VDatePicker },
    props: {
      isSingle: {
        type: Boolean,
        default: false
      },
      noBtn: {
        type: Boolean,
        default: false
      },
      isYoutube: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        date: null,
        startDate: null,
        endDate: null,
        dateBtn: 'all',
        isRequired: false,
      }
    },
    methods: {
      setDate(date) {
        this.isRequired = true
        this.date = this.$getDateFormat(date, 'Y.m.d')
      },
      setStartDate(date) {
        this.startDate = this.$getDateFormat(date, 'Y.m.d')
        if (this.startDate && !this.endDate) this.endDate = this.startDate
        this.dateBtn = null
      },
      setEndDate(date) {
        this.endDate = this.$getDateFormat(date, 'Y.m.d')
        if (!this.startDate && this.endDate) this.startDate = this.endDate
        this.dateBtn = null
      },
      clickDateBtn(flag) {
        this.isRequired = true
        if (typeof flag === 'number') {
          this.startDate = new Date().$getDate(flag)
          this.endDate = new Date().$getDate(0)
        } else {
          const date = new Date()
          if (flag == '-1m') { // 1개월
            this.startDate = this.$getDateFormat(new Date(date.setMonth(date.getMonth() - 1)), 'Y.m.d')
            this.endDate = new Date().$getDate(0)
          }
          if (flag == '-3m') { // 3개월
            this.startDate = this.$getDateFormat(new Date(date.setMonth(date.getMonth() - 3)), 'Y.m.d')
            this.endDate = new Date().$getDate(0)
          }
          if (flag == 'thismonth') { // 이번달
            this.startDate = this.$getDateFormat(new Date(date.getFullYear(), date.getMonth(), 1), 'Y.m.d')
            this.endDate = this.$getDateFormat(new Date(date.getFullYear(), date.getMonth()+1, 0), 'Y.m.d')
          }
          if (flag == 'lastmonth') { // 지난달
            this.startDate = this.$getDateFormat(new Date(date.getFullYear(), date.getMonth()-1, 1), 'Y.m.d')
            this.endDate = this.$getDateFormat(new Date(date.getFullYear(), date.getMonth(), 0), 'Y.m.d')
          }
          if (flag == 'thisyear') { // 올해
            this.startDate = this.$getDateFormat(new Date(date.getFullYear(), 0, 1), 'Y.m.d')
            this.endDate = this.$getDateFormat(new Date(date.getFullYear()+1, 0, 0), 'Y.m.d')
          }
          if (flag == 'lastyear') { // 작년
            this.startDate = this.$getDateFormat(new Date(date.getFullYear()-1, 0, 1), 'Y.m.d')
            this.endDate = this.$getDateFormat(new Date(date.getFullYear(), 0, 0), 'Y.m.d')
          }
          if (flag == 'all') {
            this.isRequired = false
            this.startDate = null
            this.endDate = null
          }
        }
        setTimeout(() => {
          this.dateBtn = flag
        }, 50)
      },
    }
  }
</script>