<template>
  <div>
    <div class="navi-bar">
        <span>반려동물</span>
        <span>반려동물 관리</span>
        <span>반려동물 리스트</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">반려동물 리스트</div>
      <div>
        <button @click="goSave()" class="btn color-1">등록</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <table class="table-cols">
        <colgroup>
            <col width="180">
            <col>
        </colgroup>
        <tbody>
        <tr>
          <th>등록일</th>
          <td><date ref="date"/></td>
        </tr>
        <tr>
          <th>펫구분</th>
          <td>
            <label><input type="radio" name="petCode" value="" v-model="petCode">전체</label>
            <label><input type="radio" name="petCode" value="10" v-model="petCode">강아지</label>
            <label><input type="radio" name="petCode" value="20" v-model="petCode">고양이</label>
          </td>
        </tr>
        <tr>
          <th>중성화 여부</th>
          <td>
            <label><input type="radio" name="neuter" value="" v-model="neuter">전체</label>
            <label><input type="radio" name="neuter" value="0" v-model="neuter">중성화 전</label>
            <label><input type="radio" name="neuter" value="1" v-model="neuter">중성화 완료</label>
          </td>
        </tr>
        <tr>
          <th>알러지 여부</th>
          <td>
            <label><input type="radio" name="allergic" value="" v-model="allergic">전체</label>
            <label><input type="radio" name="allergic" value="1" v-model="allergic">있음</label>
            <label><input type="radio" name="allergic" value="0" v-model="allergic">없음</label>
          </td>
        </tr>
        <tr>
          <th>검색어</th>
          <td>
            <div class="d-flex">
              <div class="w-100 mr-10">
                <select v-model="target">
                  <option v-for="item in targets" :key="`target-${item.value}`" :value="item.value">{{ item.name }}</option>
                </select>
              </div>
              <div class="w-300">
                <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="btn-box center my-20">
          <div>
            <button class="btn color-7" @click="search">검색</button>
          </div>  
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
    </div>

      <table class="table-rows">
        <colgroup>
            <col width="50">
            <col width="80">
            <col width="80">
            <col width="80">
            <col width="120">
            <col width="80">
            <col width="80">
            <col width="150">
            <col width="90">
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>펫구분</th>
          <th>품종</th>
          <th>이름</th>
          <th>생년월일(나이)</th>
          <th>중성화 여부</th>
          <th>알러지 여부</th>
          <th>반려인ID</th>
          <th>등록일/수정일</th>
        </tr>
        </thead>
        <tbody v-if="list && list.length > 0">
          <tr v-for="item in list" :key="`list-${item.idx}`" @click="goSave(item.idx)" class="cursor-pointer">
            <td @click.stop><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
            <td>{{ item.petName }}</td>
            <td>{{ item.breedName }}</td>
            <td>{{ item.name }}</td>
            <td>{{ $YYYYMMDDWithFormat(item.birthday, '.', true) }}</td>
            <td>{{ item.neuter === 0 ? '중성화 전' : '중성화 완료' }}</td>
            <td>{{ item.allergic === 0 ? '없음' : '있음' }}</td>
            <td>{{ item.email }}</td>
            <td>
              <p>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</p>
              <p>{{ $getDateFormat(item.updateDate, 'Y-m-d') }}</p>
            </td>
          </tr>
        </tbody>
        <tbody v-else><tr><td colspan="11">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-20">
        <div>
            <button class="btn btn-outline color-7" @click="ready('remove')">선택 삭제</button>
        </div>  
      </div>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>

  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'
export default {
  mixins: [listMixin],
  data() {
    return {
      isActive:false,
      target: 1,
      targets: [
        { name: '품종', value: 1 },
        { name: '이름', value: 2 },
        { name: '반려인 ID', value: 3 },
      ],
      text: null,
      saveStatus: '',
      petCode: '',
      neuter: '',
      allergic: '',
    }
  },
  created() {
    if (this.$route.query.state) this.saveStatus = this.$route.query.state
    this.getCtgryList(10)
    this.getList()
  },
  methods: {
    async getList() {
      const { result, list, total } = await this.$api.getPetsList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        state: this.saveStatus,
        cateCode: this.cateCode,
        petCode: this.petCode,
        neuter: this.neuter,
        allergic: this.allergic,
        target: this.target,
        text: this.text,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
        this.selectedIdxs = []
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    async remove() {
      if (!this.selectedIdxs) return false
      const { result } = await this.$api.removePets({ idxs: this.selectedIdxs })
      if (result === 'success') {
        this.allChecked = false
        this.getList().then()
      }
    },
    goSave(idx=0) {
      if (idx) this.$router.push(`/pet/save?idx=${idx}&page=${this.page}`)
      else    this.$router.push(`/pet/save?&page=${this.page}`)
    },
    goWeb(idx) {
      window.open(`${this.$domain}/pet/${idx}`)
    },
  }
}
</script>