<template>
  <div>
    <div class="navi-bar"> 
        <span>운영관리</span>
        <span>운영자 관리</span>
        <span>운영자 리스트</span>
        <span>운영자 {{ this.$route.query.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">운영자 {{ this.$route.query.idx ? '수정' : '등록'}}</div>
      <div class="">
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="save" class="btn color-1">{{ this.$route.query.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">기본정보</div>
      <table class="table-cols">
        <colgroup>
          <col width="180">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th class="require">아이디</th>
          <td v-if="this.$route.query.idx">{{ id }}</td>
          <td v-else><input-field v-model="id" class="table-input" @focusout="chkId" :errorMessage="idMsg" maxLength="15"/></td>
        </tr>
        <tr>
          <th :class="{ 'require' : idx == 0 }">비밀번호</th>
          <td>
            <input-field v-model="password" type="password" class="table-input mr-5" :errorMessage="passwordMsg" placeholder="비밀번호 입력" maxLength="20"/>
            <input-field v-model="password2" type="password" class="table-input" :errorMessage="password2Msg" placeholder="비밀번호 확인" maxLength="20"/>
          </td>
        </tr>
        <tr>
          <th class="require">닉네임</th>
          <td>
            <input-field v-model="nickName" class="table-input" @focusout="chkNickname" :errorMessage="nickNameMsg" maxLength="15"/>
          </td>
        </tr>
        <tr>
          <th class="require">이름</th>
          <td>
            <input-field v-model="name" class="table-input" :errorMessage="nameMsg" maxLength="15"/>
          </td>
        </tr>
        <tr>
          <th class="require">휴대폰번호</th>
          <td>
            <input-field v-model="phone" class="table-input" :errorMessage="phoneMsg" numberOnly placeholder="10 ~ 11자리 숫자만 입력" maxLength="11" />
          </td>
        </tr>
        <tr>
          <th class="require">이메일</th>
          <td>
            <input-field v-model="email" class="table-input" @focusout="chkEmail" :errorMessage="emailMsg" placeholder="@를 포함한 이메일 주소를 입력해주세요." maxLength="100"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
export default {
  name: 'managerSave',
  data() {
    return {
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      id: '',
      password: '',
      password2: '',
      nickName: '',
      name: '',
      phone: '',
      email: '',
      idMsg: '',
      passwordMsg: '',
      password2Msg: '',
      nickNameMsg: '',
      nameMsg: '',
      phoneMsg: '',
      emailMsg: '',
      originId: '',
      originNickName: '',
      originPhone: '',
      originEmail: '',
    }
  },
  computed: {
    ...mapGetters({
      getId: 'session/getId',
    }),
    isLoginStatus() {
      return this.isLogin;
    },
  },
  watch: {
    id() { this.idMsg = '' },
    password() { this.passwordMsg = '' },
    password2() { this.password2Msg = '' },
    nickName() { this.nickNameMsg = '' },
    name() { this.nameMsg = '' },
    phone() { this.phoneMsg = '' },
    email() { this.emailMsg = '' },
  },
  created() {
    if (this.$route.query.idx) this.setItem()
  },
  methods: {
    ...mapActions({
      setSession: 'session/setSession',
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getManager({ idx: this.$route.query.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.id = data.id
        this.originId = data.id
        this.nickName = data.nickName
        this.originNickName = data.nickName
        this.name = data.name
        this.phone = data.phone
        this.originPhone = data.phone
        this.email = data.email
        this.originEmail = data.email
      }
    },
    async chkId() {
      if (!this.id || !this.id.patternCheck('id')) return false
      if (this.$route.query.idx && this.originId == this.id) return false
      const { result } = await this.$api.managerChkId({ id: this.id })
      switch (result) {
        case 'success':
          this.idMsg = ''
          break;
        case 'fail':
        case 'id-no-input':
        case 'id-check':
        case 'id-length-fail':
          this.idMsg = this.$msg('id.check')
          break;
        case 'id-duple':
          this.idMsg = this.$msg('id.exist')
          break;
        default:
          this.idMsg = this.$msg('id.check')
      }
    },
    async chkNickname() {
      if (!this.nickName || !this.nickName.patternCheck('nickName')) return false
      if (this.$route.query.idx && this.originNickName == this.nickName) return false
      const { result } = await this.$api.managerChkNickname({ nickName: this.nickName })
      switch (result) {
        case 'success':
          this.nickNameMsg = ''
          break;
        case 'fail':
        case 'nickname-no-input':
        case 'nickname-check':
        case 'nickname-length-fail':
          this.nickNameMsg = this.$msg('nickname.check')
          break;
        case 'nickname-duple':
          this.nickNameMsg = this.$msg('nickname.exist')
          break;
        default:
          this.nickNameMsg = this.$msg('nickname.check')
      }
    },
    async chkEmail() {
      if (!this.email || !this.email.patternCheck('email')) return false
      if (this.$route.query.idx && this.originEmail == this.email) return false
      const { result } = await this.$api.managerChkEmail({ email: this.email })
      switch (result) {
        case 'success':
          this.emailMsg = ''
          break;
        case 'fail':
        case 'email-no-input':
        case 'email-type-fail':
          this.emailMsg = this.$msg('email.check')
          break;
        case 'email-duple':
          this.emailMsg = this.$msg('email.exist')
          break;
        default:
          this.emailMsg = this.$msg('email.check')
      }
    },
    validate() {
      if (!this.id) {
        this.idMsg = this.$msg('required.input')
        return false
      }
      if (!this.id.patternCheck('id')) {
        this.idMsg = this.$msg('id.check')
        return false
      }
      if (!this.idx) {
        if (!this.password) {
          this.passwordMsg = this.$msg('required.input')
          return false
        }
        if (!this.password.patternCheck('password')) {
          this.passwordMsg = this.$msg('password.check')
          return false
        }
        if (this.password !== this.password2) {
          this.password2Msg = this.$msg('password.no-match')
          return false
        }
      }
      if (this.idx && this.password) {
        if (!this.password.patternCheck('password')) {
          this.passwordMsg = this.$msg('password.check')
          return false
        }
        if (this.password !== this.password2) {
          this.password2Msg = this.$msg('password.no-match')
          return false
        }
      }
      if (!this.nickName) {
        this.nickNameMsg = this.$msg('required.input')
        return false
      }
      if (!this.nickName.patternCheck('nickName')) {
        this.nickNameMsg = this.$msg('nickname.check')
        return false
      }
      if (!this.name) {
        this.nameMsg = this.$msg('required.input')
        return false
      }
      if (!this.name.patternCheck('name')) {
        this.nameMsg = this.$msg('name.check')
        return false
      }
      if (!this.phone) {
        this.phoneMsg = this.$msg('required.input')
        return false
      }
      if (!this.phone.patternCheck('phone')) {
        this.phoneMsg = this.$msg('phone.check')
        return false
      }
      if (!this.email) {
        this.emailMsg = this.$msg('required.input')
        return false
      }
      if (!this.email.patternCheck('email')) {
        this.emailMsg = this.$msg('email.check')
        return false
      }
      if (this.idMsg || this.passwordMsg || this.password2Msg || this.nickNameMsg
          || this.nameMsg || this.phoneMsg || this.emailMsg) {
        return false
      }
      return true
    },
    async save() {
      if (this.validate()) {
        const { result } = await this.$api.saveManager({
          idx: this.idx,
          id: this.id,
          password: this.password,
          nickName: this.nickName,
          name: this.name,
          phone: this.phone,
          email: this.email,
        })
        if (result === 'success') {
          if (this.getId === this.id) {
            localStorage.setItem('name', this.name)
            localStorage.setItem('nickName', this.nickName)
            this.setSession({
              name: localStorage.getItem('name'),
              nickName: localStorage.getItem('nickName'),
            })
          }
          this.alertPopup(this.$msg('ok.save'))
        }
        else {
          this.alertPopup(this.$msg('fail'))
        }
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          this.goList()
        },
      })
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/policy/manager/list${query}`)
    }
  }
}
</script>
