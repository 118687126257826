<template>
  <div>
    <div class="navi-bar"> 
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>꼬뮤니티</span>
      <span>{{ this.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">꼬뮤니티</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="setSaveParams" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">기본정보</div>
        <table class="table-cols">
          <colgroup>
            <col width="200">
            <col width="300">
            <col width="180">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th>아이디(이메일)</th>
            <td>{{ email }}</td>
            <th>닉네임</th>
            <td>{{ nickName }}</td>
          </tr>
          <tr>
            <th>등록일</th>
            <td>{{ createDate ? createDate : '-' }}</td>
            <th>수정일</th>
            <td>{{ updateDate ? createDate : '-' }}</td>
          </tr>
          </tbody>
        </table>

        <div class="contents-box-title">컨텐츠내용</div>
        <table class="table-cols" ref="table">
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">카테고리</th>
            <td><select-box :selectBoxClass="'w-150 mr-5'" :options="opt.cateCode" v-model="cateCode" :errorMessage="cateCodeMsg"/></td>
          </tr>
          <tr>
            <th class="require">제목</th>
            <td><input-field v-model="title" class="d-inline-block" :errorMessage="titleMsg" lengthCount maxLength="50"/></td>
          </tr>
          <tr>
            <th class="require">본문내용</th>
            <td><input-field multiline rows="10" maxLength="2000" v-model="content" :errorMessage="contentMsg"
                          :placeholder="`내용을 입력하세요.\n(최소 10자 ~ 최대 2,000자 입력 가능, 사진 최대 10장까지 첨부 가능)`"></input-field></td>
          </tr>
          <tr>
            <th>첨부 이미지</th>
            <td><upload-image-group :attach="attach" :newAttach="newAttach" @upload="addAttach" @removeNew="removeNewAttach" @remove="removeAttach"/></td>
          </tr>
          </tbody>
        </table>
      </div>
    </validateForm>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
export default {
  name: '',
  data() {
    return {
      opt: {
        cateCode: [],
      },
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      cateCode: null,
      petCode: null,
      title: null,
      content: null,
      writerType: 1,
      email: null,
      nickName: null,
      createDate: null,
      updateDate: null,
      attach: [],
      newAttach: [],
      cateCodeMsg: '',
      titleMsg: '',
      contentMsg: '',
    }
  },
  watch: {
    cateCode() {
      this.cateCodeMsg = ''
    },
    title() {
      this.titleMsg = ''
    },
    content() {
      this.contentMsg = ''
    },
  },
  computed: {
    ...mapGetters({
      getId: 'session/getId',
      getNickName: 'session/getNickName',
    }),
  },
  created() {
    this.getCtgryList(80)
    if (this.idx) this.setItem()
    this.email = this.getId
    this.nickName = this.getNickName
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getCtgryList(cateCode) {
      const { result, data } = await this.$api.getCtgryList({ cateCode: cateCode })
      if (result === 'success') {
        let ctgry = []
        for (const d of data) {
          let ctgry_ = {
            value: d.cateCode,
            text: d.categoryName,
          }
          ctgry.push(ctgry_)
        }
        ctgry.unshift({ value: null, text: '선택' })
        this.opt.cateCode = ctgry
      }
    },
    async setItem() {
      const { result, data, attach } = await this.$api.getGgomunity({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.cateCode = data.cateCode
        this.title = data.title
        this.content = data.content
        this.writerType = data.writerType
        this.email = data.email
        this.nickName = data.nickName
        this.createDate = data.createDate
        this.updateDate = data.updateDate
        this.attach = attach
      }
    },
    addAttach(data) {
      this.newAttach.push(data)
    },
    removeNewAttach(index) {
      this.newAttach.splice(index, 1);
    },
    removeAttach(atchIdx) {
      this.attach = this.attach.filter(a => a.atchIdx !== atchIdx)
    },
    validate() {
      if (!this.cateCode) {
        this.cateCodeMsg = this.$msg('required.select')
        return false
      }
      if (!this.title) {
        this.titleMsg = this.$msg('required.input')
        return false
      }
      if (!this.content) {
        this.contentMsg = this.$msg('required.input')
        return false
      }
      if (this.content.length < 10) {
        this.contentMsg = this.$msg('content.limit1')
        return false
      }
      if (this.content.length > 2001) {
        this.contentMsg = this.$msg('content.limit2')
        return false
      }
      return true
    },
    setSaveParams() {
      if (this.validate()) {
        const params = {
          idx: this.idx,
          cateCode: this.cateCode,
          title: this.title,
          content: this.content,
          attachFile: this.newAttach,
          writerId: this.email,
          writerName: this.nickName,
        }
        this.save(params)
      }
    },
    async save(params) {
      const { result } = await this.$api.saveGgomunity(params);
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'), `/board/ggomunity/list`)
      }
      else {
        this.alertPopup(this.$msg('fail'), null)
      }
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/board/ggomunity/list${query}`)
    },
    alertPopup(msg, movePath) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (movePath) this.$router.push(movePath)
        },
      })
    },
  }
}
</script>