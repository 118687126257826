const productTag = {
    namespaced: true,
    state: {
        removeProductTagEditorImgIds: [],
        openProductTagPopupWithImgId: null
    },
    mutations: {
        MUT_PUSH_REMOVE_PRODUCT_TAG_EDITOR_IMG_IDS(state, payload) {
            state.removeProductTagEditorImgIds.push(payload)
        },
        MUT_SET_OPEN_PRODUCT_TAG_POPUP_WITH_IMG_ID(state, payload) {
            state.openProductTagPopupWithImgId = payload
        }
    },
    actions: {
        setRemoveProductTagEditorImgIds({commit}, payload) {
            commit("MUT_PUSH_REMOVE_PRODUCT_TAG_EDITOR_IMG_IDS", payload);
        },
        setOpenProductTagPopupWithImgId({commit}, payload) {
            commit("MUT_SET_OPEN_PRODUCT_TAG_POPUP_WITH_IMG_ID", payload);
        },
    },
    getters: {
        getRemoveProductTagEditorImgIds: state => {
            return state.removeProductTagEditorImgIds
        },
        getOpenProductTagPopupWithImgId: state => {
            return state.openProductTagPopupWithImgId
        },
    },
}

export default productTag