<template>
  <div class="login-layout">
    <div class="w-360">
      <div class="logo mb-20 ta-center"><img src="@/assets/img/common/logo.png"></div>

      <validateForm ref="loginForm">
        <input-field v-model="id" :validate="rules.id" placeholder="아이디" maxLength="100"/>
        <input-field v-model="password" :validate="rules.password" placeholder="비밀번호" type="password" maxLength="20" class="mt-10"/>
        <div v-if="msg" class="error-message">{{ msg }}</div>

        <div class="mt-15" @click="login">
          <button class="btn btn-x-large color-1 btn-block">로그인</button>
        </div>
      </validateForm>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  data() {
    return {
      id: null,
      password: null,
      rules: {
        id: [v => !!v || this.$msg('required.input')],
        password: [v => !!v || this.$msg('required.input')],
      },
      msg: null,
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async login() {
      this.msg = null
      if (this.$refs.loginForm.validate()) {
        const { result, data } = await this.$api.login({
          id: this.id,
          password: this.password
        })
        if (result === 'success') {
          const info = this.$parseJwt(data.token)
          if (info) {
            localStorage.setItem('auth', data.token)
            localStorage.setItem('id', info.id)
            localStorage.setItem('email', info.email)
            localStorage.setItem('name', info.name)
            localStorage.setItem('nickName', info.nickName)
            localStorage.setItem('phone', info.phone)
          }
          location.href = '/dashboard/'
        }
        else {
          this.alertPopup(this.$msg('login.fail'))
        }
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}
</script>
