<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 진열 관리</span>
      <span>메인 분류 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">메인 분류 관리</div>
    </div>


    <div class="layout-contents-box">
      <table class="table-rows">
        <colgroup>
          <col width="180">
          <col>
          <col>
          <col width="100">
        </colgroup>
        <thead>
        <tr>
          <th>게시판</th>
          <th>메인 분류명</th>
          <th>메인 서브문구</th>
          <th>관리</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>랜선집사</td>
            <td class="left"><input-field v-model="mdText" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요."
                                          :errorMessage="mdTextMsg" @keypress.enter="save('md')"/></td>
            <td class="left"><input-field v-model="mdSubText" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요."
                                          @keypress.enter="save('md')"/></td>
            <td><button class="btn btn-x-sm color-6" @click="save('md')">수정</button></td>
          </tr>
          <tr>
            <td>연구일지</td>
            <td class="left"><input-field v-model="labText" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요."
                                          :errorMessage="labTextMsg" @keypress.enter="save('lab')"/></td>
            <td class="left"><input-field v-model="labSubText" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요."
                                          @keypress.enter="save('lab')"/></td>
            <td><button class="btn btn-x-sm color-6" @click="save('lab')">수정</button></td>
          </tr>
          <tr>
            <td>꼬리TIP</td>
            <td class="left"><input-field v-model="tipText" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요."
                                          :errorMessage="tipTextMsg" @keypress.enter="save('tip')"/></td>
            <td class="left"><input-field v-model="tipSubText" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요."
                                          @keypress.enter="save('tip')"/></td>
            <td><button class="btn btn-x-sm color-6" @click="save('tip')">수정</button></td>
          </tr>
          <tr>
            <td>MD추천.1</td>
            <td class="left"><input-field v-model="recommendText" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요."
                                          :errorMessage="recommendTextMsg" @keypress.enter="save('recommend')"/></td>
            <td class="left"><input-field v-model="recommendSubText" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요."
                                          @keypress.enter="save('recommend')"/></td>
            <td><button class="btn btn-x-sm color-6" @click="save('recommend')">수정</button></td>
          </tr>
          <tr>
            <td>MD추천.2</td>
            <td class="left"><input-field v-model="recommend2Text" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요."
                                          :errorMessage="recommend2TextMsg" @keypress.enter="save('recommend2')"/></td>
            <td class="left"><input-field v-model="recommend2SubText" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요."
                                          @keypress.enter="save('recommend2')"/></td>
            <td><button class="btn btn-x-sm color-6" @click="save('recommend2')">수정</button></td>
          </tr>
          <tr>
            <td>MD추천.3</td>
            <td class="left"><input-field v-model="recommend3Text" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요."
                                          :errorMessage="recommend3TextMsg" @keypress.enter="save('recommend3')"/></td>
            <td class="left"><input-field v-model="recommend3SubText" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요."
                                          @keypress.enter="save('recommend3')"/></td>
            <td><button class="btn btn-x-sm color-6" @click="save('recommend3')">수정</button></td>
          </tr>
          <tr>
            <td>관심 키워드 추천 컨텐츠</td>
            <td class="left"><input-field v-model="keywordText" class="w-100p" maxLength="30" placeholder="분류명을 입력해주세요."
                                          :errorMessage="keywordTextMsg" @keypress.enter="save('keyword')"/></td>
            <td class="left"><input-field v-model="keywordSubText" class="w-100p" maxLength="200" placeholder="서브문구를 입력해주세요."
                                          @keypress.enter="save('keyword')"/></td>
            <td><button class="btn btn-x-sm color-6" @click="save('keyword')">수정</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex"
export default {
  name: 'mainManage',
  data() {
    return {
      tipText: null,
      tipSubText: null,
      labText: null,
      labSubText: null,
      mdText: null,
      mdSubText: null,
      recommendText: null,
      recommendSubText: null,
      recommend2Text: null,
      recommend2SubText: null,
      recommend3Text: null,
      recommend3SubText: null,
      keywordText: null,
      keywordSubText: null,
      tipTextMsg: '',
      labTextMsg: '',
      mdTextMsg: '',
      recommendTextMsg: '',
      recommend2TextMsg: '',
      recommend3TextMsg: '',
      keywordTextMsg: '',
    }
  },
  watch: {
    tipText() { this.tipTextMsg = '' },
    labText() { this.labTextMsg = '' },
    mdText() { this.mdTextMsg = '' },
    recommendText() { this.recommendTextMsg = '' },
    recommend2Text() { this.recommend2TextMsg = '' },
    recommend3Text() { this.recommend3TextMsg = '' },
    keywordText() { this.keywordTextMsg = '' },
  },
  created() {
    this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getMainManage()
      if (result === 'success') {
        this.tipText = data.tip.text
        this.tipSubText = data.tip.subText
        this.labText = data.lab.text
        this.labSubText = data.lab.subText
        this.mdText = data.md.text
        this.mdSubText = data.md.subText
        this.recommendText = data.recommend.text
        this.recommendSubText = data.recommend.subText
        this.recommend2Text = data.recommend2.text
        this.recommend2SubText = data.recommend2.subText
        this.recommend3Text = data.recommend3.text
        this.recommend3SubText = data.recommend3.subText
        this.keywordText = data.keyword.text
        this.keywordSubText = data.keyword.subText
      }
    },
    validate(board) {
      if (!this[`${board}Text`]) {
        this[`${board}TextMsg`] = this.$msg('required.input')
        return false
      }
      return true
    },
    async save(board) {
      if (this.validate(board)) {
        const { result } = await this.$api.saveMainManage({
          board: board,
          text: this[`${board}Text`],
          subText: this[`${board}SubText`],
        })
        this.alertPopup(result === 'success' ? this.$msg('ok.save') : this.$msg('fail'))
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}
</script>