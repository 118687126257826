import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

import Component from './Component'

export default Node.create({
  name: 'image',
  group: 'inline',
  inline: true,
  selectable: true,
  draggable: true,
  atom: true,

  addAttributes() {
    return {
      id: {},
      src: {},
      alt: {
        default: null
      },
      title: {
        default: null,
      },
      tagId: {
        default: null,
      },
      tagTop: {
        default: null,
      },
      tagLeft: {
        default: null,
      },
      tagColor: {
        default: null,
      },
      tagTitle: {
        default: null,
      },
      tagPrice: {
        default: null,
      },
      tagUrl: {
        default: null,
      },
      tagSrc: {
        default: null,
      },
      tagPosition: {
        default: null,
      },
    }
  },
  parseHTML: [{
    tag: 'img.editorImage',
    getAttrs: dom => ({
      id: dom.getAttribute('id'),
      src: dom.getAttribute('src'),
      title: dom.getAttribute('title'),
      alt: dom.getAttribute('alt'),
      tagId: dom.getAttribute('tagId'),
      tagTop: dom.getAttribute('tagTop'),
      tagLeft: dom.getAttribute('tagLeft'),
      tagColor: dom.getAttribute('tagColor'),
      tagTitle: dom.getAttribute('tagTitle'),
      tagPrice: dom.getAttribute('tagPrice'),
      tagUrl: dom.getAttribute('tagUrl'),
      tagSrc: dom.getAttribute('tagSrc'),
      tagPosition: dom.getAttribute('tagPosition'),
    })
  }, 0],
  renderHTML({ HTMLAttributes }) {
    let imgDiv = document.createElement('div')
    imgDiv.className = 'content-img-box'

    let img = document.createElement('img')
    img.setAttribute('id', HTMLAttributes.id)
    img.setAttribute('class', 'editorImage')
    img.setAttribute('src', HTMLAttributes.src)
    img.setAttribute('alt', HTMLAttributes.alt)
    img.setAttribute('title', HTMLAttributes.title)
    img.setAttribute('tagId', HTMLAttributes.tagId)
    img.setAttribute('tagTop', HTMLAttributes.tagTop)
    img.setAttribute('tagLeft', HTMLAttributes.tagLeft)
    img.setAttribute('tagColor', HTMLAttributes.tagColor)
    img.setAttribute('tagTitle', HTMLAttributes.tagTitle)
    img.setAttribute('tagPrice', HTMLAttributes.tagPrice)
    img.setAttribute('tagUrl', HTMLAttributes.tagUrl)
    img.setAttribute('tagSrc', HTMLAttributes.tagSrc)
    img.setAttribute('tagPosition', HTMLAttributes.tagPosition)

    imgDiv.appendChild(img)

    if (HTMLAttributes.tagId > 0) {
      let tagDiv = document.createElement('div')
      tagDiv.style.left = `${HTMLAttributes.tagLeft}%`
      tagDiv.style.top = `${HTMLAttributes.tagTop}%`
      tagDiv.style.position = 'absolute'
      tagDiv.setAttribute('class', 'tag-div')

      let iconDiv = document.createElement('div')
      iconDiv.className = `tag-icon ${HTMLAttributes.tagColor}`
      iconDiv.style.top = '-10px'

      let dotDiv = document.createElement('div')
      dotDiv.className = `dot`

      let boxA = document.createElement('a')
      boxA.setAttribute('href', HTMLAttributes.tagUrl)
      boxA.setAttribute('target', '_black')
      boxA.className = `tag-product-box cursor-pointer ${HTMLAttributes.tagPosition}`
      boxA.style.display = 'none'

      let tagImgDiv = document.createElement('div')
      tagImgDiv.className = `product-img`
      let img = document.createElement('img')
      img.setAttribute('src', HTMLAttributes.tagSrc)

      let infoDiv = document.createElement('div')
      infoDiv.className = `product-info`

      let title = document.createElement('strong')
      title.className = 'tag-title'
      title.setAttribute('data-content', HTMLAttributes.tagTitle)

      let price = document.createElement('span')
      price.className = 'tag-price'
      price.setAttribute('data-content', `${Number(HTMLAttributes.tagPrice).numberFormat()}원`)

      imgDiv.appendChild(tagDiv)
      tagDiv.appendChild(iconDiv)

      iconDiv.appendChild(dotDiv)
      iconDiv.appendChild(boxA)

      boxA.appendChild(tagImgDiv)
      tagImgDiv.appendChild(img)

      boxA.appendChild(infoDiv)
      infoDiv.appendChild(title)
      infoDiv.appendChild(price)
    }

    return ['div', imgDiv]
    // return ['div', mergeAttributes(HTMLAttributes)]
  },
  addCommands() {
    return {
      // setImage: options => ({ commands }) => {
      //   console.log("> options : ", options)
      //   console.log("> commands : ", commands)
      //   console.log("> this.name : ", this.name)
      //   return commands.insertContent({
      //     type: this.name,
      //     attrs: options,
      //   })
      // }
      setImage: options => ({ state, dispatch, commands }) => {
        const { selection, schema } = state
        const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
        const div = schema.nodes.paragraph.create()
        const node = this.type.create(options)
        const transaction = state.tr.insert(position+1, node).insert(position+3, div)
        dispatch(transaction)
      },
      updateTagInfo: options => ({ tr }) => {
        const { selection } = tr
        tr.doc.descendants((node) => {
          tr.setNodeMarkup(selection.from, this.type, {
            ...node.attrs,
            ...options
          })
        })
        return true
      },
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(Component)
  },
})