<template>
  <li v-if="depth === 1" :index="index" :key="`${depth}-${index}`">
    <div @click="toggleActive(isActive)"
         :class="[{ 'active': isActive }, { 'noUse': item.useYN === 'N' }]">
      <font-awesome-icon v-if="!isFolder" icon="fas fa-bars"/>
      <font-awesome-icon v-else-if="isOpen" icon="fas fa-angle-up" @click.stop="toggleOpen"/>
      <font-awesome-icon v-else icon="fas fa-angle-down" @click.stop="toggleOpen"/>
      <span> {{ item.codeName }} ({{ item.code }})</span>
    </div>
    <ul v-if="isOpen" class="second-depth">
      <tree-node
        v-for="(item2,index2) in item.children"
        :depth="increaseDepth"
        :item="item2"
        :key="item2.code"
        :index="index2"
        :propDisabled="isDisabled"
      />
    </ul>
  </li>
  <li v-else-if="depth === 2" :index="index" :key="`${depth}-${index}`"
      :class="[{ 'active': isActive }, { 'noUse': item.useYN === 'N' }]"
      @click="toggleActive(isActive)">
    <span>ㄴ {{ item.codeName }} ({{ item.code }})</span>
    <font-awesome-icon :icon="['fas', 'fa-pen']" @click.stop="setModify" />
  </li>
</template>

<script>
let nodeClicked = undefined;
let rootTree = null;
import { findRoot } from "./utils";
export default {
  name: "treeNode",
  data() {
    return {
      isOpen: false,
      isClicked: false,
      isActive: false,
    }
  },
  props: {
    item: Object,
    depth: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    propDisabled: {
      type: Boolean,
      default: false,
    }
  },
    computed: {
      isFolder() {
        return this.item.children && this.item.children.length
      },
      increaseDepth() {
        return this.depth + 1
      },
      isDisabled() {
        return this.item.useYN === 'N' || this.propDisabled
      },
    },
    created() {
      rootTree = findRoot(this)
    },
    methods: {
      toggleOpen() {
        if (this.isFolder) this.isOpen = !this.isOpen
        this.isClicked = !this.isClicked

        if (nodeClicked != this.item.code) {
          let treeParent = rootTree.$parent
          let nodeStack = [treeParent.$children[0]]
          while (nodeStack.length != 0) {
            let item = nodeStack.shift()
            item.isClicked = false
            if (item.$children && item.$children.length > 0) {
              nodeStack = nodeStack.concat(item.$children)
            }
          }
          this.isClicked = true
          nodeClicked = this.item.code
        }
      },
      toggleActive(is) {
        if (this.item.useYN === 'N') return false
        rootTree.emitNodeClicked(this.item, this);
        rootTree.unActive(this)
        this.isActive = !is
        if (this.item.codeLev === 1) {
          rootTree.closeAll()
          this.toggleOpen()
        }
      },
      setModify() {
        rootTree.emitSetModify(this.item)
        this.isActive = false
      },
    },
};
</script>