<template>
  <div>
    <div class="navi-bar"> 
        <span>게시판</span>
        <span>게시물 관리</span>
        <span>꼬뮤니티</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">꼬뮤니티</div>
      <div>
          <div class="btn btn-outline color-6" @click="openCtgryPopup">카테고리 설정</div>
          <button @click="goSave()" class="btn color-1">등록</button>
      </div>
    </div>

    <ctgry-popup v-if="isOpenCtgryPopup" cateCode="80" @close="closeCtgryPopup"/>

    <div class="layout-contents-box">
      <table class="table-cols">
        <colgroup>
            <col width="180">
            <col>
        </colgroup>
        <tbody>
        <tr>
          <th>등록일</th>
          <td><date ref="date"/></td>
        </tr>
        <tr>
          <th>카테고리</th>
          <td>
            <label><input type="radio" name="cateCode" value="" v-model="cateCode">전체</label>
            <label v-for="item in ctgryList" :key="`ctgry-${item.cateCode}`"><input type="radio" name="cateCode" :value="item.cateCode" v-model="cateCode">{{ item.categoryName }}</label>
          </td>
        </tr>
        <tr>
          <th>전시상태</th>
          <td>
            <label><input type="radio" name="blindYN" value="" v-model="blindYN">전체</label>
            <label v-for="item in blinds" :key="`blind-${item.value}`"><input type="radio" name="blindYN" :value="item.value" v-model="blindYN">{{ item.text }}</label>
          </td>
        </tr>
        <tr>
          <th>검색어</th>
          <td>
            <div class="d-flex">
              <div class="w-100 mr-10">
                <select v-model="target">
                  <option v-for="item in targets" :key="`target-${item.value}`" :value="item.value">{{ item.text }}</option>
                </select>
              </div>
              <div class="w-300">
                <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="btn-box center my-20">
          <div>
              <button class="btn color-7" @click="search">검색</button>
          </div>  
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>

      <table class="table-rows">
        <colgroup>
            <col width="50">
            <col width="100">
            <col width="100">
            <col width="380">
            <col width="200">
            <col width="75">
            <col width="75">
            <col width="75">
            <col width="120"> 
        </colgroup>
        <thead>
        <tr>
          <th><input type="checkbox" v-model="allChecked" @change="toggleAll"></th>
          <th>전시상태</th>
          <th>카테고리</th>
          <th>제목</th>
          <th>아이디/닉네임</th>
          <th>조회수</th>
          <th>추천</th>
          <th>댓글</th>
          <th>등록일/수정일</th>
        </tr>
        </thead>
        <tbody v-if="list.length > 0">
          <tr v-for="(item,index) in list" :key="index" @click="goSave(item.idx)">
            <td @click.stop><input type="checkbox" class="check-list" :value="item.idx" v-model="selectedIdxs" ></td>
            <td>{{ item.blindYN ? '블라인드' : '정상' }}</td>
            <td>{{ item.categoryName }}</td>
            <td class="left">{{ item.title }}<button @click.stop="goWeb(item.idx)" class="icon-link"></button></td>
            <td class="left">
              <p>{{ item.email }}</p>
              <p>{{ item.nickName }}</p>
            </td>
            <td>{{ item.view.numberFormat() }}</td>
            <td>{{ item.likeCnt.numberFormat() }}</td>
            <td>{{ item.comment.numberFormat() }}</td>
            <td>
              <p>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</p>
              <p>{{ $getDateFormat(item.updateDate, 'Y-m-d') }}</p>
            </td>
          </tr>
        </tbody>
        <tbody v-else><tr><td colspan="9">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <div class="btn-box flex-between-center my-20">
        <div>
            <button class="btn btn-outline color-7" @click="readyRemove">선택 삭제</button>
        </div>  
      </div>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>

  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'
export default {
  mixins: [listMixin],
  data() {
    return {
      target: 1,
      targets: [
        { text: '제목', value: 1 },
        { text: '이메일(아이디)', value: 2 },
        { text: '닉네임', value: 3 },
      ],
      text: null,
      blindYN: '',
      blinds: [
        { text: '정상', value: 0 },
        { text: '블라인드', value: 1 },
      ],
      ctgryList: null,
      cateCode: '',
      isOpenCtgryPopup: false,
    }
  },
  created() {
    this.getCtgryList(80)
    this.getList()
  },
  methods: {
    async getList() {
      const { result, list, total } = await this.$api.getGgomunityList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        cateCode: this.cateCode,
        target: this.target,
        text: this.text,
        blindYN: this.blindYN,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
        this.selectedIdxs = []
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    readyRemove() {
      if (this.selectedIdxs.length > 0) {
        this.confirmRemovePopup()
      }
      else {
        this.alertPopup(this.$msg('no-data-selected'))
      }
    },
    async remove() {
      if (!this.selectedIdxs) return false
      const { result } = await this.$api.boardRemove({ idxs: this.selectedIdxs });
      if (result === 'success') {
        this.allChecked = false
        this.getList().then()
      }
    },
    openCtgryPopup() {
      this.isOpenCtgryPopup = true
    },
    closeCtgryPopup() {
      this.getCtgryList(80)
      this.isOpenCtgryPopup = false
    },
    goSave(idx=0) {
      if (idx) this.$router.push(`/board/ggomunity/save?idx=${idx}&page=${this.page}`)
      else    this.$router.push(`/board/ggomunity/save?&page=${this.page}`)
    },
    goWeb(idx) {
      window.open(`${this.$domain}/ggomunity/${idx}`)
    },
  }
}
</script>