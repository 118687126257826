<template>
  <div>
    <div class="navi-bar"> 
        <span>운영관리</span>
        <span>기본정책</span>
        <span>기본정보 설정</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">기본 정보 설정</div>
      <div class=""><button @click="save" class="btn color-1">저장</button></div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">회사정보</div>
        <table class="table-cols">
          <colgroup>
            <col width="180">
            <col width="300">
            <col width="180">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">사이트명</th>
            <td><input-field v-model="siteName" :validate="rules.siteName" class="table-input" maxLength="50"></input-field></td>
            <th class="require">사업자명</th>
            <td><input-field v-model="comName" :validate="rules.comName" class="table-input" maxLength="50"></input-field></td>
          </tr>
          <tr>
            <th class="require">사업자등록번호</th>
            <td><input-field v-model="bizNum" :validate="rules.bizNum" class="table-input" numberOnly maxLength="10"></input-field></td>
            <th class="require">통신판매업 신고 번호</th>
            <td><input-field v-model="comSaleNum" :validate="rules.comSaleNum" class="table-input" maxLength="100"></input-field></td>
          </tr>
          <tr>
            <th class="require">대표자명</th>
            <td colspan="3"><input-field v-model="ceoName" :validate="rules.ceoName" class="table-input" maxLength="30"></input-field></td>
          </tr>
          <tr>
            <th class="require">주소</th>
            <td colspan="3"><input-field v-model="addr" :validate="rules.addr" class="table-input2" maxLength="100"></input-field></td>
          </tr>
          <tr>
            <th class="require">대표전화</th>
            <td><input-field v-model="comTel" :validate="rules.comTel" class="table-input" maxLength="30"></input-field></td>
            <th class="require">대표 이메일</th>
            <td><input-field v-model="comEmail" :validate="rules.comEmail" class="table-input" maxLength="100"></input-field></td>
          </tr>
          <tr>
            <th>영업시간</th>
            <td colspan="3"><input-field multiline rows="4" v-model="bizHour" class="block table-input2" maxLength="100"></input-field></td>
          </tr>
          </tbody>
        </table>
      </div>
    </validateForm>
  </div>
</template>
<script>
import {mapActions} from "vuex"

export default {
  name: 'baseInfo',
  data() {
    return {
      siteName: null,
      comName: null,
      bizNum: null,
      bizNumMsg: '',
      comSaleNum: null,
      ceoName: null,
      comTel: null,
      comEmail: null,
      addr: null,
      bizHour: null,
      rules: {
        siteName: [v => !!v || this.$msg('required.input')],
        comName: [v => !!v || this.$msg('required.input')],
        bizNum: [v => !!v || this.$msg('required.input')],
        comSaleNum: [v => !!v || this.$msg('required.input')],
        ceoName: [v => !!v || this.$msg('required.input')],
        comTel: [v => !!v || this.$msg('required.input')],
        comEmail: [v => !!v || this.$msg('required.input')],
        addr: [v => !!v || this.$msg('required.input')],
      },
    }
  },
  created() {
    this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getCompanyInfo()
      if (result === 'success') {
        this.siteName = data.siteName
        this.comName = data.comName
        this.bizNum = data.bizNum
        this.comSaleNum = data.comSaleNum
        this.ceoName = data.ceoName
        this.comTel = data.comTel
        this.comEmail = data.comEmail
        this.addr = data.addr
        this.bizHour = data.bizHour
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.setCompanyInfo({
          siteName: this.siteName,
          comName: this.comName,
          bizNum: this.bizNum,
          comSaleNum: this.comSaleNum,
          ceoName: this.ceoName,
          comTel: this.comTel,
          comEmail: this.comEmail,
          addr: this.addr,
          bizHour: this.bizHour,
        })
        this.alertPopup(result === 'success' ? this.$msg('ok.save') : this.$msg('fail'))
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}
</script>
