<template>
  <div>
    <div class="file-box w-400">
      <label>
        <button class="btn btn-x-sm color-6 btn-round0" @click="$refs.uploadImage.click()">이미지 선택</button>
      </label>
      <input type="text" :placeholder="name" disabled="" class="">
      <input type="file" accept="image/gif, image/jpg, image/jpeg, image/png" ref="uploadImage" @change="uploadImage($event)" style="display:none;">
    </div>

    <div v-if="isNoSizeMsg" class="desc-message color1"></div>
    <div v-else-if="isProduct" class="desc-message color1">*상품 이미지 권장 사이즈 : 550 X 550</div>
    <div v-else-if="isProfile" class="desc-message color1">*권장 사이즈 : 500 X 550</div>
    <div v-else class="desc-message color1">*대표 이미지 권장 사이즈 : 800 X 550</div>
    <image-alt v-if="src" class="file-box-preview" :src="src" altType="list" />

    <div v-if="msg" class="error-message">{{ msg }}</div>
  </div>
</template>

<script>
import Compressor from 'compressorjs'
import ImageAlt from "./imageAlt"

export default {
  name: 'uploadImage',
  components: {ImageAlt},
  props: ['imgSrc', 'imgName', 'isProduct', 'isNoSizeMsg', 'isProfile'],
  data() {
    return {
      file: null,
      name: this.imgName,
      src: this.imgSrc,
      msg: null,
    }
  },
  watch: {
    imgSrc(v) {
      this.src = v
    },
    imgName(v) {
      this.name = v
    }
  },
  methods: {
    uploadImage(event) {
      if (!event) return false
      event.stopPropagation()
      event.preventDefault()

      let result = false
      this.msg = null
      let files = event.target.files || event.dataTransfer.files
      if (files.length == 0) {
        this.msg = this.$msg('required.input')
      }
      if (files.length > 0) {
        for (let file of files) {
          if (!/^image\//.test(file.type)) {
            this.msg = this.$msg('image.ext')
            return false
          }

          let fileExt = this.$getExts(file.name, false)
          let isImg = this.$isImages(fileExt);
          if (isImg) {
            if (file.size < this.$MBtoB(10)) { // 10MB
              result = true;
            } else {
              this.msg = this.$msg('image.limit')
            }
          }

          if (result) {
            let vm = this

            const img = new Image()
            img.src = URL.createObjectURL(file)

            img.onload = () => {
              const isCompressor = fileExt === 'gif' || !(img.width > vm.$imageMaxWidth || img.height > vm.imageMaxHeight) ? false : true

              if (isCompressor) {
                const options = {
                  maxWidth: vm.$imageMaxWidth,
                  maxHeight: vm.imageMaxHeight,
                  success: (_file) => {
                    if (_file.size > vm.$MBtoB(10)) { // 리사이징 했는데도 용량이 큰 경우
                      vm.alertPopup('image.limit')
                      return false
                    }
                    let reader = new FileReader()
                    reader.onload = () => {
                      vm.file = reader.result
                      vm.name = _file.name
                      vm.src = URL.createObjectURL(_file)

                      if (vm.isProduct) {
                        vm.$emit('upload', vm.file, vm.name, vm.src)
                      }
                      else {
                        vm.$emit('upload', vm.file, vm.name)
                      }
                      event.target.value = ''
                    }
                    reader.readAsDataURL(_file)
                  },
                  error: (err) => console.log(err)
                }
                new Compressor(file, options)
              }
              else {
                let reader = new FileReader()
                reader.onload = function () {
                  vm.file = reader.result
                  vm.name = file.name
                  vm.src = URL.createObjectURL(file)

                  if (vm.isProduct) {
                    vm.$emit('upload', vm.file, vm.name, vm.src)
                  }
                  else {
                    vm.$emit('upload', vm.file, vm.name)
                  }
                  event.target.value = ''
                }
                reader.readAsDataURL(file)
              }
            }
          }

        }
      }
    },
  }
}
</script>