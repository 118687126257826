<template>
  <div>
    <div class="navi-bar"> 
      <span>반려동물</span>
      <span>반려동물 관리</span>
      <span>반려동물 리스트</span>
      <span>{{ this.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">반려동물 등록</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="save" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">기본정보</div>
        <table class="table-cols">
          <colgroup>
            <col width="200">
            <col width="450">
            <col width="200">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">반려인 ID</th>
            <td colspan="3">
              <input-field class="w-20p d-inline-block" v-model="memEmail" :validate="rules.input" placeholder="ID 선택" disabled />
              <button @click="openMemberPopup" class="btn btn-x-sm w-70 color-9 d-inline-block">선택</button>
            </td>
          </tr>
          <pet-member-popup v-if="isOpenMemberPopup" :memIdx="memIdx" @set="setMember" @close="closeMemberPopup" />
          <tr>
            <th>등록일</th>
            <td>{{ createDate }}</td>
            <th>수정일</th>
            <td>{{ updateDate }}</td>
          </tr>
          </tbody>
        </table>

        <div class="contents-box-title">반려동물 정보</div>
        <table class="table-cols" ref="table">
          <colgroup>
            <col width="200">
            <col width="450">
            <col width="200">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th>프로필 이미지</th>
            <td colspan="3"><upload-image @upload="setImg" :imgSrc="profileSrc" :imgName="imgName" :isProfile="true"/></td>
          </tr>
          <tr>
            <th>펫구분</th>
            <td>
                <label><input type="radio" name="petCode" value="10" v-model="petCode">강아지</label>
                <label><input type="radio" name="petCode" value="20" v-model="petCode">고양이</label>
            </td>
            <th class="require">품종</th>
            <td>
              <div class="d-flex">
                <select-box selectBoxClass="w-200 mr-5" :options="opt.breed" v-model="breedIdx" :validate="rules.select" :placeholder="'선택'"/>
                <input-field v-if="breedIdx === 'etc'" v-model="breedName" :validate="rules.input" placeholder="직접입력" maxLength="15" />
              </div>
            </td>
          </tr>
          <tr>
            <th class="require">이름</th>
            <td><input-field v-model="name" :validate="rules.name" placeholder="반려동물 이름 입력" maxLength="15" /></td>
            <th class="require">생년월일</th>
            <td>
                <div class="d-flex">
                    <input-field class="w-30p" v-model="birthday" :validate="rules.input" placeholder="숫자 8자리 입력 (예: 20220101)" numberOnly maxLength="8" />
                    <span class="w-150 pl-5 pt-5">(나이: {{ birthday && birthday.length === 8 ? $calcAge(birthday) : '0살 0개월' }})</span>
                </div>
            </td>
          </tr>
          <tr>
            <th>성별</th>
            <td>
                <label><input type="radio" name="sex" value="M" v-model="sex">남아</label>
                <label><input type="radio" name="sex" value="F" v-model="sex">여아</label>
            </td>
            <th class="require">몸무게</th>
            <td>
              <div class="d-flex w-40p">
                <div class="w-100"><input-field v-model="weightArr[0]" :validate="rules.input" numberOnly maxLength="3" /></div>
                <span class="pt-5 px-5">.</span>
                <div class="w-40"><input-field v-model="weightArr[1]" numberOnly maxLength="1" /></div>
                <span class="pt-5 px-5">kg</span>
              </div>
            </td>
          </tr>
          <tr v-if="petCode == 10">
            <th>크기</th>
            <td colspan="3">
                <label><input type="radio" name="size" value="L" v-model="size">대형견</label>
                <label><input type="radio" name="size" value="M" v-model="size">중형견</label>
                <label><input type="radio" name="size" value="S" v-model="size">소형견</label>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="contents-box-title">반려동물 추가정보</div>
        <table class="table-cols" ref="table">
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th>중성화 여부</th>
            <td>
                <label><input type="radio" name="neuter" value="0" v-model="neuter">중성화 전</label>
                <label><input type="radio" name="neuter" value="1" v-model="neuter">중성화 후</label>
            </td>
          </tr>
          <tr>
            <th>관심 키워드(다중 선택 가능)</th>
            <td colspan="3">
              <label v-for="(item, index) in getKeywordCode" :key="`keyword${index}`"class="checkCustom">
                <input type="checkbox" name="keyword" class="checkbox-keyword" :value="item.value" v-model="keywordList"><span>{{ item.text }}</span>
              </label>
            </td>
          </tr>
          <tr>
            <th>관심 질환(다중 선택 가능)</th>
            <td colspan="3">
              <label v-for="(item, index) in getDiseaseCode" :key="`keyword${index}`" class="checkCustom">
                <input type="checkbox" name="disease" class="checkbox-disease" :value="item.value" v-model="diseaseList"><span>{{ item.text }}</span>
              </label>
            </td>
          </tr>
          <tr>
            <th>알러지 여부(다중 선택 가능)</th>
            <td rowspan="2">
                <div>
                    <label><input type="radio" name="allergic" value="1" v-model="allergic">있어요</label>
                    <label><input type="radio" name="allergic" value="0" v-model="allergic">없어요</label>
                </div>
                <div v-if="allergic == 1">
                  <label v-for="(item, index) in getAllergyCode" :key="`keyword${index}`" class="checkCustom">
                    <input type="checkbox" name="allergic" class="checkbox-allergic" :value="item.value" v-model="allergicList"><span>{{ item.text }}</span>
                  </label>
                </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </validateForm>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import petMemberPopup from "@/components/common/petMemberPopup"
export default {
  components: { petMemberPopup },
  data() {
    return {
      opt: {
        breed: [],
      },
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      memIdx: null,
      memEmail: null,
      petCode: 10,
      breedIdx: '',
      breedName: null,
      breedEtc: 'etc', // 직접입력 구분값
      name: null,
      birthday: null,
      sex: 'M',
      weight: null,
      weightArr: [null, null],
      size: null,
      originSize: null,
      profile: null,
      profileSrc: null,
      imgName: null,
      neuter: 0,
      keyword: null,
      keywordList: [],
      disease: null,
      diseaseList: [],
      allergic: null,
      allergicInfo: null,
      allergicList: [],
      createDate: null,
      updateDate: null,
      isOpenMemberPopup: false,
      rules: {
        input: [v => !!v || this.$msg('required.input')],
        select: [v => !!v || this.$msg('required.select')],
        name: [v => !!v || this.$msg('required.input'), v=> v.patternCheck('nickName') || this.$msg('nickname.check')],
      }
    }
  },
  watch: {
    petCode(v) {
      if (v == 10) {
        this.size = this.originSize ? this.originSize : 'L'
      }
      else if (v == 20) {
        this.size = null
      }
      this.getBreed()
      this.breedIdx = ''
      this.breedName = null
    }
  },
  computed: {
    ...mapGetters({
      getKeywordCode: 'common/getKeywordCode',
      getDiseaseCode: 'common/getDiseaseCode',
      getAllergyCode: 'common/getAllergyCode',
    }),
  },
  created() {
    this.getBreed()
    if (this.$route.query.memIdx && this.$route.query.memEmail) {
      this.memIdx = this.$route.query.memIdx
      this.memEmail = this.$route.query.memEmail
    }
    if (this.idx) this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getBreed() {
      const { result, list } = await this.$api.getBreedByPetCode({ petCode: this.petCode })
      if (result === 'success') {
        this.opt.breed = list.reduce((target, data) => {
          target.push({
            text: data.breedName,
            value: data.idx,
          })
          return target;
        }, [])
        this.opt.breed.unshift({ text: '직접입력', value: this.breedEtc })
      }
    },
    async setItem() {
      const { result, data } = await this.$api.getPets({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.memIdx = data.memIdx
        this.memEmail = data.email
        this.petCode = data.petCode
        this.name = data.name
        this.birthday = data.birthday
        this.sex = data.sex
        this.weight = data.weight
        this.weightArr = data.weight.toFixed(1).split('.')
        this.size = data.size
        this.originSize = data.size
        this.profileSrc = data.profile
        this.neuter = data.neuter
        this.keyword = data.keyword
        this.keywordList = data.keyword ? data.keyword.toString().split(',') : []
        this.disease = data.disease
        this.diseaseList = data.disease ? data.disease.toString().split(',') : []
        this.allergic = data.allergic
        this.allergicInfo = data.allergicInfo
        this.allergicList = data.allergicInfo ? data.allergicInfo.toString().split(',') : []
        this.createDate = data.createDate
        this.updateDate = data.updateDate

        setTimeout(() => {
          this.breedIdx = data.breedIdx ? data.breedIdx : this.breedEtc
          this.breedName = data.breedName
        }, 100)
      }
    },
    async save() {
      if (!this.weightArr[1]) this.weightArr[1] = 0
      this.weight = this.weightArr.join('.')
      this.keyword = this.keywordList.length === 0 ? null : this.keywordList.join(',')
      this.disease = this.diseaseList.length === 0 ? null : this.diseaseList.join(',')
      this.allergicInfo = this.allergicList.length === 0 ? null : this.allergicList.join(',')

      if (this.$refs.form.validate()) {
        const { result } = await this.$api.savePets({
          idx: this.idx,
          memIdx: this.memIdx,
          petCode: this.petCode,
          breedIdx: this.breedIdx === this.breedEtc ? null : this.breedIdx,
          breedName: this.breedIdx === this.breedEtc ? this.breedName : null,
          name: this.name,
          birthday: this.birthday,
          sex: this.sex,
          weight: this.weight,
          size: this.size,
          profile: this.profile ? this.profile : null,
          imgName: this.imgName,
          neuter: this.neuter,
          keyword: this.keyword,
          disease: this.disease,
          allergic: this.allergic,
          allergicInfo: this.allergicInfo,
        });
        if (result === 'success') {
          this.alertPopup(this.$msg('ok.save'), true)
        }
        else {
          this.alertPopup(this.$msg('fail'))
        }
      }
    },
    setImg(data, name) {
      this.profile = data
      this.profileSrc = data
      this.imgName = name
    },
    setMember(data) {
      this.memIdx = data.memIdx
      this.memEmail = data.email
      this.closeMemberPopup()
    },
    openMemberPopup() {
      this.isOpenMemberPopup = true
    },
    closeMemberPopup() {
      this.isOpenMemberPopup = false
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/pet/list${query}`)
    },
    alertPopup(msg, isGoList) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (isGoList) this.goList()
        },
      })
    },
  }
}
</script>