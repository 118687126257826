export default {
  data() {
    return {
      previewH: 0,
    }
  },
  methods: {
    getImageIds(content) {
      let div = document.createElement('div')
      div.innerHTML = content
      let arr = []
      let imgArr =  div.getElementsByClassName("editorImage")
      for (let img of imgArr) arr.push(Number(img.id))
      return arr
    },
    async getCtgryList(cateCode) {
      const { result, data } = await this.$api.getCtgryList({ cateCode: cateCode })
      if (result === 'success') {
        let ctgry = []
        for (const d of data) {
          let ctgry_ = {
            value: d.cateCode,
            text: d.categoryName,
          }
          ctgry.push(ctgry_)
        }
        ctgry.unshift({ value: null, text: '선택' })
        this.opt.cateCode = ctgry
      }
    },
    setRepImg(data, name) {
      this.repImgName = name
      this.repImgData = data
      this.setPreviewHeight()
    },
    validateSave() {
      if (!this.repImgName) {
        this.$refs.repImage.msg = this.$msg('repImage.check')
        return false
      }
      return true
    },
    setPreviewHeight() {
      const vm = this
      setTimeout(() => {
        vm.previewH = vm.$refs.table.clientHeight
      }, 100)
    }
  }
}
