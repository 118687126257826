<template>
  <div>
    <div class="navi-bar"> 
        <span>회원</span>
        <span>알림</span>
        <span>알림 발송 내역</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">알림 발송 내역</div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">기본설정</div>
      <table class="table-cols">
        <colgroup>
            <col width="180">
            <col>
        </colgroup>
        <tbody>
          <tr>
            <th>발송일</th>
            <td><date ref="date"/></td>
          </tr>
        <tr>
            <th>구분</th>
            <td>
                <label><input type="radio" value="" v-model="target"><span></span> 전체</label>
                <label><input type="radio" value="event" v-model="target"><span></span> 이벤트</label>
                <label><input type="radio" value="notice" v-model="target"><span></span> 공지사항</label>
            </td>
        </tr>
        <tr>
            <th>제목</th>
            <td><div class="table-input2"><input type="text" v-model="title" placeholder="검색어 입력" maxlength="30" @keypress.enter="search"></div></td>
        </tr>
        </tbody>
      </table>
      
      <div class="btn-box center my-20">
          <div>
              <button class="btn color-7" @click="search">검색</button>
          </div>  
      </div>
    </div>
    
    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>


      <table class="table-rows">
        <colgroup>
          <col width="120">
          <col width="100">
          <col>
          <col width="200">
        </colgroup>
        <thead>
        <tr>
            <th>발송일</th>
            <th>구분</th>
            <th>제목</th>
            <th>링크</th>
        </tr>
        </thead>
        <tbody v-if="list && list.length > 0">
          <tr v-for="item in list" :key="`list-${item.idx}`">
            <td>{{ $getDateFormat(item.sendDate, 'Y-m-d') }}</td>
            <td>{{ item.target == 'event' ? '이벤트' : '공지사항' }}</td>
            <td>{{ item.title }}</td>
            <td><a :href="item.url" target="_blank" class="word-break">{{ item.url }}</a></td>
          </tr>
        </tbody>
        <tbody v-else><tr><td colspan="4">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
export default {
  name: 'memberList',
  mixins: [listMixin],
  data() {
    return {
      target: '',
      title: null,
    }
  },
  watch: {
    pageSize() {
      this.page = 1
      this.getList()
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { result, list, total } = await this.$api.getNotificationSendList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        target: this.target,
        title: this.title,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
  }
}
</script>
