 <template>
  <div>
    <div class="navi-bar"> 
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>연구일지</span>
      <span>{{ this.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">연구일지</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="setSaveParams" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">기본정보</div>
        <table class="table-cols">
          <colgroup>
            <col width="210">
            <col width="300">
            <col width="210">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th>아이디(이메일)</th>
            <td>{{ email }}</td>
            <th>닉네임</th>
            <td>{{ nickName }}</td>
          </tr>
          <tr>
            <th>등록일</th>
            <td>{{ createDate }}</td>
            <th>수정일</th>
            <td>{{ updateDate }}</td>
          </tr>
          </tbody>
        </table>

        <div class="contents-box-title">컨텐츠 내용</div>
        <table class="table-cols" ref="table">
          <colgroup>
            <col width="210">
            <col width="300">
            <col width="210">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">카테고리</th>
            <td><select-box :selectBoxClass="'w-100 mr-5'" :options="opt.cateCode" v-model="cateCode" :errorMessage="cateCodeMsg"/></td>
            <th class="require">펫 구분</th>
            <td><select-box :selectBoxClass="'w-100 mr-5'" :options="opt.petCode" v-model="petCode" :errorMessage="petCodeMsg"/></td>
          </tr>
          <tr>
            <th class="require">제목</th>
            <td colspan="3"><input-field v-model="title" class="d-inline-block" :errorMessage="titleMsg" lengthCount maxLength="50"/></td>
          </tr>
          <tr>
            <th class="require">대표이미지</th>
            <td colspan="3"><upload-image ref="repImage" @upload="setRepImg" :imgSrc="repImgSrc" :imgName="repImgName"/></td>
          </tr>
          <tr>
            <th class="require">관심 키워드(다중 선택 가능)</th>
            <td colspan="3">
              <label class="checkCustom">
                <input type="checkbox" name="keyword" class="checkbox-keyword-all"
                       :checked="keywordList.length === 0" @change="changeKeyword(null)"><span>전체</span>
              </label>
              <label v-for="(item, index) in getKeywordCode" :key="`keyword${index}`"class="checkCustom">
                <input type="checkbox" name="keyword" class="checkbox-keyword" :value="item.value" v-model="keywordList"
                       @change="changeKeyword(item.value)"><span>{{ item.text }}</span>
              </label>
            </td>
          </tr>
          <tr>
            <th class="require">관심 질환(다중 선택 가능)</th>
            <td colspan="3">
              <label class="checkCustom">
                <input type="checkbox" name="disease" class="checkbox-disease-all"
                       :checked="diseaseList.length === 0" @change="changeDisease(null)"><span>전체</span>
              </label>
              <label v-for="(item, index) in getDiseaseCode" :key="`keyword${index}`" class="checkCustom">
                <input type="checkbox" name="disease" class="checkbox-disease" :value="item.value" v-model="diseaseList"
                       @change="changeDisease(item.value)"><span>{{ item.text }}</span>
              </label>
            </td>
          </tr>
          <tr>
            <th>나이</th>
            <td colspan="3" rowspan="2">
              <div>
                <label v-for="(item, index) in opt.age" :key="`keyword${index}`" class="checkCustom">
                  <input type="radio" name="age" :value="item.value" v-model="age"><span>{{ item.text }}</span>
                </label>
              </div>
              <div v-if="isOpenRange"><range :rangeData="ageRangeData" @set="setAge"/></div>
            </td>
          </tr>
          </tbody>
        </table>

        <editor ref="editor" :cateCode="20" :previewH="this.previewH"/>

      </div>
    </validateForm>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import saveEditorMixin from "@/mixins/saveEditorMixin"
import range from "@/components/common/range"

export default {
  name: 'labSave',
  mixins: [ saveEditorMixin ],
  components: { range },
  data() {
    return {
      opt: {
        cateCode: [],
        petCode: [
          { value: null, text: '선택' },
          { value: 10, text: '강아지' },
          { value: 20, text: '고양이' },
        ],
        age: [
          { value: 0, text: '전체' },
          { value: 1, text: '범위선택' },
          { value: 2, text: '1세 이상' },
        ],
      },
      repImgSrc: null,
      repImgName: null,
      repImgData: null,
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      cateCode: null,
      petCode: null,
      title: null,
      keyword: null,
      keywordList: [],
      disease: null,
      diseaseList: [],
      age: 0,
      isOpenRange: false,
      ageRangeData: null,
      minAge: null,
      maxAge: null,
      writerType: 1,
      email: null,
      nickName: null,
      content: null,
      createDate: null,
      updateDate: null,
      cateCodeMsg: '',
      petCodeMsg: '',
      titleMsg: '',
    }
  },
  watch: {
    cateCode() {
      this.cateCodeMsg = ''
    },
    petCode() {
      this.petCodeMsg = ''
    },
    title() {
      this.titleMsg = ''
    },
    age(v) {
      if (v === 0) {
        this.isOpenRange = false
        this.minAge = this.ageRangeData.min
        this.maxAge = this.ageRangeData.max
      }
      if (v === 1) {
        const rangeMaxAge = this.getAgeGroup[this.getAgeGroup.length-2].idx
        this.minAge = this.minAge ? this.minAge : this.ageRangeData.min
        this.maxAge = this.maxAge > rangeMaxAge ? rangeMaxAge : this.maxAge
        this.ageRangeData.range = [String(this.minAge), String(this.maxAge)]
        this.isOpenRange = true
      }
      if (v === 2) {
        this.isOpenRange = false
        this.minAge = this.ageRangeData.max
        this.maxAge = this.ageRangeData.max
      }
    },
  },
  computed: {
    ...mapGetters({
      getId: 'session/getId',
      getNickName: 'session/getNickName',
      getKeywordCode: 'common/getKeywordCode',
      getDiseaseCode: 'common/getDiseaseCode',
      getAgeGroup: 'common/getAgeGroup',
    }),
  },
  mounted() {
    this.getCtgryList(20)
    if (this.idx) this.setItem()
    this.email = this.getId
    this.nickName = this.getNickName
    this.setPreviewHeight()
    setTimeout(() => {
      this.setAgeGroupData()
    }, 300)
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getLab({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.cateCode = data.cateCode
        this.petCode = data.petCode
        this.title = data.title
        this.keyword = data.keyword
        this.keywordList = data.keyword ? data.keyword.toString().split(',') : []
        this.disease = data.disease
        this.diseaseList = data.disease ? data.disease.toString().split(',') : []
        this.minAge = data.minAge
        this.maxAge = data.maxAge
        this.writerType = data.writerType
        this.email = data.email
        this.nickName = data.nickName
        this.repImgSrc = data.repImgSrc
        this.repImgName = data.repImgName
        this.createDate = data.createDate
        this.updateDate = data.updateDate

        this.$refs.editor.editor.commands.setContent(data.content)
        this.$refs.editor.savedContent = data.content
        this.$refs.editor.editorImageIds = this.getImageIds(data.content)
        this.$refs.editor.savedImageIds = this.getImageIds(this.$refs.editor.savedContent)
        this.setPreviewHeight()
      }
    },
    setAgeGroupData() {
      let marks = {}
      const ageList = this.getAgeGroup
      const data = ageList.reduce((target, data, index) => {
        if ([0, (ageList.length-2)/2, ageList.length-2].includes(index)) marks[data.idx] = data.ageName
        if (index < ageList.length-1) target[data.idx] = data.ageName;
        return target;
      }, {})
      this.ageRangeData = {
        min: ageList[0].idx,
        max: ageList[ageList.length-1].idx,
        range: [String(ageList[0].idx), String(this.getAgeGroup[ageList.length-2].idx)],
        data: data,
        marks: marks
      }
      this.minAge = this.minAge ? this.minAge : ageList[0].idx
      this.maxAge = this.maxAge ? this.maxAge : ageList[ageList.length-1].idx

      if (this.minAge === this.ageRangeData.min && this.maxAge === this.ageRangeData.max) {
        this.age = 0
      }
      else if (this.minAge === this.ageRangeData.max && this.maxAge === this.ageRangeData.max) {
        this.age = 2
      }
      else {
        this.age = 1
      }
    },
    setAge([min, max]) {
      this.minAge = min
      this.maxAge = max
    },
    changeKeyword(keyword) {
      if (keyword) {
        this.$el.querySelector('.checkbox-keyword-all').checked = false
      } else {
        this.keywordList = []
        this.$el.querySelector('.checkbox-keyword-all').checked = true
        const checkboxs = this.$el.querySelectorAll('.checkbox-keyword')
        for (let checkbox of checkboxs) {
          checkbox.checked = false
        }
      }
    },
    changeDisease(disease) {
      if (disease) {
        this.$el.querySelector('.checkbox-disease-all').checked = false
      } else {
        this.diseaseList = []
        this.$el.querySelector('.checkbox-disease-all').checked = true
        const checkboxs = this.$el.querySelectorAll('.checkbox-disease')
        for (let checkbox of checkboxs) {
          checkbox.checked = false
        }
      }
    },
    validate() {
      if (!this.cateCode) {
        this.cateCodeMsg = this.$msg('required.select')
        return false
      }
      if (!this.petCode) {
        this.petCodeMsg = this.$msg('required.select')
        return false
      }
      if (!this.title) {
        this.titleMsg = this.$msg('required.input')
        return false
      }
      return true
    },
    setSaveParams() {
      this.keyword = this.keywordList.length === 0 ? null : this.keywordList.join(',')
      this.disease = this.diseaseList.length === 0 ? null : this.diseaseList.join(',')

      if (this.validate() && this.validateSave()) {
        this.save({
          idx: this.idx,
          cateCode: this.cateCode,
          petCode: this.petCode,
          title: this.title,
          repImgData: this.repImgData,
          repImgName: this.repImgName,
          keyword: this.keyword,
          disease: this.disease,
          minAge: this.minAge,
          maxAge: this.maxAge,
          content: this.$refs.editor.editor.getHTML(),
          writerId: this.email,
          writerName: this.nickName,
          editImg: this.$refs.editor.getEditorImageIds(),
        })
      }
    },
    async save(params) {
      const { result } = await this.$api.labSave(params);
      if (result === 'success') {
        // await this.$refs.editor.removeProductTag() 상품태그 정보만 삭제
        this.alertPopup(this.$msg('ok.save'), true)
      }
      else {
        this.alertPopup(this.$msg('fail'))
      }
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/board/lab/list${query}`)
    },
    alertPopup(msg, isGoList) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (isGoList) this.goList()
        }
      })
    },
  }
}
</script>