<template>
  <div class="popupBox-wrap">
    <div class="popupPosition" @click="close"></div>

    <div class="popupBox w-900">
      <div class="popupBox-title">신고 내역</div>
      <div class="popupBox-body">


        <table class="table-rows">
          <colgroup>
            <col width="50">
            <col>
            <col width="90">
            <col width="180">
          </colgroup>
          <thead>
          <tr>
            <th>No.</th>
            <th>신고 유형</th>
            <th>신고자</th>
            <th>신고일</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in list" :key="`list-${item.idx}`">
            <td>{{ item.idx }}</td>
            <td class="left">
              {{ item.reportName }}
              <template v-if="item.reportName === '기타'">(사유 : {{ item.memo }})</template>
            </td>
            <td class="left">
              <p>{{ item.email }}</p>
              <p>{{ item.nickName }}</p>
            </td>
            <td>{{ item.createDate, 'Y-m-d' }}</td>
          </tr>
          </tbody>
        </table>

        <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />

      </div>
      <div class="popupBox-bottom">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
export default {
  props: ['idx'],
  mixins: [listMixin],
  data() {
    return {
      list: null,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        idx: this.idx,
        page: this.page,
        pageSize: this.pageSize,
      }
      const { result, list, total } = await this.$api.getReportDetail(params)
      if (result === 'success') {
        this.total = total
        this.list = list
      }
    },
    close() {
      this.$emit('close')
    },
  }
}

</script>
