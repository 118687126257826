<template>
  <div>
    <div class="navi-bar"> 
        <span>반려동물</span>
        <span>반려동물 관리</span>
        <span>품종 관리</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">품종 관리</div>
      <div>
        <button @click="goSave()" class="btn color-1">등록</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <table class="table-cols">
        <colgroup>
            <col width="180">
            <col>
        </colgroup>
        <tbody>
        <tr>
          <th>펫구분</th>
          <td>
            <label><input type="radio" name="petCode" value="" v-model="petCode">전체</label>
            <label><input type="radio" name="petCode" value="10" v-model="petCode">강아지</label>
            <label><input type="radio" name="petCode" value="20" v-model="petCode">고양이</label>
          </td>
        </tr>
        <tr>
          <th>품종명</th>
          <td>
            <div class="d-flex">
              <div class="w-300">
                <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="btn-box center my-20">
          <div>
            <button class="btn color-7" @click="search">검색</button>
          </div>  
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
    </div>

      <table class="table-rows">
        <colgroup>
            <col width="60">
            <col width="120">
            <col width="80">
            <col width="80">
            <col width="80">
        </colgroup>
        <thead>
        <tr>
          <th>펫구분</th>
          <th>품종명</th>
          <th>크기</th>
          <th>평균 몸무게 등록현황</th>
          <th>등록일/수정일</th>
        </tr>
        </thead>
        <tbody v-if="list && list.length > 0">
        <tr v-for="item in list" :key="`list-${item.idx}`" @click="goSave(item.idx)" class="cursor-pointer">
          <td>{{ item.petName }}</td>
          <td>{{ item.breedName }}</td>
          <td>{{ !item.size ? '-' : item.size === 'S' ? '소형견' : item.size === 'M' ? '중형견' : '대형견' }}</td>
          <td>{{ item.avgWeight }}</td>
          <td>
            <p>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</p>
            <p>{{ $getDateFormat(item.updateDate, 'Y-m-d') }}</p>
          </td>
        </tr>
        </tbody>
        <tbody v-else><tr><td colspan="11">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />
    </div>
  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'
export default {
  mixins: [listMixin],
  data() {
    return {
      isActive:false,target: 1,
      petCode: '',
      text: null,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { result, list, total } = await this.$api.getBreedList({
        petCode: this.petCode,
        text: this.text,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    goSave(idx=0) {
      if (idx) this.$router.push(`/pet/breed/save?idx=${idx}&page=${this.page}`)
      else    this.$router.push(`/pet/breed/save?&page=${this.page}`)
    },
  }
}
</script>