<template>
  <div class="popupBox-wrap scroll">
    <div class="popupPosition" @click="close"></div>
    <div class="popupBox w-840">
      <div class="popupBox-title">상품태그 설정</div>
      <div class="popupBox-body">

        <div v-if="!tagInfo">
          <div @click="$refs.editorImageInput.click()" class="tag-edite-btn"><div class="icon-img icon-editor-img"></div> 이미지 등록</div>
          <input type="file" accept="image/gif, image/jpg, image/jpeg, image/png" ref="editorImageInput" @change="uploadEditorImage($event)" style="display:none">
        </div>

        <div class="contents-box-title">
          <div>첨부이미지</div>
        </div>
        <div class="tag-edite-box" >
          <div class="tag-img-wrap">
            <img v-if="imageSrc" :src="imageSrc" altType="detail" style="width: 800px;" ref="popupEditorImage" @click="imageClick"/>
            <div v-else class="tag-img-empty">이미지 등록을 해주세요.</div>
          </div>

          <movable v-if="leftPx && topPx" class="modalMovable" :posLeft="leftPx" :posTop="topPx"
                   @move="dragMove" @complete="dragComplete"
                   :bounds="{x: [-leftPx, imageWidth-leftPx], y:[-topPx, imageHeight-topPx]}">
            <div class="tag-icon" :class="{ orange : color === 'orange'}">
              <div class="dot"  ref="tagIcon" target="tagIcon"></div>
              <div class="tag-product-box" v-if="title && price && tagImgSrc" :class="tagPosition">
                <div class="product-img"><img :src="tagImgSrc"></div>
                <div class="product-info">
                  <strong>{{ title }}</strong>
                  <span>{{ Number(price).numberFormat() }}원</span>
                </div>
              </div>
            </div>
          </movable>
        </div>
        <div v-if="imageMsg" class="error-message">{{ imageMsg }}</div>


        <div class="information-box">
          <div class="information-box-title">안내</div>
          <div class="information-contents">
            <ul class="dot-list">
              <li>이미지 클릭, 아이콘 드래그를 통해 위치를 변경 할 수 있습니다.</li>
            </ul>
          </div>
        </div>

        <div class="contents-box-title">
          <div>상품정보</div>
        </div>
        <table class="table-cols">
          <colgroup>
            <col width="180">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th>배경색상</th>
            <td>
              <div class="colorChipCheck">
                <label>
                  <input type="radio" name="background" value="9" :checked="color === 'black'" @click="setColor('black')">
                  <span class="bgcolor-9"></span>
                </label>
                <label>
                  <input type="radio" name="background" value="1" :checked="color === 'orange'" @click="setColor('orange')">
                  <span class="bgcolor-1"></span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th class="require">상품URL</th>
            <td><input-field v-model="url" :errorMessage="urlMsg" maxLength="500" placeholder="클릭 시 이동할 링크 https:// 포함하여 입력해주세요."/></td>
          </tr>
          <tr>
            <th class="require">상품명</th>
            <td><input-field v-model="title" :errorMessage="titleMsg" maxLength="30" /></td>
          </tr>
          <tr>
            <th class="require">상품가격</th>
            <td><input-field v-model="price" :errorMessage="priceMsg" numberOnly maxLength="11" /></td>
          </tr>
          <tr>
            <th class="require">상품이미지</th>
            <td>
              <upload-image ref="tagImage" @upload="setTagImg" :imgSrc="tagImgSrc" :imgName="tagImgName" isProduct="true"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="popupBox-bottom">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">닫기</button>
        <button v-if="tagInfo" class="w-90 mr-10 btn btn-sm btn-outline color-1" @click="remove">삭제</button>
        <button class="w-90 mr-10 btn btn-sm color-1" @click="setImage">저장</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  props: ['cateCode', 'propId', 'tagInfo'],
  data(){
    return{
      imageId: this.propId,
      imageData: null,
      imageName: null,
      imageSrc: null,
      imageWidth: null,
      imageHeight: null,

      leftPx: null,
      topPx: null,
      leftPercent: 50,
      topPercent: 50,

      tagId: 0,
      color: 'black',
      title: null,
      price: null,
      url: null,
      tagImgData: null,
      tagImgName: null,
      tagImgSrc: null,
      tagPosition: 'up',

      imageMsg: '',
      titleMsg: '',
      priceMsg: '',
      urlMsg: '',
    }
  },
  watch: {
    imageName() {
      this.imageMsg = ''
    },
    title() {
      this.titleMsg = ''
    },
    price() {
      this.priceMsg = ''
    },
    url() {
      this.urlMsg = ''
    }
  },
  created() {
    if (this.tagInfo) {
      this.tagId = this.tagInfo.tagId
      this.imageWidth = this.tagInfo.imageWidth
      this.imageHeight = this.tagInfo.imageHeight
      this.imageSrc = this.tagInfo.edImgSrc
      this.imageName = this.tagInfo.edImgName
      this.leftPercent = this.tagInfo.leftPer
      this.topPercent = this.tagInfo.topPer
      this.leftPx = this.leftPercent / 100 * this.imageWidth
      this.topPx = this.topPercent / 100 * this.imageHeight
      this.color = this.tagInfo.color
      this.title = this.tagInfo.title
      this.price= this.tagInfo.price
      this.url = this.tagInfo.url
      this.tagImgSrc = this.tagInfo.tagSrc
      this.tagImgName = this.tagInfo.tagImgName
      this.tagPosition = this.imageHeight - this.topPx > 150 ? 'up' : 'down'
    }
  },
  methods: {
    ...mapActions({
      setRemoveProductTagEditorImgIds: 'productTag/setRemoveProductTagEditorImgIds',
    }),
    async uploadEditorImage(event) {
      if (!event) return false
      event.stopPropagation();
      event.preventDefault();

      let result = false;
      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        for (let file of files) {
          if (!/^image\//.test(file.type)) {
            this.imageMsg = this.$msg('image.ext')
            return false
          }
          let fileExt = this.$getExts(file.name, false);
          let isImg = this.$isImages(fileExt);
          if (isImg) {
            if (file.size < this.$MBtoB(10)) { // 10MB
              result = true;
            } else {
              this.imageMsg = this.$msg('image.limit')
            }
          }
          if (result) {
            let vm = this;
            let reader = new FileReader();
            reader.onload = function () {
              vm.imageName = file.name
              vm.imageData = reader.result
              vm.imageSrc = URL.createObjectURL(file)
              event.target.value = ''
            }
            reader.readAsDataURL(file);

            setTimeout(() => {
              vm.imageWidth = 800
              vm.imageHeight = vm.$refs.popupEditorImage.height
              vm.leftPx = vm.imageWidth / 2
              vm.topPx = vm.imageHeight / 2
            }, 500)
          }
        }
      }
    },
    imageClick(event) {
      const left = event.layerX
      const top = event.layerY
      const isUp = this.imageHeight - top > 150
      if (!isUp) this.tagPosition = 'down'
      else       this.tagPosition = 'up'

      this.leftPx = left
      this.topPx = top
      this.leftPercent = left* 100 / this.imageWidth
      this.topPercent = top * 100 / this.imageHeight
    },
    dragMove(el) {
      if (el.css.left < 0) el.css.left = 0
      const isUp = this.imageHeight - el.css.top > 150
      if (!isUp) this.tagPosition = 'down'
      else       this.tagPosition = 'up'
    },
    dragComplete(el) {
      this.leftPercent = el.css.left * 100 / this.imageWidth
      this.topPercent = el.css.top * 100 / this.imageHeight
    },
    setColor(color){
      this.color = color;
    },
    setTagImg(data, name, src) {
      this.tagImgData = data
      this.tagImgName = name
      this.tagImgSrc = src
    },
    validate() {
      if (!this.imageName) {
        this.imageMsg = this.$msg('required.input')
        return false
      }
      if (!this.url) {
        this.urlMsg = this.$msg('required.select')
        return false
      }
      if (!this.url.patternCheck('url')) {
        this.urlMsg = this.$msg('url.check')
        return false
      }
      if (!this.title) {
        this.titleMsg = this.$msg('required.input')
        return false
      }
      if (!this.price) {
        this.priceMsg = this.$msg('required.input')
        return false
      }
      if (!this.tagImgName) {
        this.$refs.tagImage.msg = this.$msg('required.input')
        return false
      }
      return true
    },
    async setImage() {
      if (this.validate()) {
        if (!this.tagInfo) {
          const res_image = await this.$api.uploadEditorImage({
            name: this.imageName,
            data: this.imageData,
            cateCode: this.cateCode,
          })
          if (res_image.result === 'success') {
            this.imageId = Number(res_image.data.atchIdx)
            this.imageSrc = res_image.data.src
            this.imageName = res_image.data.imgName
          }
        }

        const res_tag = await this.$api.setProductTag({
          atchIdx: this.imageId,
          tagId: this.tagId,
          imageWidth: this.imageWidth,
          imageHeight: this.imageHeight,
          leftPercent: this.leftPercent,
          topPercent: this.topPercent,
          color: this.color,
          title: this.title,
          price: this.price,
          url: this.url,
          tagImgData: this.tagImgData,
          tagImgName: this.tagImgName,
        })
        if (res_tag.result === 'success') {
          this.imageName = res_tag.data.tagImgName

          const emit = this.tagId == 0 ? 'insert' : 'update'
          this.$emit(emit, {
            id: this.imageId,
            src: this.imageSrc,
            title: this.imageName,
            tagId: res_tag.data.tagId,
            tagLeft: this.leftPercent,
            tagTop: this.topPercent,
            tagColor: this.color,
            tagTitle: this.title,
            tagPrice: this.price,
            tagUrl: this.url,
            tagSrc: res_tag.data.src,
            tagPosition: this.tagPosition,
          })

        }
      }
    },
    async remove() {
      this.$emit('remove', this.imageId)
    },
    close() {
      this.$emit('close')
    },
  }
}
</script>