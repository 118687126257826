<template>
  <div class="layout-side" v-if="!$route.path.startsWith('/dashboard/')">
    <div v-for="(lnb, index) in lnbList" :key="`lnbList-${index}`">
      <div v-for="(menu, index) in lnb" :key="`lnb-${index}`" v-if="$route.path.startsWith(`/${index}`)" class="menu-box">
        <div v-for="(item, index) in menu" :key="`menu-${index}`">
          <div class="dep2-heading">{{ item.name }}</div>
          <ul>
            <li v-for="item2 in item.list" :class="{ on : getIsMenu(item.path, item2.path)}"><router-link :to="`${item.path}${item2.path}`">{{ item2.name }}</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lnbList: [
        {
          policy : [
            {
              name: '기본정책', path: '/policy',
              list: [
                { name: '기본 정보 설정', path: '/baseInfo' },
                { name: '약관설정', path: '/terms' },
                { name: '아이콘 노출 설정', path: '/icon' },
                { name: '스크립트 관리', path: '/script' },
                { name: '단어 필터링', path: '/wordFilter' },
              ]
            },
            {
              name: '운영자 관리', path: '/policy',
              list: [
                { name: '운영자 리스트', path: '/manager/list' },
              ]
            },
            {
              name: '분류 관리', path: '/policy',
              list: [
                { name: '공통 분류 관리', path: '/classification' },
              ]
            },
          ],
          member : [
            {
              name: '회원 관리', path: '/member',
              list: [
                { name: '회원 리스트', path: '/list' },
                { name: '회원 등록', path: '/save' },
                { name: '휴면 회원관리', path: '/sleepList' },
                { name: '회원 탈퇴', path: '/withdrawal' },
              ]
            },
            {
              name: '알림', path: '/member',
              list: [
                { name: '알림 발송', path: '/notification/send' },
                { name: '알림 발송 내역', path: '/notification/send/list' },
              ]
            },
          ],
          board : [
            {
              name: '게시물관리', path: '/board',
              list: [
                { name: '꼬리TIP', path: '/tip/list' },
                { name: '연구일지', path: '/lab/list' },
                { name: '랜선집사', path: '/md/list' },
                { name: '꼬뮤니티', path: '/ggomunity/list' },
                { name: '이벤트', path: '/event/list' },
                { name: '공지사항', path: '/notice/list' },
                { name: '1:1문의', path: '/inquiry/list' },
                { name: 'FAQ', path: '/faq/list' },
                { name: '삭제 게시물 관리', path: '/remove' },
                { name: '신고 게시물 관리', path: '/report' },
              ]
            },
            {
              name: '게시물 진열 관리', path: '/board',
              list: [
                { name: '메인 분류 관리', path: '/manage/main' },
                { name: '메인 진열 관리', path: '/manage/mainDisplay' },
                { name: 'MD’S PICK 분류 관리', path: '/manage/md' },
                { name: 'MD’S PICK 진열 관리', path: '/manage/mdDisplay' },
                { name: '나이별 추천 컨텐츠', path: '/manage/age' },
              ]
            },
            {
              name: '댓글관리', path: '/board',
              list: [
                { name: '댓글 리스트', path: '/comment/list' },
                { name: '삭제 댓글 관리', path: '/comment/remove' },
              ]
            },
            {
              name: '활동관리', path: '/board',
              list: [
                { name: '활동 리스트', path: '/activity' },
              ]
            },
          ],
          product : [
            {
              name: '상품 관리', path: '/product',
              list: [
                { name: '상품 리스트', path: '/list' },
              ]
            },
            {
              name: '상품 진열 관리', path: '/product',
              list: [
                { name: '메인 분류 관리', path: '/main' },
                { name: '메인 진열 관리', path: '/mainDisplay' },
                { name: '추천상품 진열 관리', path: '/display' },
              ]
            },
          ],
          design : [
            {
              name: '디자인 설정', path: '/design',
              list: [
                { name: '배너 관리', path: '/banner/list' },
              ]
            },
          ],
          pet : [
            {
              name: '반려동물 관리', path: '/pet',
              list: [
                { name: '반려동물 리스트', path: '/list' },
                { name: '품종 관리', path: '/breed/list' },
                { name: '접종 관리', path: '/vaccine/list' },
              ]
            },
          ],
        },
      ]
    }
  },
  methods: {
    getIsMenu(_path1, _path2) {
      const same = [
        '/member/notification/send','/member/notification/send/list','/member/save',
        '/board/manage/main','/board/manage/mainDisplay','/board/manage/md', '/board/manage/mdDisplay', 
        '/board/comment/list','/board/comment/remove','/board/manage/md','/board/manage/age','/product/list','/product/save',
        '/product/main','/product/mainDisplay','/pet/list','/pet/save',
      ]
      const currentPath = this.$route.path
      const currentQuery = this.$route.query
      if (same.includes(currentPath)) {
        if (currentPath == '/member/save' && currentQuery.idx) {
          if (_path1+_path2 === '/member/list') return true
        }
        if (currentPath.includes('/product/save')) {
          if (_path1+_path2 === '/product/list') return true
        }
        if (currentPath.includes('/pet/save')) {
          if (_path1+_path2 === '/pet/list') return true
        }
        else if (currentPath == `${_path1}${_path2}`) return true
      }
      else {
        const splitPath = _path2.split('/')
        if (currentPath.includes(`${_path1}/${splitPath[1]}`)) return true
      }
    }
  },
}
</script>
