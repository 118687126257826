<template>
  <div class="popupBox-wrap scroll">
    <div class="popupPosition" @click="close"></div>
    
    <div class="popupBox w-900">
      <div class="popupBox-title">배너{{ idx ? '수정' : '등록'}}</div>
      <div class="popupBox-body">
        <div class="layout-contents-box">
          <table class="table-cols">
            <colgroup>
              <col width="180">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>노출영역</th>
              <td>{{ ctgry.categoryName }}</td>
            </tr>
            <tr>
              <th class="require">링크</th>
              <td><input-field v-model="link" :errorMessage="linkMsg" maxLength="500" placeholder="클릭 시 이동할 링크 https:// 포함하여 입력해주세요."/></td>
            </tr>
            <tr>
              <th>링크방식</th>
              <td>
                <label><input type="radio" v-model="linkTarget" name="linkTarget" value="_blank">새창</label>
                <label><input type="radio" v-model="linkTarget" name="linkTarget" value="_self">현재창</label>
              </td>
            </tr>
            <tr>
              <th>노출여부</th>
              <td>
                <label><input type="radio" v-model="view" name="view" :value="1">노출</label>
                <label><input type="radio" v-model="view" name="view" :value="0">미노출</label>
              </td>
            </tr>
            <tr>
              <th>노출기간</th>
              <td>
                <div class="d-flex">
                  <div class="mr-10">
                    <label><input type="radio" v-model="viewType" name="viewType" :value="0">상시노출</label>
                    <label><input type="radio" v-model="viewType" name="viewType" :value="1">노출기간 설정</label>
                  </div>
                  <date v-show="viewType === 1" ref="date" noBtn/>
                </div>
                <div v-show="dateMsg" class="error-message">{{ dateMsg }}</div>
              </td>
            </tr>
            <tr>
              <th class="require">이미지</th>
              <td>
                <upload-image ref="bannerImage" @upload="setBannerImg" :imgSrc="imgSrc" :imgName="imgName" isNoSizeMsg="true"/>
              </td>
            </tr>
            </tbody>
          </table>
          
          <div class="information-box">
            <div class="information-box-title">안내</div>
            <div class="information-contents">
              <ul class="dot-list">
                <li>메인 비주얼배너(PC) 권장사이즈 : 1200 x auto</li>
                <li>메인 중간배너(PC) 권장사이즈 : 1200 x auto</li>
                <li>상단 띠 배너(PC) 권장사이즈 : 1920 x auto</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div class="popupBox-bottom">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">닫기</button>
        <button v-if="idx" class="w-90 mr-10 btn btn-outline btn-sm color-1" @click="remove">삭제</button>
        <button class="w-90 mr-10 btn btn-sm color-1" @click="setSaveParams">{{ idx ? '수정' : '등록'}}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['idx', 'ctgry'],
  data() {
    return {
      link: null,
      linkTarget: '_blank',
      view: 1,
      viewType: 0,
      startDate: null,
      endDate: null,
      imgData: null,
      imgSrc: null,
      imgName: null,
      linkMsg: '',
      dateMsg: '',
    }
  },
  watch: {
    link() {
      this.linkMsg = ''
    },
  },
  created() {
    if (this.idx) this.setItem()
  },
  methods: {
    async setItem() {
      const { result, data } = await this.$api.getBanner({idx: this.idx})
      if (result === 'success') {
        this.link = data.link
        this.linkTarget = data.linkTarget
        this.view = data.view && Number(data.view)
        this.viewType = data.viewType && Number(data.viewType)
        if (this.viewType === 1) {
          this.$refs.date.startDate = data.startDate
          this.$refs.date.endDate = data.endDate
        }
        this.imgSrc = data.imgSrc
        this.imgName = data.imgName
      }
    },
    setBannerImg(data, name) {
      this.imgName = name
      this.imgData = data
    },
    validate() {
      this.dateMsg = ''
      if (!this.link) {
        this.linkMsg = this.$msg('required.input')
        return false
      }
      if (!this.link.patternCheck('url')) {
        this.linkMsg = this.$msg('url.check')
        return false
      }
      if (this.viewType === 1 && !(this.$refs.date.startDate && this.$refs.date.endDate)) {
        this.dateMsg = this.$msg('required.select')
        return false
      }
      return true
    },
    validateSave() {
      if (!this.imgName) {
        this.$refs.bannerImage.msg = this.$msg('image.check')
        return false
      }
      return true
    },
    setSaveParams() {
      if (this.validate() && this.validateSave()) {
        this.save({
          idx: this.idx,
          cateCode: this.ctgry.cateCode,
          link: this.link,
          linkTarget: this.linkTarget,
          view: this.view,
          viewType: this.viewType,
          startDate: this.$refs.date ? this.$refs.date.startDate : null,
          endDate: this.$refs.date ? this.$refs.date.endDate : null,
          img: this.imgData,
          imgName: this.imgName,
        })
      }
    },
    async save(params) {
      const { result } = await this.$api.saveBanner(params);
      if (result === 'success') this.$emit('save', true)
      else                      this.$emit('save', false)
    },
    async remove() {
      this.$emit('remove', this.idx)
    },
    close() {
      this.$emit('close')
    },
  }
}
</script>
