<template>
  <div>
    <div class="navi-bar">
      <span>게시판</span>
      <span>게시물 관리</span>
      <span>공지사항</span>
      <span>{{ this.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">공지사항</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="setSaveParams" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <div class="layout-contents-box">
      <div class="contents-box-title">게시글 {{ this.idx ? '수정' : '등록'}}</div>
      <table class="table-cols">
        <colgroup>
          <col width="200">
          <col>
        </colgroup>
        <tbody>
        <tr>
          <th>상위고정</th>
          <td>
            <label><input type="checkbox" value="1" v-model="pinYN">상위고정</label>
          </td>
        </tr>
        <tr>
          <th class="require">제목</th>
          <td colspan="3"><input-field v-model="title" class="w-300 mr-5 d-inline-block" :errorMessage="titleMsg" lengthCount maxLength="50"/></td>
        </tr>
        </tbody>
      </table>
      <editor ref="editor" :cateCode="5010" :previewH="this.previewH"/>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex"
import saveEditorMixin from "@/mixins/saveEditorMixin"
export default {
  name: 'noticeSave',
  mixins: [ saveEditorMixin ],
  data() {
    return {
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      pinYN: 0,
      title: null,
      content: null,
      titleMsg: '',
    }
  },
  mounted() {
    if (this.idx) this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getNotice({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.pinYN = data.pinYN
        this.title = data.title

        this.$refs.editor.editor.commands.setContent(data.content)
        this.$refs.editor.savedContent = data.content
        this.$refs.editor.editorImageIds = this.getImageIds(data.content)
        this.$refs.editor.savedImageIds = this.getImageIds(this.$refs.editor.savedContent)
      }
    },
    validate() {
      if (!this.title) {
        this.titleMsg = this.$msg('required.input')
        return false
      }
      return true
    },
    setSaveParams() {
      if (this.validate()) {
        this.save({
          idx: this.idx,
          pinYN: this.pinYN,
          title: this.title,
          content: this.$refs.editor.editor.getHTML(),
          editImg: this.$refs.editor.getEditorImageIds(),
        })
      }
    },
    async save(params) {
      const { result } = await this.$api.noticeSave(params)
      if (result === 'success') {
        this.alertPopup(this.$msg('ok.save'), `/board/notice/list`)
      }
      else {
        this.alertPopup(this.$msg('fail'), null)
      }
    },
    alertPopup(msg, movePath) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (movePath) location.href = movePath
        },
      })
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/board/notice/list${query}`)
    },
  }
}
</script>