<template>
  <div>
    <div class="navi-bar"> 
      <span>반려동물</span>
      <span>반려동물 관리</span>
      <span>접종 관리</span>
      <span>{{ this.idx ? '수정' : '등록'}}</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">접종 {{ this.idx ? '수정' : '등록'}}</div>
      <div>
        <button @click="goList" class="btn btn-outline color-6">목록</button>
        <button @click="save" class="btn color-1">{{ this.idx ? '수정' : '등록'}}</button>
      </div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">기본정보</div>
        <table class="table-cols">
          <colgroup>
            <col width="200">
            <col width="400">
            <col width="200">
            <col width="450">
          </colgroup>
          <tbody>

          <tr>
            <th>펫구분</th>
            <td>
              <label><input type="radio" name="petCode" value="10" v-model="petCode">강아지</label>
              <label><input type="radio" name="petCode" value="20" v-model="petCode">고양이</label>
            </td>
            <th class="require">접종구분</th>
            <td><select-box :selectBoxClass="'w-500 mr-5'" :validate="rules2" :options="getAgeOpt"  v-model="ageIdx" /></td>
          </tr>
          <tr>
            <th>필수여부</th>
            <td>
              <label><input type="radio" name="requiredYN" value="1" v-model="required">필수</label>
              <label><input type="radio" name="requiredYN" value="0" v-model="required">선택</label>
            </td>
            <th class="require">접종시기</th>
            <td><input-field class="w-400" v-model="period" :validate="rules" placeholder="접종시기 입력" maxLength="30"/></td>
          </tr>
          <tr>
            <th class="require">접종명</th>
            <td colspan="3">
              <div class="w-400"><input-field v-model="vaccineName" :validate="rules" placeholder="접종명 입력" /></div>
            </td>
          </tr>
          <tr>
            <th class="require">접종상세정보</th>
            <td colspan="3"><input-field multiline rows="5" v-model="memo" :validate="rules" maxLength="1000" /></td>
          </tr>
          </tbody>
        </table>

      </div>
    </validateForm>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
export default {
  data() {
    return {
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      petCode: 10,
      ageIdx: null,
      required: 1,
      period: null,
      vaccineName: null,
      memo: null,
      rules: [v => !!v || this.$msg('required.input')],
      rules2: [v => !!v || this.$msg('required.select')],
    }
  },
  computed: {
    ...mapGetters({
      getAgeGroup: 'common/getAgeGroup',
    }),
    getAgeOpt() {
      const opt = []
      this.getAgeGroup.forEach((age) => {
        opt.push({
          text: age.ageName,
          value: age.idx
        })
      })
      opt.unshift({ value: null, text: '선택' })
      return opt
    },
  },
  created() {
    if (this.idx) this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getVac({ idx: this.idx })
      if (result === 'success') {
        this.idx = data.idx
        this.petCode = data.petcode
        this.ageIdx = data.ageIdx
        this.required = data.required
        this.period = data.period
        this.vaccineName = data.vaccineName
        this.memo = data.memo
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.saveVac({
          idx: this.idx,
          petCode: this.petCode,
          ageIdx: this.ageIdx,
          required: this.required,
          period: this.period,
          vaccineName: this.vaccineName,
          memo: this.memo,
        });
        if (result === 'success') {
          this.alertPopup(this.$msg('ok.save'), true)
        }
       else {
          this.alertPopup(this.$msg('fail'))
        }
      }
    },
    goList() {
      const query = this.$route.query.page ? `?page=${this.$route.query.page}` : ''
      this.$router.push(`/pet/vaccine/list${query}`)
    },
    alertPopup(msg, isGoList) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (isGoList) this.goList()
        },
      })
    },
  }
}
</script>