<template>
  <div class="popupBox-wrap">
    <div class="popupPosition" @click="close"></div>

    <div class="popupBox w-600">
      <div class="popupBox-title">반려인 ID 선택</div>
      <div class="popupBox-body popup-height350">

          <table class="table-rows">
            <colgroup>
              <col width="150">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>통합검색</th>
              <td><input-field v-model="text" placeholder="검색어 입력(아이디, 닉네임)" @keypress.enter="getList"/></td>
            </tr>
            </tbody>
          </table>
          <div class="popupBox-middle"><button class="w-80 btn btn-x-sm btn-round0 color-9" @click="getList">검색</button></div>

        <table class="table-rows">
          <colgroup>
            <col width="10">
            <col width="200">
            <col width="250">
          </colgroup>
          <thead>
          <tr>
            <th colspan="2">아이디(이메일)</th>
            <th>닉네임</th>
          </tr>
          </thead>
          <template v-if="!isSearch">
            <tbody><tr><td colspan="3">{{ $msg('search.keyword') }}</td></tr></tbody>
          </template>
          <template v-else>
            <tbody v-if="list && list.length > 0">
            <tr v-for="(item, index) in list" :key="index">
              <td><input type="radio" v-model="idx" name="memIdx" :value="item.memIdx"/></td>
              <td>{{ item.email }}</td>
              <td>{{ item.nickName }}</td>
            </tr>
            </tbody>
            <tbody v-else><tr><td colspan="3">{{ $msg('nodata2') }}</td></tr></tbody>
          </template>
        </table>

      </div>
      <div class="popupBox-middle">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">취소</button>
        <button class="w-90 mr-10 btn btn-sm color-1" @click="set">선택완료</button>
      </div>
    </div>
  </div>
</template>
<script>
import listMixin from '@/mixins/listMixin'
export default {
  props: ['memIdx'],
  mixins: [listMixin],
  data() {
    return {
      isSearch: false,
      text: null,
      idx: null,
    }
  },
  created() {
    this.idx = this.memIdx
  },
  methods: {
    async getList() {
      this.isSearch = true
      const { result, list } = await this.$api.getPetMembers({
        text: this.text,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.list = list
      }
    },
    set() {
      if (!this.idx) {
        this.alertPopup(this.$msg('no-data-selected'))
        return false
      }
      this.$emit('set', this.list.find(l => l.memIdx === this.idx))
    },
    close() {
      this.$emit('close')
    },
  }
}

</script>
