<template>
  <div>
    <div class="navi-bar"> 
        <span>회원</span>
        <span>회원 관리</span>
        <span>회원 탈퇴</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">회원 탈퇴</div>
    </div>

    <div class="layout-contents-box">
      <table class="table-cols">
        <colgroup>
            <col width="180">
            <col>
        </colgroup>
        <tbody>
        <tr>
            <th>탈퇴일</th>
          <td><date ref="date"/></td>
        </tr>
        <tr>
            <th>아이디(이메일)</th>
            <td><div class="table-input2"><input type="text" v-model="email" placeholder="검색어 입력" maxlength="30" @keypress.enter="search"></div></td>
        </tr>
        </tbody>
      </table>
      
      <div class="btn-box center my-20">
          <div>
              <button class="btn color-7" @click="search">검색</button>
          </div>  
      </div>

    </div>
    
    <div class="layout-contents-box">
      <div class="contents-box-title">
        <div>검색결과 <strong class="color-1">{{ total }}</strong> 건</div>
        <div class="w-110">
          <select v-model="pageSize">
            <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
          </select>
        </div>
      </div>
      
      <table class="table-rows">
        <colgroup>
            <col width="120">
            <col>
        </colgroup>
        <thead>
        <tr>
          <th>탈퇴일</th>
          <th>구분</th>
          <th>아이디(이메일)</th>
          <th>닉네임</th>
          <th>탈퇴사유</th>
        </tr>
        </thead>
        <tbody v-if="list && list.length > 0">
          <tr v-for="item in list" :key="`list-${item.idx}`">
            <td>{{ $getDateFormat(item.withdrawalDate, 'Y-m-d') }}</td>
            <td>{{ item.type == 0 ? '본인탈퇴' : '관리자탈퇴' }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.nickName }}</td>
            <td>{{ item.resignMsg }}</td>
          </tr>
        </tbody>
        <tbody v-else><tr><td colspan="5">{{ $msg('nodata') }}</td></tr></tbody>
      </table>

      <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="getList" />

      <div class="information-box">
      <div class="information-box-title">안내</div>
      <div class="information-contents">
        <ul class="dot-list">
            <li>본인탈퇴 : 회원이 “회원탈퇴“ 신청을 통해 탈퇴 처리된 회원을 말합니다.</li>
            <li>관리자 탈퇴 : 운영방침에 따라 관리자가 강제 탈퇴처리한 회원을 말합니다.</li>
            <li>회원 탈퇴 처리 시 개인정보가(연락처, 비밀번호)삭제되며 복구할 수 없습니다. </li>
            <li>회원 탈퇴 시 회원의 활동내역(댓글, 추천, 게시글 등)은 삭제 되지 않습니다.</li>
            <li>회원 탈퇴한 아이디(이메일)로 재가입은 불가능합니다.</li>
        </ul>
      </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
export default {
  name: 'memberList',
  mixins: [listMixin],
  data() {
    return {
      email: null,
    }
  },
  watch: {
    pageSize() {
      this.page = 1
      this.getList()
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { result, list, total } = await this.$api.getWithdrawalMemberList({
        starDate: this.$refs.date ? this.$refs.date.startDate : null,
        endDate: this.$refs.date ? this.$refs.date.endDate : null,
        email: this.email,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
  }
}
</script>
