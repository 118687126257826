<template>
  <div>
    <div class="navi-bar"> 
        <span>운영관리</span>
        <span>기본정책</span>
        <span>아이콘 노출 설정</span>
    </div>

    <div class="page-title-box">
      <div class="page-title">아이콘 노출 설정</div>
      <div class=""><button @click="save" class="btn color-1">저장</button></div>
    </div>

    <validateForm ref="form">
      <div class="layout-contents-box">
        <div class="contents-box-title">게시물</div>
        <table class="table-cols">
          <colgroup>
            <col width="220">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <th class="require">추천 아이콘 노출 기준</th>
            <td><input-field v-model="recommend" :validate="rules.recommend" class="table-input" maxLength="10" numberOnly></input-field></td>
          </tr>
          <tr>
            <th class="require">인기 아이콘 노출 기준</th>
            <td><input-field v-model="best" :validate="rules.best" class="table-input" maxLength="10" numberOnly></input-field></td>
          </tr>
          </tbody>
        </table>
      </div>
    </validateForm>

    <div class="information-box">
      <div class="information-box-title">안내</div>
      <div class="information-contents">
        <ul class="dot-list">
          <li>추천, 인기 아이콘 노출 기준을 설정하는 화면입니다.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'baseInfo',
  data() {
    return {
      recommend: 0,
      best: 0,
      rules: {
        recommend: [v => !!v || this.$msg('required.input')],
        best: [v => !!v || this.$msg('required.input')],
      },
    }
  },
  created() {
    this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      const { result, data } = await this.$api.getIcon()
      if (result === 'success') {
        this.recommend = data.recommend
        this.best = data.best
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.setIcon({
          recommend: Number(this.recommend),
          best: Number(this.best),
        })
        this.alertPopup(result === 'success' ? this.$msg('ok.save') : this.$msg('fail'))
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}
</script>
