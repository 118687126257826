<template>
  <div class="popupBox-wrap">
    <div class="popupPosition" @click="close"></div>

    <div class="popupBox w-65p">
      <div class="popupBox-title"></div>
      <div class="popupBox-body viewAllImg-wrap">
        <div class="viewAllImgBox">
          <img v-for="(item, index) in srcList" class="img" :key="`image${index}`" :src="item">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['srcList'],
  data() {
    return {
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
  }
}

</script>
