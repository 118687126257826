<template>
  <div class="layout-footer">
   v1.6.2 © dreaminsight
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods:{
  }
}
</script>
