<template>
  <div class="popupBox-wrap scroll">
    <div class="popupPosition" @click="close"></div>

    <div class="popupBox w-1200">
      <div class="popupBox-title"><span class="color-1">{{ getDisplayTitle }}</span> 게시물선택</div>
      <div class="popupBox-body">
        <div class="layout-contents-box">
          <table class="table-cols">
            <colgroup>
              <col width="180">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>검색어</th>
              <td>
                <div class="d-flex">
                  <div class="w-100 mr-10">
                    <select v-if="isBoard" v-model="target">
                      <option v-for="item in targets" :key="`target-${item.value}`" :value="item.value">{{ item.name }}</option>
                    </select>
                    <select v-else v-model="target">
                      <option v-for="item in mdTargets" :key="`target-${item.value}`" :value="item.value">{{ item.name }}</option>
                    </select>
                  </div>
                  <div class="w-300">
                    <input type="text" v-model="text" placeholder="검색어 입력" maxlength="30" @keypress.enter="search">
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="btn-box center mt-20">
            <div>
              <button ref="searchBtn" class="btn color-7" @click="search">검색</button>
            </div>
          </div>

          <div class="contents-box-title">
            <div></div>
            <div class="">
              <select v-if="board != 'md'" v-model="sort" class="w-110 mr-5">
                <option v-for="item in sorts" :key="`target-${item.value}`" :value="item.value">{{ item.name }}</option>
              </select>
              <select v-model="pageSize" class="w-110">
                <option v-for="item in pageSizes" :key="`page-size-${item}`" :value="item">{{ item }}개 보기</option>
              </select>
            </div>
          </div>

          <table class="table-rows">
            <colgroup>
              <col width="80">
              <col width="130">
              <col width="150">
              <col>
              <col width="90">
              <col v-if="isBoard" width="80">
              <col v-if="isBoard" width="80">
              <col width="120">
            </colgroup>
            <thead>
            <tr>
              <th><input type="checkbox" class="check-display-all" v-model="allChecked" @change="toggleAll"></th>
              <th>구분</th>
              <th>이미지</th>
              <th>게시물</th>
              <th>조회수</th>
              <th v-if="isBoard">댓글</th>
              <th v-if="isBoard">추천</th>
              <th>등록일</th>
            </tr>
            </thead>
            <tbody v-if="this.list && this.list.length > 0">
            <tr v-for="item in this.list" :key="`list-${item.idx}`">
              <td><input type="checkbox" class="check-display-list" :value="item.idx" v-model="selectedIdxs" ></td>
              <td>{{ getCateName(item.cateCode) }}</td>
              <td><image-alt v-if="item.src" :src="item.src" altType="table" /></td>
              <td class="left">
                <p class="fw-bold">{{ item.title }}</p>
                <p class="color-7" v-if="board == 'md'">{{ item.channelName }}</p>
                <p class="color-7" v-else>{{ item.email }} / {{ item.nickName }}</p>
              </td>
              <td>{{ item.view.numberFormat() }}</td>
              <td v-if="isBoard">{{ item.comment.numberFormat() }}</td>
              <td v-if="isBoard">{{ item.likeCnt.numberFormat() }}</td>
              <td>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</td>
            </tr>
            </tbody>
            <tbody v-else><tr><td :colspan="isBoard ? 8 : 5">{{ $msg('nodata') }}</td></tr></tbody>
          </table>

          <pagination ref="pagination" :total="total" :size="pageSize" :block="10" v-model="page" @input="movePage" />

          <div class="contents-box-title">선택 리스트</div>
          <table class="table-rows">
            <colgroup>
              <col width="80">
              <col width="130">
              <col width="150">
              <col>
              <col width="90">
              <col v-if="isBoard" width="80">
              <col v-if="isBoard" width="80">
              <col width="120">
            </colgroup>
            <thead>
            <tr>
              <th>-</th>
              <th>구분</th>
              <th>이미지</th>
              <th>게시물</th>
              <th>조회수</th>
              <th v-if="isBoard">댓글</th>
              <th v-if="isBoard">추천</th>
              <th>등록일</th>
            </tr>
            </thead>
            <tbody v-if="this.selectList && this.selectList.length > 0">
            <tr v-for="item in this.selectList" :key="`list-${item.idx}`">
              <td><input type="checkbox" checked @click="remove(item.idx)"></td>
              <td>{{ getCateName(item.cateCode) }}</td>
              <td><image-alt v-if="item.src" :src="item.src" altType="table" /></td>
              <td class="left">
                <p class="fw-bold">{{ item.title }}</p>
                <p class="color-7" v-if="board == 'md'">{{ item.channelName }}</p>
                <p class="color-7" v-else>{{ item.email }} / {{ item.nickName }}</p>
              </td>
              <td>{{ item.view && item.view.numberFormat() }}</td>
              <td v-if="isBoard">{{ item.comment && item.comment.numberFormat() }}</td>
              <td v-if="isBoard">{{ item.likeCnt && item.likeCnt.numberFormat() }}</td>
              <td>{{ $getDateFormat(item.createDate, 'Y-m-d') }}</td>
            </tr>
            </tbody>
            <tbody v-else><tr><td :colspan="isBoard ? 7 : 5">{{ $msg('nodata.select') }}</td></tr></tbody>
          </table>
        </div>
      </div>

      <div class="popupBox-bottom">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">취소</button>
        <button class="w-90 mr-10 btn btn-sm color-1" @click="save">선택완료</button>
      </div>
    </div>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
export default {
  props: ['board', 'boIdxs', 'mdIdx', 'petCode', 'ageIdx'],
  mixins: [listMixin],
  data() {
    return {
      target: 1,
      targets: [
        { name: '제목', value: 1 },
        { name: '이메일(아이디)', value: 2 },
        { name: '닉네임', value: 3 },
      ],
      mdTargets: [
        { name: '제목', value: 1 },
        { name: '고유번호', value: 2 },
      ],
      sort: 1,
      sorts: [
        { name: '조회순', value: 1 },
        { name: '추천순', value: 2 },
        { name: '댓글순', value: 3 },
        { name: '최신순', value: 4 },
      ],
      text: null,
      popupMsg: null,
      selectList: [],
      selectedIdxs: this.boIdxs,
      allChecked: false,
    }
  },
  watch: {
    pageSize() {
      this.page = 1
      this.getList()
    },
    selectedIdxs(v) {
      this.allChecked = v == this.list ? true : false
      const includedList = this.list.filter(l => v.includes(l.idx))
      this.allChecked = JSON.stringify(this.list.sort()) === JSON.stringify(includedList.sort())
      this.setSelectList()
    }
  },
  computed: {
    getDisplayTitle() {
      if (this.board === 'tip') return '꼬리TIP'
      if (this.board === 'lab') return '연구일지'
      if (this.board === 'md') return '랜선집사'
      if (this.board.startsWith('recommend')) return '꼬리TIP&연구일지'
      if (this.board === 'keyword') return '연구일지'
    },
    isBoard() {
      return ['tip','lab','recommend','recommend2','recommend3','age', 'keyword'].includes(this.board)
    },
  },
  created() {
    if (this.boIdxs) this.selectedIdxs = this.boIdxs
    this.getList()
  },
  methods: {
    async getList() {
      const { result, list, total } = await this.$api.getDisplayBoardList({
        board: this.board,
        sort: this.sort,
        target: this.target,
        text: this.text,
        mdIdx: this.mdIdx,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.total = total
        this.list = list
        this.setSelectList().then()
      }
    },
    async search() {
      this.page = 1
      this.getList().then()
    },
    getCateName(code) {
      if (code.toString().startsWith('10')) return '꼬리TIP'
      if (code.toString().startsWith('20')) return '연구일지'
      if (code.toString().startsWith('30')) return '랜선집사'
    },
    movePage() {
      this.$refs.searchBtn.focus()
      this.$el.querySelectorAll('.check-display-all')[0].checked = false
      this.getList()
    },
    toggleAll() {
      const checkbox = this.$el.querySelectorAll('.check-display-list')
      const allChecked = this.allChecked
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = allChecked
        let idx = Number(checkbox[i].value)
        if (!this.selectedIdxs.includes(idx)) this.selectedIdxs.push(idx)
        if (!allChecked) this.selectedIdxs = this.selectedIdxs.filter(i => i != idx)
        else if (!this.selectedIdxs.includes(idx)) this.selectedIdxs.push(idx)
      }
    },
    async setSelectList() {
      const params = {
        board: this.board,
        idxs: this.selectedIdxs
      }
      if (this.mdIdx) params.mdIdx = this.mdIdx
      if (this.petCode && this.ageIdx) {
        params.petCode = this.petCode
        params.ageIdx = this.ageIdx
      }
      const { result, list } = await this.$api.getSelectedDisplayBoardList(params)
      if (result === 'success') this.selectList = list
    },
    remove(id) {
      this.selectedIdxs = this.selectedIdxs.filter(i => i != id)
    },
    save() {
      const list = this.selectList
      if (list) list.sort((a, b) => (a.sorting == null) - (b.sorting == null) || a.sorting - b.sorting)
      this.$emit('save', list)
      this.close()
    },
    close() {
      this.$emit('close')
    },
  }
}
</script>
