<template>
  <div class="editor-box">
    <div class="editor-header">
      <div class="select-box" @click="toggleSelectMedia" v-click-outside="closeSelectMedia">
        <div class="editor-btn-img">
          <div class="icon-img icon-editor-img">이미지</div>
        </div>
        <div class="select-popup" v-if="isOpenSelect.media">
          <div class="editor-btn-img select-div" @click="$refs.imageInput.click()">
            <div class="icon-img icon-editor-img"></div><span>사진</span>
          </div>
          <div class="editor-btn-img select-div" @click="openYoutubeVideoPopup(true)">
            <div class="icon-img icon-editor-video"></div><span>영상</span>
          </div>
          <div v-if="$route.name === 'labSave'" class="editor-btn-img select-div" @click="openProductTagPopupForInsert">
            <div class="icon-img icon-editor-product"></div><span>상품태그</span>
          </div>
        </div>

        <input type="file" accept="image/gif, image/jpg, image/jpeg, image/png" ref="imageInput"
               multiple @change="uploadEditorImage($event)" style="display:none">
      </div>

      <div class="link-bar"></div>

      <div class="select-box" @click="toggleSelectText" v-click-outside="closeSelectText">
        <div :class="['selected', {'on': isOpenSelect.text}]">
          <div class="editor-btn-text" v-if="editor && editor.isActive('heading') && editor.isActive('heading', { level: 2 })">제목</div>
          <div class="editor-btn-text" v-else-if="editor && editor.isActive('heading') && editor.isActive('heading', { level: 3 })">본문2</div>
          <div class="editor-btn-text" v-else>본문1</div>
        </div>

        <div class="select-popup" v-if="isOpenSelect.text">
          <div class="editor-btn-text select-div" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
            <div class="subject">제목</div>
          </div>
          <div class="editor-btn-text select-div" @click="editor.chain().focus().setParagraph().run()">
            <div class="bodyText1">본문1</div>
          </div>
          <div class="editor-btn-text select-div" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
            <div class="bodyText2">본문2</div>
          </div>
        </div>

      </div>

      <div class="link-bar"></div>

      <div :class="['editor-btn-font', { 'on': editor && editor.isActive('bold') }]" @click="editor.chain().focus().toggleBold().run()">
        <div class="icon-img icon-editor-bold">볼드</div>
      </div>
      <div :class="['editor-btn-font', { 'on': editor && editor.isActive('italic') }]" @click="editor.chain().focus().toggleItalic().run()">
        <div class="icon-img icon-editor-italic">기울임</div>
      </div>
      <div :class="['editor-btn-font', { 'on': editor && editor.isActive('underline') }]" @click="editor.chain().focus().toggleUnderline().run()">
        <div class="icon-img icon-editor-under">밑줄</div>
      </div>

      <div class="link-bar"></div>

      <div class="editor-btn-color" @click="setColor('#272727')">
        <div class="color-box bgcolor-9">검정</div>
      </div>
      <div class="editor-btn-color" @click="setColor('#FF693A')">
        <div class="color-box bgcolor-1">주황</div>
      </div>
      <div class="editor-btn-color" @click="setColor('#643BFF')">
        <div class="color-box bgcolor-3">보라</div>
      </div>

      <!--        editor.isActive('highlight', { color: 'red' }) }"-->
      <div class="editor-btn-color" @click="editor.chain().focus().toggleHighlight({ color: '#fff8b2' }).run()">
        <div class="color-box" style="border: 3px solid #fff8b2">배경1</div>
      </div>

      <div class="editor-btn-color" @click="editor.chain().focus().toggleHighlight({ color: '#ffe3c8' }).run()">
        <div class="color-box" style="border: 3px solid #ffe3c8">배경2</div>
      </div>

      <div class="link-bar"></div>

      <div class="editor-btn-link" @click="toggleLinkPopup('open', editor.getAttributes('link'))" v-click-outside="toggleLinkPopup">
        <div class="icon-img icon-editor-link">링크</div>
        <div v-if="isOpenLinkPopup" class="popupBox editor-link-input w-400">
          <div class="popupBox-body">
            <div><input type="text" placeholder="https:// 포함한 URL을 입력하세요." v-model="linkUrl" v-focus @keypress.enter="setLink"></div>
          </div>
          <div class="popupBox-bottom">
            <button v-if="editor.isActive('link')" @click="editor.commands.unsetLink()" class="w-90 mr-10 btn btn-sm btn-outline color-6" >링크해제</button>
            <button :class="['w-90 btn btn-sm', isLinkActive ? 'color-1' : 'color-6 btn-outline']" @click="setLink">확인</button>
          </div>
        </div>
      </div>

      <div class="link-bar"></div>

      <div class="select-box" @click="toggleSelectHr" v-click-outside="closeSelectHr">
        <div :class="['selected']">
          <div class="editor-btn-text">가로선</div>
        </div>
        <div class="select-popup" v-if="isOpenSelect.hr">
          <div class="editor-btn-text select-div flex-center" @click="editor.chain().focus().setHorizontalRule({class: 'short'}).run()">
            <div class="hr short"/>
          </div>
          <div class="editor-btn-text select-div" @click="editor.chain().focus().setHorizontalRule().run()">
            <div class="hr"/>
          </div>
        </div>
      </div>

    </div>

    <div v-if="editor" class="writeP-content-box editor-content-box" style="margin-top: 0;margin-bottom: 0">
      <bubble-menu :editor="editor" :tippy-options="{ zIndex: 10, duration: 400 }" v-if="editor">
        <transition name="fade">
          <div class="float-menu">
            <div class="editor-btn-font" :class="{ 'on': editor && editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
              <div class="icon-img icon-editor-bold">볼드</div>
            </div>
            <div class="editor-btn-font" :class="{ 'on': editor && editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()">
              <div class="icon-img icon-editor-italic">기울임</div>
            </div>
            <div class="editor-btn-font" :class="{ 'on': editor && editor.isActive('underline') }" @click="editor.chain().focus().toggleUnderline().run()">
              <div class="icon-img icon-editor-under">밑줄</div>
            </div>

            <div class="link-bar"></div>

            <div class="editor-btn-color" @click="setColor('#272727')">
              <div class="color-box bgcolor-9">검정</div>
            </div>
            <div class="editor-btn-color" @click="setColor('#FF693A')">
              <div class="color-box bgcolor-1">주황</div>
            </div>
            <div class="editor-btn-color" @click="setColor('#643BFF')">
              <div class="color-box bgcolor-3">보라</div>
            </div>
            <div class="editor-btn-color" @click="editor.chain().focus().toggleHighlight({ color: '#fff8b2' }).run()">
              <div class="color-box" style="border: 3px solid #fff8b2">배경1</div>
            </div>
            <div class="editor-btn-color" @click="editor.chain().focus().toggleHighlight({ color: '#ffe3c8' }).run()">
              <div class="color-box" style="border: 3px solid #ffe3c8">배경2</div>
            </div>
          </div>
        </transition>
      </bubble-menu>

      <editor-content style="color: #333333" :editor="editor" placeholder="본문 내용을 입력해주세요."/>
    </div>

    <div class="quick_top_btn" @click="goTop"><span>Top</span></div>

    <youtube-video-popup v-if="isYoutubeVideoPopup" @set="setYoutubeVideo" @close="openYoutubeVideoPopup" />

    <product-tag-popup v-if="isProductTagPopup" :cateCode="cateCode" :propId="propId" :tagInfo="productTagInfo"
                       @insert="tagInsert" @update="tagUpdate" @remove="tagRemove" @close="closeProductTagPopup" />
  </div>
</template>

<script>
import Compressor from 'compressorjs'
import {mapActions} from "vuex"
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2"
import StarterKit from '@tiptap/starter-kit'
import Paragraph from '@tiptap/extension-paragraph'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Highlight from "@tiptap/extension-highlight"
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import DropCursor from '@tiptap/extension-dropcursor'
import HorizontalRule from "@/components/tiptap/horizontalRule"
import Underline from '@/components/tiptap/underline'
import ImageUpload from '@/components/tiptap/image/index'
import Youtube from '@/components/tiptap/youtube'
import productTagMixin from '@/mixins/productTagMixin'

import productTagPopup from '@/components/tiptap/popup/productTagPopup'
import youtubeVideoPopup from '@/components/tiptap/popup/youtubeVideoPopup'

export default {
  components: {
    EditorContent,
    BubbleMenu,
    productTagPopup,
    youtubeVideoPopup,
  },
  props: ['cateCode', 'previewH'],
  mixins: [productTagMixin],
  data() {
    return {
      idx: this.$route.query.idx ? this.$route.query.idx : 0,
      editor: null,
      editorImageIds: [],
      isOpenSelect: {
        media: false,
        text: false,
        hr: false,
      },
      isYoutubeVideoPopup: false,
      linkUrl: null,
      fontSize: null,
      isLinkActive: null,
      isOpenLinkPopup: false,
      isFirstLoad: true,
      savedImageIds: [],
      savedContent: null,
    }
  },
  watch: {
    linkUrl(v) {
      const expUrl = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi
      this.isLinkActive = expUrl.test(v);
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.preventKeyDown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.preventKeyDown)
    this.editor.destroy()
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          document: true,
          text: true,
          paragraph: false,
          horizontalRule: false,
          dropcursor: false,
        }),
        Underline,
        TextStyle,
        Color,
        Highlight.configure({ multicolor: true }),
        Paragraph.extend({
          parseHTML() {
            return [{ tag: 'div', class: 'default-text' }]
          },
          renderHTML({ HTMLAttributes }) {
            return ['div', {class: 'default-text'}, 0]
            // return ['div', {class: 'default-text'}, 0] // ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
          },
        }),
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          placeholder: '본문 내용을 입력해주세요.',
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
        Link.configure({
          openOnClick: false,
          linkOnPaste: false,
          validate: href => /^https?:\/\//.test(href),
          target: '_blank'
        }),
        DropCursor.configure({
          color: '#ffe1d8',
          width: 3,
        }),
        HorizontalRule,
        ImageUpload,
        Youtube,
      ],
      content: '',
      // autoFocus: 'start',
    })
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    preventKeyDown(event) {
      let className = event.target.className
      if (className.indexOf('ProseMirror-hideselection') > -1) {
        event.preventDefault()
        event.stopPropagation()
      }
    },
    async uploadEditorImage(event) {
      if (!event) return false
      event.stopPropagation()
      event.preventDefault()

      let result = false
      let files = event.target.files || event.dataTransfer.files

      if (files.length > 0) {
        for (let file of files) {
          if (!/^image\//.test(file.type)) {
            this.alertPopup(this.$msg('image.ext'))
            return false
          }

          let fileExt = this.$getExts(file.name, false);
          let isImg = this.$isImages(fileExt);
          if (isImg) {
            if (file.size < this.$MBtoB(10)) { // 10MB
              result = true;
            } else {
              this.alertPopup(this.$msg('image.limit'))
            }
          }

          if (result) {
            let vm = this

            const img = new Image()
            img.src = URL.createObjectURL(file)

            img.onload = () => {
              const isCompressor = fileExt === 'gif' || !(img.width > vm.$imageMaxWidth || img.height > vm.imageMaxHeight) ? false : true

              if (isCompressor) {
                const options = {
                  maxWidth: vm.$imageMaxWidth,
                  maxHeight: vm.imageMaxHeight,
                  success: (_file) => {
                    if (_file.size > vm.$MBtoB(10)) { // 리사이징 했는데도 용량이 큰 경우
                      vm.alertPopup('image.limit')
                      return false
                    }
                    let reader = new FileReader()
                    reader.onload = () => {
                      let params = {
                        name: _file.name,
                        data: reader.result,
                        cateCode: vm.cateCode,
                      }
                      vm.uploadImage(params)
                    }
                    reader.readAsDataURL(_file)
                  },
                  error: (err) => console.log(err)
                }
                new Compressor(file, options)
              }
              else {
                let reader = new FileReader()
                reader.onload = function () {
                  let params = {
                    name: file.name,
                    data: reader.result,
                    cateCode: vm.cateCode,
                  }
                  vm.uploadImage(params)
                }
                reader.readAsDataURL(file)
              }
            }
          }

        }
      }
    },
    async uploadImage(params) {
      const { result, data } = await this.$api.uploadEditorImage(params)
      if (result === 'success') {
        this.editorImageIds.push(Number(data.atchIdx))
        this.editor.chain().focus().setImage({
          src: data.src,
          title: params.name,
          id: data.atchIdx,
        }).run()
      }
    },
    getEditorImageIds() {
      let arr = []
      let imgArr = document.getElementsByClassName("editorImage")
      for (let img of imgArr) arr.push(Number(img.id))
      return arr
    },
    setYoutubeVideo(data) {
      this.editor.chain().focus().setYoutube(data).run()
    },
    tagInsert(data) {
      this.editorImageIds.push(Number(data.id))
      this.editor.chain().focus().setImage(data).run()
      this.closeProductTagPopup()
    },
    tagUpdate(data) {
      if (!this.editorImageIds.includes(Number(data.id))) this.editorImageIds.push(Number(data.id))
      this.editor.chain().focus().updateTagInfo(data).run()
      this.closeProductTagPopup()
    },
    tagRemove(editorId) {
      document.getElementById(`${editorId}`).parentNode.parentNode.remove()
      this.closeProductTagPopup()
    },
    setColor(color) {
      // const attrs = this.editor.getAttributes('link')
      // if (attrs && !attrs.href) this.editor.chain().focus().setColor(color).run()
      this.editor.chain().focus().setColor(color).run()
    },
    setLink() {
      if (!this.isLinkActive) return false
      this.editor.commands.setLink({ href: this.linkUrl, target: '_blank' })
      this.toggleLinkPopup()
    },
    toggleSelectMedia() {
      this.isOpenSelect.media = !this.isOpenSelect.media
    },
    closeSelectMedia() {
      this.isOpenSelect.media = false
    },
    toggleSelectText() {
      this.isOpenSelect.text = !this.isOpenSelect.text
    },
    closeSelectText() {
      this.isOpenSelect.text = false
    },
    toggleSelectHr() {
      this.isOpenSelect.hr = !this.isOpenSelect.hr
    },
    closeSelectHr() {
      this.isOpenSelect.hr = false
    },
    openYoutubeVideoPopup(isOpen){
      this.isYoutubeVideoPopup = isOpen ? true : false
    },
    toggleLinkPopup(type, attrs) {
      if (type === 'open') {
        if (this.isOpenLinkPopup) return false
        this.linkUrl = attrs.href;
        this.isOpenLinkPopup = true
      }
      else {
        this.linkUrl = null
        this.isOpenLinkPopup = false
      }
    },
    goTop(){
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
};
</script>