import {mapActions} from "vuex"

export default {
  data() {
    return {
      page: this.$route.query.page ? this.$route.query.page : 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100, 200],
      total: 0,
      list: [],
      selectedIdxs: [],
      allChecked: false,
    }
  },
  watch: {
    pageSize() {
      this.page = 1
      this.getList()
    },
    selectedIdxs(v) {
      this.allChecked = v.length > 0 && v.length == this.list.length ? true : false
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getCtgryList(cateCode) {
      const { result, data } = await this.$api.getCtgryList({ cateCode: cateCode })
      if (result === 'success') this.ctgryList = data
    },
    toggleAll(isString=false) {
      const checkbox = this.$el.querySelectorAll('.check-list')
      const allChecked = this.allChecked
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = allChecked
        let idx = isString ? checkbox[i].value : Number(checkbox[i].value)
        if (!this.selectedIdxs.includes(idx)) this.selectedIdxs.push(idx)
        if (!allChecked) this.selectedIdxs = this.selectedIdxs.filter(i => i != idx)
        else if (!this.selectedIdxs.includes(idx)) this.selectedIdxs.push(idx)
      }
    },
    ready(target) {
      if (this.selectedIdxs.length > 0) {
        if (target === 'remove') this.confirmRemovePopup()
      }
      else {
        this.alertPopup(this.$msg('no-data-selected'))
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmRemovePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: () => {
          this.setMsgPopup()
          this.remove()
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}